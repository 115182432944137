<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('Creating a material')"
          :description="$t('From here you can create a new material')"
          v-if="$route.name == 'materialsCreate'"
        />
        <addHeader
          :title="$t('Modified material')"
          :description="$t('From here you can modify the material')"
          v-if="$route.name == 'materialsEdit'"
        />
        <div class="mb-4 row">
          <dvider
            :title="$t('Basic information')"
            :description="$t('Background information for the opacity')"
            noLine="true"
          />
          <formInput
            :title="$t('The name of the material')"
            v-model="item.name"
            :hasErorr="errors.name"
            :error="$t('This field is required')"
            maxlength="255"
          />
          <connectionInput
            :title="$t('Raw material rating')"
            :group="$t('classifications')"
            v-model="item.section_id"
            :hasAdd="true"
            :open="'addSection'"
            :values="$database.sections"
          />
          <connectionInput
            :title="$t('Supplier name')"
            :group="$t('Suppliers')"
            v-model="item.supplier_id"
            :hasAdd="false"
            :values="$database.suppliers"
          />
          <div class="form-group row col-md-6">
            <label class="col-md-4">{{ $t('Barcode Code') }}</label>
            <!-- Input -->
            <div class="col-md-7 d-flex">
              <input type="text" class="form-control" v-model="item.barcode" />
              <button class="btn btn-primary barcode-generator" @click="generatBarcode">
                <i class="fas fa-scanner-keyboard"></i>
              </button>
            </div>
          </div>
          <formTextarea :title="$t('Description of the material')" v-model="item.description" />
          <dvider
            :title="$t('Accounts Information')"
            :description="$t('Product account information')"
          />

          <connectionInput
            :title="$t('Tax rate')"
            :group="$t('Rates')"
            v-model="item.tax_id"
            :hasAdd="true"
            :values="$database.taxes"
            :open="'addTaxe'"
          />
          <connectionInput
            :title="$t('Discount rate')"
            :group="$t('Discount rates')"
            v-model="item.discount_id"
            :hasAdd="true"
            :values="$database.discounts"
            :open="'addDiscount'"
          />
          <pricesTable class="col-12" />
          <dvider
            :title="$t('Store settings')"
            :description="$t('Settings for stores')"
            v-if="item.type == 0"
          />
          <connectionInput
            :title="$t('Basic unit')"
            :group="$t('Units')"
            v-model="item.unit_id"
            :open="'addUnit'"
            :hasAdd="true"
            :values="$database.units"
            v-if="item.type == 0"
          />

          <unitsTable class="col-12" />

          <dvider
            :title="$t('Warehouse quantities')"
            :description="$t('Quantities of products available in stores')"
            v-if="item.type == 0"
          />
          <div class="form-group col-12 row" v-if="item.type == 0">
            <label class="col-2">{{ $t('Track inventory') }}</label>
            <div class="col-md-7">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  id="cardToggle"
                  :checked="item.track_quantity == 1"
                  class="custom-control-input"
                  value="1"
                  @change="changevalue()"
                />
                <label for="cardToggle" class="custom-control-label"></label>
              </div>
            </div>
          </div>

          <storesTable v-if="item.type == 0 && item.track_quantity == 1" class="co-12" />

          <dvider
            :title="$t('Manufacturing settings')"
            :description="$t('Manufacturing numbers')"
            v-if="item.type == 0"
          />
          <checkInput
            :title="$t('Factory product')"
            :value="item.factory_product"
            v-model="item.factory_product"
          />
          <connectionInput
            :title="$t('Manufacturing model')"
            :group="$t('Manufacturing models')"
            v-model="item.manufacturing_model_id"
            :values="$database.manufacturingModels"
            v-if="item.factory_product"
          />

          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'materialsCreate' && !$parent.stopEdit"
          >
            {{ $t('Create material') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'materialsEdit' && !$parent.stopEdit"
          >
            {{ $t('Edit material') }}
          </a>
          <loading v-if="$parent.stopEdit" />
        </div>
      </div>
    </div>
    <addSection v-if="model == 'addSection'"></addSection>
    <addUnit v-if="model == 'addUnit'"></addUnit>
    <addStore v-if="model == 'addStore'"></addStore>
    <addTaxe v-if="model == 'addTaxe'"></addTaxe>
    <addDiscount v-if="model == 'addDiscount'"></addDiscount>
  </div>
</template>
<script>
import axios from 'axios'

import formInput from '@/elements/add/form/formInput.vue'
import formTextarea from '@/elements/add/form/formTextarea.vue'
import connectionInput from '@/elements/add/form/connectionInput.vue'
import monyInput from '@/elements/add/form/monyInput.vue'
import dvider from '@/elements/add/dvider.vue'
import addHeader from '@/elements/add/header.vue'
import checkInput from '@/elements/add/form/checkInput.vue'

import addSection from './components/addSection.vue'
import addUnit from './components/addUnit.vue'
import addStore from './components/addStore.vue'
import addTaxe from './components/addTaxe.vue'
import addDiscount from './components/addDiscount.vue'
import loading from '@/elements/add/loading.vue'

import pricesTable from '@/elements/add/form/product/pricesTable.vue'
import unitsTable from '@/elements/add/form/product/unitsTable.vue'
import storesTable from '@/elements/add/form/product/storesTable.vue'
export default {
  data() {
    return {
      path: '/materials',
      item: {
        type: 0,
        track_quantity: 1,
        tax_id: 0,
        discount_id: 0,
        unit_id: 0,
        section_id: 0,
        supplier_id: 0,

        productPrices: [],
        productUnits: [],
        productQuantities: [],

        barcode: '',
        material: 1,
        unit_name: 'وحدة',
        factory_product: 0,
        manufacturing_model_id: 0
      },
      errors: {
        name: false
      },
      model: false,
      disabled: true,
      runStores: 0
    }
  },
  methods: {
    addUnit() {
      this.item.productUnits.push({
        unit_id: '',
        quantity: ''
      })
    },
    addProductPrices() {
      this.item.productPrices.push({
        priceList_id: 0,
        detail_id: 0,
        unit_id: 0,
        price: '',
        cost: ''
      })
    },
    getItem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.$parent.stopEdit = false
        this.item = response.data
        this.runStores += 1

        if (this.item.productUnits.length == 0) {
          this.addUnit()
        }
      })
    },
    saveItem() {
      var error = 0
      if (typeof this.item.name === 'undefined' || this.item.name == '') {
        error = 1
        this.errors.name = true
      }
      if (error == 0) {
        if (this.$route.name == 'materialsCreate') {
          this.$toast.success(this.$t('Product successfully added'))
          this.$addToLocalDatabase(this.item, this.path.substring(1))
          this.$router.go(-1)
        } else if (this.$route.name == 'materialsEdit') {
          this.$parent.stopEdit = true
          this.item._method = 'PUT'
          axios
            .post(this.$linkGenerator(this.path + '/' + this.item.id), this.item)
            .then((response) => {
              this.$toast.success(this.$t('Product has been modified successfully'))
              this.$router.go(-1)
              return response
            })
        }
      } else {
        this.$toast.error(this.$t('Please make sure of the input'))
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    changevalue() {
      if (this.item.track_quantity == 1) {
        this.item.track_quantity = 0
      } else if (this.item.track_quantity == 0) {
        this.item.track_quantity = 1
      }
    },
    generatBarcode() {
      this.item.barcode = Math.floor(Math.random() * 10000000)
    }
  },
  mounted() {
    this.$updateDatabase(['sections', 'units', 'stores', 'suppliers', 'taxes'])
    if (this.$route.name == 'materialsEdit') {
      this.$parent.stopEdit = true
      this.getItem()
    } else {
      this.runStores += 1
      this.addUnit()
    }
  },
  watch: {
    runStores: function (val) {
      if (val == 1) {
        this.$database.stores.forEach((store) => {
          var productQuantity = this.item.productQuantities.find((el) => el.store_id == store.id)
          if (productQuantity) {
            productQuantity.name = store.name
          } else {
            this.item.productQuantities.push({
              store_id: store.id,
              name: store.name,
              quantity: '',
              min_quantity: ''
            })
          }
        })
      }
    },
    'item.unit_id': function (val) {
      var unit = this.$database.units.find((el) => el.id == val)
      if (unit) {
        this.item.unit_name = unit.name
      } else {
        this.item.unit_name = 'وحدة'
      }
    },
    'item.detail_id': function (val) {
      var productMaterials = this.item.productMaterials.filter((el) => el.detail_id == val)
      if (!productMaterials.length) {
        this.addMaterial()
      }
    }
  },
  components: {
    formInput,
    dvider,
    addHeader,
    formTextarea,
    connectionInput,
    monyInput,
    addSection,
    checkInput,
    addUnit,
    addStore,
    addTaxe,
    addDiscount,
    loading,
    pricesTable,
    unitsTable,
    storesTable
  }
}
</script>
