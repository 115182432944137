var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c(_setup.showHeader,{staticClass:"col-12 mt-3",attrs:{"icon":'treasure-chest',"title":_setup.item.name,"stat":_setup.item.stat,"statText":_setup.item.stat ? _vm.$t('Active') : _vm.$t('Inactive'),"subData":[
        {
          title: _vm.$t('Current balance'),
          value: _vm.$moneyFormat(_setup.item.balance)
        }
      ],"buttons":[
        {
          title: _vm.$t('Modulation'),
          icon: 'edit',
          link: _setup.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$checkRole('safes_edit')
        },
        {
          title: _vm.$t('Treasury statement'),
          icon: 'file-chart-pie',
          link: '/safesAccounts?safe_id=' + _vm.$route.params.id,
          role: _vm.$checkRole('reports_show')
        }
      ]}}),_c(_setup.showBoxs,{staticClass:"col-12",attrs:{"boxs":[
        {
          icon: 'dollar-sign',
          title: _vm.$t('Current balance'),
          value: _vm.$moneyFormat(_setup.item.balance),
          class: 'col-md-3'
        },
        {
          icon: 'credit-card-front',
          title: _vm.$t('Invoices count'),
          value: _setup.item.salesPaymentsCount,
          class: 'col-md-3'
        },
        {
          icon: 'credit-card-front',
          title: _vm.$t('Quotations count'),
          value: _setup.item.purchasePaymentsCount,
          class: 'col-md-3'
        },
        {
          icon: 'usd-square',
          title: _vm.$t('ordersCount'),
          value: _setup.item.expensesCount,
          class: 'col-md-3'
        }
      ]}}),_c(_setup.showCard,{staticClass:"col-12",attrs:{"title":_vm.$t('Store address'),"columns":{
        firstColumn: [
          {
            icon: 'map-marker-alt',
            title: _vm.$t('Address'),
            value: _setup.item.address1
          },
          {
            icon: 'location-arrow',
            title: _vm.$t('The side'),
            value: _setup.item.entity
          },
          {
            icon: 'map-marked',
            title: _vm.$t('Zip code'),
            value: _setup.item.zip
          },
          {
            icon: 'dollar-sign',
            title: _vm.$t('Balance'),
            value: this.$moneyFormat(_setup.item.balance)
          }
        ],
        secondColumn: [
          {
            icon: 'map-marker-alt',
            title: _vm.$t('The sequel'),
            value: _setup.item.address2
          },
          { icon: 'city', title: _vm.$t('City'), value: _setup.item.city },
          {
            icon: 'globe-americas',
            title: _vm.$t('State'),
            value: _setup.item.country
          },
          {
            icon: 'dollar-sign',
            title: _vm.$t('Opening balance'),
            value: this.$moneyFormat(_setup.item.opening_balance)
          }
        ]
      }}}),_c('div',{staticClass:"col-12"},[(_vm.$checkRole('history_show'))?_c(_setup.HistoryCard,{attrs:{"items":_setup.item.histories}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }