var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('Account statement')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Account'),"plaseholder":_vm.$t('Choose account'),"show":'name',"refrance":'id',"value":_vm.params.id,"values":_vm.$database.accounts.filter((el) => el.is_main == 0)},model:{value:(_vm.params.id),callback:function ($$v) {_vm.$set(_vm.params, "id", $$v)},expression:"params.id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('Account statement'),"details":[{ title: _vm.$t('Account Name'), value: _vm.accountName }]}}),_c('reportTable',{attrs:{"header":[
            { colspan: 8, value: _vm.$t('Opening Balance') },
            { colspan: 1, value: _vm.startBalance }
          ],"emptyTableText":_vm.$t('There are no data'),"emptyTableSubText":_vm.$t('Try adding some and Try again'),"cloumns":[
            { column: 'check', title: '#', type: 'check', reportType: 'account' },

            { column: 'code', title: _vm.$t('Code'), type: 'reportCode' },
            { column: 'reference', title: _vm.$t('Reference'), type: 'text' },
            { column: 'date', title: _vm.$t('Date'), type: 'text' },
            { column: 'type', title: _vm.$t('Process'), type: 'reportType' },
            {
              column: 'statement',
              title: _vm.$t('statement'),
              type: 'statement'
            },
            { column: 'debit', title: _vm.$t('Debit'), type: 'textOrEmpty' },
            { column: 'creditor', title: _vm.$t('Credit'), type: 'textOrEmpty' },
            { column: 'new_balance', title: _vm.$t('Balance'), type: 'text' }
          ],"footer":[
            { colspan: 6, value: _vm.$t('Final Balance') },
            { colspan: 1, value: _vm.debit },
            { colspan: 1, value: _vm.creditor },
            { colspan: 1, value: _vm.endBalance }
          ],"tafqeet":_vm.tafqeet}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }