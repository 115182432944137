<template>
  <div
    class="casher-section"
    :class="{
      'sections-without-image': !options.show_sections_image,
      active: active
    }"
    @click="$emit('selectSection', section.id)"
  >
    <!-- Section Image -->
    <div
      class="casher-section-image"
      :style="image"
      v-if="section.image != null && !options.show_sections_icon"
    ></div>
    <!-- Section Icon-->
    <div class="casher-section-icon" v-else-if="options.show_sections_icon && section.icon != null">
      <img :src="'accounting/img/icons/' + section.icon + '.png'" />
    </div>
    <!-- Section no image -->
    <div class="casher-section-icon" v-else>
      <img :src="'accounting/img/noImage.png'" class="no-casher-img" />
    </div>
    <!-- Section name -->
    <div class="casher-section-name">
      {{ section.name }}
    </div>
  </div>
</template>
<script setup>
const image =
  'background-image: url("' +
  localStorage.getItem('site') +
  '/companiesUploads/' +
  localStorage.getItem('subdomain') +
  '/sections/' +
  props.section.image +
  '");'

const props = defineProps(['section', 'options', 'active'])
const emits = defineEmits('selectSection')
</script>
