<template>
  <div class="row pt-4 quotation-item mb-3 pb-3">
    <div class="col-xl-3">
      <label class="d-xl-none"> {{ $t('Delivery App') }} </label>
      <input
        type="text"
        class="form-control w-100"
        :placeholder="$t('Delivery App')"
        v-model="item.name"
      />
    </div>
    <div class="col-xl-4">
      <label class="d-xl-none"> {{ $t('Customer') }} </label>
      <select class="form-control w-100" v-model="item.customer_id">
        <option value="">{{ $t('Choose the customer') }}</option>
        <option v-for="(customer, index) in $database.customers" :key="index" :value="customer.id">
          {{ customer.name }}
        </option>
      </select>
    </div>
    <div class="col-xl-3">
      <label class="d-xl-none"> {{ $t('Price list') }} </label>
      <select class="form-control w-100" v-model="item.price_list_id">
        <option value="">{{ $t('Choose price list') }}</option>
        <option
          v-for="(priceList, index) in $database.priceLists"
          :key="index"
          :value="priceList.id"
        >
          {{ priceList.name }}
        </option>
      </select>
    </div>

    <div class="col-xl-2">
      <button
        class="btn btn-danger"
        @click="deleteItem()"
        :disabled="$parent.$parent.item.delivery_apps.length <= 1"
      >
        <i class="far fa-trash"></i>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      path: '/invoiceItems',
      searchProducts: {},
      noSearch: false,
      showItemsList: false
    }
  },
  methods: {
    searchItem() {
      this.showItemsList = true
      var search = this.item.product_name
      var editsearch = new RegExp(search, 'i')
      var products = this.$parent.filteredItems
        .sort(this.$dynamicSort({ orderBy: 'name', orderType: 'desc' }))
        .filter((el) => editsearch.test(el.name))
        .slice(0, 20)
      if (typeof products != 'undefined') {
        this.searchProducts = products
      } else {
        this.searchProducts = []
      }
    },
    deleteItem() {
      this.$parent.$parent.item.delivery_apps.splice(
        this.$parent.$parent.item.delivery_apps.indexOf(this.item),
        1
      )
    },
    selectProduct(e) {
      if (
        (e.key == 'ArrowDown' && this.searchProducts.length != 0) ||
        (e.key == 'ArrowUp' && this.searchProducts.length != 0)
      ) {
        var product = this.searchProducts.find((el) => el.focused == true)
        var selected = this.searchProducts.indexOf(product)
        var length = this.searchProducts.length
        if (selected > -1) {
          this.$set(this.searchProducts[selected], 'focused', false)
        }
        if (e.key == 'ArrowDown') {
          if (selected < length - 1) {
            this.$set(this.searchProducts[selected + 1], 'focused', true)
          } else {
            this.$set(this.searchProducts[0], 'focused', true)
          }
        } else if (e.key == 'ArrowUp') {
          if (selected > 0) {
            this.$set(this.searchProducts[selected - 1], 'focused', true)
          } else {
            this.$set(this.searchProducts[length - 1], 'focused', true)
          }
        }
      } else if (e.key == 'Enter') {
        var item = this.$parent.filteredItems.find((el) => el.barcode == this.item.product_name)
        if (item) {
          this.changeProduct(item)
        } else {
          var eproduct = this.searchProducts.find((el) => el.focused == true)
          var eselected = this.searchProducts.indexOf(eproduct)
          if (eselected != -1) {
            this.changeProduct(eproduct)
          }
        }
      }
    },
    removeItemList() {
      this.showItemsList = false
    },
    removeItem() {
      this.item.product_name = ''
      this.updateItem()
    }
  },
  watch: {},
  props: ['item', 'index']
}
</script>
