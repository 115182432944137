<template>
  <div class="col-12">
    <div class="d-xl-block d-none">
      <div class="row invoice-table-header">
        <div class="col-xl-2">
          {{ $t('Price lists') }}
        </div>
        <div class="col-xl-2">
          {{ $t('productDetail') }}
        </div>
        <div class="col-xl-2">
          {{ $t('Unit') }}
        </div>
        <div class="col-xl-5">
          <div class="row">
            <div class="col-xl-6">
              {{ $t('sellingPrice') }}
            </div>
            <div class="col-xl-6">
              {{ $t('purchasingPrice') }}
            </div>
          </div>
        </div>
        <div class="col-xl-1"></div>
      </div>
    </div>

    <priceItem
      v-for="(rowItem, index) in $parent.item.productPrices"
      :key="index"
      :index="index"
      :item="rowItem"
    />
    <button class="btn btn-success w-100 mb-4" @click="$parent.addProductPrices()">
      <i class="fas fa-plus"></i> {{ $t('Addition') }}
    </button>
  </div>
</template>
<script>
import priceItem from './priceItem.vue'
export default {
  components: {
    priceItem
  }
}
</script>
