<template>
  <div class="row form-group">
    <label :class="!withoutLabel ? 'col-md-4' : 'd-none'" v-if="!withoutLabel">
      {{ title }}
    </label>
    <div :class="withoutLabel ? 'col-md-12' : 'col-md-7'">
      <div class="search-container" ref="elementRef">
        <label :class="withoutLabel ? 'd-block' : 'd-none'">
          {{ title }}
        </label>
        <div :class="value ? 'add-input' : ''">
          <input
            type="search"
            autocomplete="off"
            v-model="search"
            class="form-control"
            :class="{ 'is-invalid': errors.length }"
            :placeholder="placeholder"
            @keydown="handelKey"
            @keyup="searchItem"
            @focus="searchItem"
            :disabled="disabled || value > 0"
          />
          <span class="btn btn-danger" v-if="value" @click="removeItem">
            <i class="fas fa-times"></i>
          </span>
        </div>
        <ul class="list-unstyled search-selects" v-if="searchItems.length > 0 && showItemsList">
          <li
            v-for="item in searchItems"
            :key="item.id"
            @click="selectItem(item.id)"
            :class="{ focused: item.focused }"
          >
            {{ item.name }}
            <template v-if="showProps && showProps.length">
              <div v-for="prop in showProps">
                {{ prop.title }}:
                <template v-if="prop.money">
                  {{ $moneyFormat(item[prop.key]) }}
                </template>
                <template v-else>
                  {{ item[prop.key] ? item[prop.key] : '--' }}
                </template>
              </div>
            </template>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-md-4"></div>
    <div class="col-md-7">
      <div class="invalid-feedback d-block">
        <ul class="list-unstyled">
          <li v-for="(error, index) in errors" :key="index">{{ $t(error) }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import Vue from 'vue'
import { ref, watch, onMounted } from 'vue'
import { useClickOutside } from '@/helpers/useClickOutside'

// Click outside logic
const { elementRef, isOutside } = useClickOutside()
watch(isOutside, (value, oldValue) => {
  if (value && oldValue != value) removeItemsList()
})

const search = ref('')
const searchItems = ref([])
const showItemsList = ref(false)

const searchItem = () => {
  // SearchRGX
  let searchRGX = new RegExp(search.value, 'i')
  // get search Items
  let items = props.options
    .sort(Vue.prototype.$dynamicSort({ orderBy: 'name', orderType: 'desc' }))
    .filter((el) => searchRGX.test(el.name))
    .slice(0, 15)
  // Show products list
  if (items.length) {
    searchItems.value = items
    showItemsList.value = true
  } else {
    searchItems.value = []
  }
}

const handelKey = (e) => {
  // Move up and down in items
  if (
    (e.key == 'ArrowDown' && searchItems.value.length != 0) ||
    (e.key == 'ArrowUp' && searchItems.value.length != 0)
  ) {
    let product = searchItems.value.find((el) => el.focused == true)
    let selected = searchItems.value.indexOf(product)
    let length = searchItems.value.length

    if (selected > -1) Vue.prototype.$set(searchItems.value[selected], 'focused', false)

    if (e.key == 'ArrowDown') {
      if (selected < length - 1) {
        Vue.prototype.$set(searchItems.value[selected + 1], 'focused', true)
      } else {
        Vue.prototype.$set(searchItems.value[0], 'focused', true)
      }
    } else if (e.key == 'ArrowUp') {
      if (selected > 0) {
        Vue.prototype.$set(searchItems.value[selected - 1], 'focused', true)
      } else {
        Vue.prototype.$set(searchItems.value[length - 1], 'focused', true)
      }
    }
  }
  // Select product
  else if (e.key == 'Enter') {
    let product = searchItems.value.find((el) => el.focused == true)
    if (product) selectItem(product.id)
  }
}

const selectItem = (id) => {
  emit('input', id)
  const item = props.options.find((el) => el.id == id)
  search.value = item.name
  showItemsList.value = false
}

const removeItemsList = () => (showItemsList.value = false)

const removeItem = () => {
  emit('input', null)
  searchItems.value = []
  search.value = ''
}

onMounted(() => {
  if (props.value) {
    const item = props.options.find((el) => el.id == props.value)
    if (item) search.value = item.name
  }
})

watch(
  () => props.value,
  () => {
    const item = props.options.find((el) => el.id == props.value)
    if (item) search.value = item.name
  }
)
const props = defineProps([
  'title',
  'disabled',
  'errors',
  'value',
  'options',
  'showProps',
  'placeholder',
  'withoutLabel'
])
const emit = defineEmits(['input'])
</script>
