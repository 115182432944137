<template>
  <div class="row pt-4 quotation-item mb-3 pb-3">
    <div class="col-xl-2">
      <label class="d-xl-none"> {{ $t('Account') }} </label>
      <div class="search-container" ref="elementRef">
        <!-- v-click-outside="removeItemList"-->
        <div :class="item.account_id != 0 ? 'add-input' : ''">
          <input
            type="text"
            class="form-control w-100"
            :class="{ 'is-invalid': item.error }"
            autocomplete="off"
            :placeholder="$t('Account name')"
            v-model="search"
            @keydown="handelKeyEvent"
            @keyup="searchItem"
            @focus="searchItem"
            :disabled="item.account_id"
          />
          <span class="btn btn-danger" v-if="item.account_id" @click="removeItem">
            <i class="fas fa-times"></i>
          </span>
        </div>
        <ul class="list-unstyled search-selects" v-if="searchItems.length > 0 && showItemsList">
          <li
            v-for="(item, index) in searchItems"
            :key="index"
            @click="changeAccount(item)"
            :class="{ focused: item.focused }"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="col-xl-2">
      <label class="d-xl-none"> {{ $t('Debit') }} </label>
      <input
        type="number"
        class="form-control w-100"
        :placeholder="$t('Debit')"
        v-model="item.debit"
      />
    </div>

    <div class="col-xl-2">
      <label class="d-xl-none"> {{ $t('Credit') }} </label>
      <input
        type="number"
        class="form-control w-100"
        :placeholder="$t('Credit')"
        v-model="item.credit"
      />
    </div>
    <div class="col-xl-3">
      <label class="d-xl-none"> {{ $t('Thats about') }} </label>
      <input
        class="form-control w-100"
        :placeholder="$t('Thats about')"
        v-model="item.description"
      />
    </div>

    <div class="col-xl-2">
      <label class="d-xl-none"> {{ $t('Cost center') }} </label>
      <select class="form-control w-100" v-model="item.cost_center_id">
        <option value="">{{ $t('Choose the cost center') }}</option>
        <option
          v-for="(costCenter, index) in $database.costCenters"
          :key="index"
          :value="costCenter.id"
        >
          {{ costCenter.name }}
        </option>
      </select>
    </div>

    <div class="col-xl-1">
      <button class="btn btn-danger" @click="$emit('deleteItem', index)">
        <i class="far fa-trash"></i>
      </button>
    </div>
  </div>
</template>
<script setup>
import Vue, { ref, watch } from 'vue'
import { useClickOutside } from '@/helpers/useClickOutside'

// Click outside logic
const { elementRef, isOutside } = useClickOutside()
watch(isOutside, (value, oldValue) => {
  if (value && oldValue != value) removeItemList()
})

const item = ref(props.value)

const search = ref('')
const searchItems = ref([])
const showItemsList = ref(false)

const searchItem = () => {
  showItemsList.value = true
  const editSearch = new RegExp(search.value, 'i')
  const items = Vue.prototype.$database.accounts
    .sort(Vue.prototype.$dynamicSort({ orderBy: 'name', orderType: 'desc' }))
    .filter((el) => editSearch.test(el.name) && el.is_main == 0)
    .slice(0, 20)
  searchItems.value = typeof items != 'undefined' ? items : []
}

const handelKeyEvent = (e) => {
  if ((e.key == 'ArrowDown' || e.key == 'ArrowUp') && searchItems.value.length != 0) {
    const item = searchItems.value.find((el) => el.focused == true)
    const selected = searchItems.value.indexOf(item)
    const length = searchItems.value.length
    if (selected > -1) Vue.prototype.$set(searchItems.value[selected], 'focused', false)
    if (e.key == 'ArrowDown')
      Vue.prototype.$set(
        searchItems.value[selected < length - 1 ? selected + 1 : 0],
        'focused',
        true
      )
    else if (e.key == 'ArrowUp')
      Vue.prototype.$set(
        searchItems.value[selected > 0 ? selected - 1 : length - 1],
        'focused',
        true
      )
  }

  if (e.key == 'Enter') {
    const item = searchItems.value.find((el) => el.focused == true)
    const selected = searchItems.value.indexOf(item)
    if (selected != -1) changeAccount(item)
  }
}

const changeAccount = (selectedItem) => {
  search.value = selectedItem.name
  item.value.account_id = selectedItem.id
  removeItemList()
}
const removeItem = () => {
  search.value = ''
  item.value.account_id = null
  removeItemList()
}

const removeItemList = () => (showItemsList.value = false)

watch(
  () => props.value,
  () => {
    if (props.value.account_id) {
      const account = Vue.prototype.$database.accounts.find((el) => el.id == props.value.account_id)
      if (account) search.value = account.name
    }
    item.value = props.value
  },
  { deep: true }
)

watch(
  () => item.value,
  () => emit('input', item.value),
  { deep: true }
)

if (props.value.account_id) {
  const account = Vue.prototype.$database.accounts.find((el) => el.id == props.value.account_id)
  if (account) search.value = account.name
}

const props = defineProps(['value', 'index'])
const emit = defineEmits(['deleteItem'])
</script>
