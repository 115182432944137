<template>
  <div class="container-fluid">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col ml-n2">
                <h4 class="mb-1">
                  <a
                    >{{ $t('Stores settlement') }} <strong># {{ item.code }}</strong></a
                  >
                </h4>
              </div>
              <div class="col-auto">
                <!-- <router-link
                  :to="path + '/' + item.id + '/edit'"
                  class="btn btn-sm btn-primary"
                  :title="$t('Edit')"
                >
                  <i class="fas fa-edit"></i>
                </router-link> -->

                <a @click="print()" class="btn btn-sm btn-primary" :title="$t('Print')">
                  <i class="fas fa-print"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="card card-body p-5">
          <StoresSettlementComponent :item="item" v-if="!loading" />
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">{{ $t('Invoice') }} : #{{ item.code }}</h4>
          </div>
          <div class="card-body">
            <p>
              {{ $t('Created in') }} {{ item.created }}, (<small
                >{{ $t('Last update') }} {{ item.updated }}</small
              >)
            </p>
            <p>
              <strong>{{ $t('Administrator') }}</strong> :
              {{ item.user ? item.user.name : '--' }}
            </p>
            <p>
              <strong>{{ $t('The store') }}</strong> :
              {{ item.store ? item.store.name : '--' }}
            </p>
          </div>
        </div>
        <showFile />
      </div>
      <div class="col-12">
        <HistoryCard :items="item.histories" v-if="$checkRole('history_show')" />
      </div>
      <PrintStoreSettlements
        v-if="printingSession"
        :item="printingSession"
        @onFinish="FinishPrinting"
      />
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import showFile from '@/elements/show/showFile.vue'
import StoresSettlementComponent from '@/print/components/storesSettlements.vue'
import HistoryCard from '@/components/historyCard.vue'
import PrintStoreSettlements from '@/print/storesSettlements.vue'

export default {
  data() {
    return {
      path: '/storesSettlements',
      item: {},
      salesPayments: {},
      salesPaymentForm: false,
      addId: this.$route.params.id,
      amount: 0,
      type: 'show',
      loading: true,
      printingSession: null,
      printInvoice: false,
      site: localStorage.getItem('site')
    }
  },
  mounted() {
    this.getitem()
  },
  methods: {
    getitem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.salesPayments = this.item.sales_payments
        this.amount = this.item.rest
        this.loading = false
      })
    },
    print() {
      this.printingSession = this.item
    },
    FinishPrinting() {
      this.printingSession = null
    }
  },
  components: {
    showFile,
    StoresSettlementComponent,
    PrintStoreSettlements,
    HistoryCard
  }
}
</script>
