<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <PageHeader
          :title="$t('sales')"
          :description="$t('From here you can control your sales reports')"
        />
      </div>
      <!-- تقارير العملاء -->
      <div class="col-md-6">
        <reportsCard
          class="title-form"
          :cardTitle="$t('customers')"
          :icon="'fa-user-alt'"
          v-if="
            ($user.role.available_reports.find(
              (el) => el.id == 1 || el.id == 2 || el.id == 3 || el.id == 4
            ) ||
              $user.admin) &&
            $site.customers_allow
          "
          :reports="[
            {
              name: $t('Total CustomerAccount'),
              link: '/customersTotalAccounts',
              role: $user.role.available_reports.find((el) => el.id == 1) || $user.admin
            },
            // {
            //   name: $t('Customer Account Details'),
            //   link: '/customersDetailsAccounts',
            //   role: $user.role.available_reports.find((el) => el.id == 2) || $user.admin
            // },
            {
              name: $t('Customer Assets'),
              link: '/customersBalance',
              role: $user.role.available_reports.find((el) => el.id == 3) || $user.admin
            },
            {
              name: $t('Customer Guide'),
              link: '/customersGuide',
              role: $user.role.available_reports.find((el) => el.id == 4) || $user.admin
            },

            {
              name: $t('inactive Clients'),
              link: '/customersInactive',
              role: $user.role.available_reports.find((el) => el.id == 4) || $user.admin
            },
            {
              name: $t('active Customers'),
              link: '/customersActive',
              role: $user.role.available_reports.find((el) => el.id == 4) || $user.admin
            },
            {
              name: $t('customer Traffic'),
              link: '/customerTraffic',
              role: $user.role.available_reports.find((el) => el.id == 4) || $user.admin
            },
            {
              name: $t('sales Collection'),
              link: '/salesCollection',
              role: $user.role.available_reports.find((el) => el.id == 4) || $user.admin
            }
          ]"
        />
      </div>
      <!-- تقارير المبيعات -->
      <div class="col-md-6">
        <reportsCard
          class=""
          :cardTitle="$t('Sales')"
          :icon="'fa-file-invoice-dollar'"
          v-if="
            ($user.role.available_reports.find((el) => el.id == 13 || el.id == 14) ||
              $user.admin) &&
            $site.invoices_allow
          "
          :reports="[
            {
              name: $t('Sales By Invoice'),
              link: './salesByInvoice',
              role: $user.role.available_reports.find((el) => el.id == 13) || $user.admin
            },
            {
              name: $t('Sales By Product'),
              link: '/salesByItem',
              role: $user.role.available_reports.find((el) => el.id == 14) || $user.admin
            },
            {
              name: $t('Sales By Section'),
              link: '/salesBySection',
              role: $user.role.available_reports.find((el) => el.id == 14) || $user.admin
            },
            {
              name: $t('customer Sales Prices'),
              link: '/customerSalesPrices',
              role: $user.role.available_reports.find((el) => el.id == 14) || $user.admin
            },
            {
              name: $t('sales By Customer'),
              link: '/salesByCustomer',
              role: $user.role.available_reports.find((el) => el.id == 14) || $user.admin
            }
          ]"
        />
        <!-- تقارير طلبات البيع -->
        <reportsCard
          class=""
          :cardTitle="$t('Sales Orders')"
          :icon="'fa-briefcase'"
          v-if="
            ($user.role.available_reports.find((el) => el.id == 21 || el.id == 22) ||
              $user.admin) &&
            $site.orders_allow
          "
          :reports="[
            {
              name: $t('Orders By Invoice'),
              link: './salesOrdersByInvoice',
              role: $user.role.available_reports.find((el) => el.id == 21) || $user.admin
            },
            {
              name: $t('Orders By Product'),
              link: '/salesOrdersByItem',
              role: $user.role.available_reports.find((el) => el.id == 22) || $user.admin
            }
          ]"
        />
        <!-- تقارير مرتجعات البيع -->
        <reportsCard
          class=""
          :cardTitle="$t('invoices')"
          :icon="'fa-exchange'"
          v-if="
            ($user.role.available_reports.find((el) => el.id == 17 || el.id == 18) ||
              $user.admin) &&
            $site.invoices_allow
          "
          :reports="[
            {
              name: $t('Returned By Invoice'),
              link: './salesReturnsByInvoice',
              role: $user.role.available_reports.find((el) => el.id == 17) || $user.admin
            },
            {
              name: $t('Returned By Product'),
              link: '/salesReturnsByItem',
              role: $user.role.available_reports.find((el) => el.id == 18) || $user.admin
            }
          ]"
        />
      </div>
    </div>
  </div>
</template>
<script>
import reportsCard from '@/elements/reports/reportsCard.vue'
import PageHeader from '@/components/pageHeader'
export default {
  components: {
    reportsCard,
    PageHeader
  }
}
</script>
