var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c(_setup.showHeader,{staticClass:"col-12 mt-3",attrs:{"icon":'treasure-chest',"title":_setup.item.name,"stat":_setup.item.stat,"statText":_setup.item.stat ? _vm.$t('Active') : _vm.$t('Inactive'),"subData":[
        {
          title: _vm.$t('Current balance'),
          value: this.$moneyFormat(_setup.item.balance)
        }
      ],"buttons":[
        {
          title: _vm.$t('Modulation'),
          icon: 'edit',
          link: _setup.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$checkRole('safes_edit')
        }
      ]}}),_c(_setup.showBoxs,{staticClass:"col-12",attrs:{"boxs":[
        {
          icon: 'dollar-sign',
          title: _vm.$t('Current balance'),
          value: this.$moneyFormat(_setup.item.balance),
          class: 'col-md-3'
        },
        {
          icon: 'credit-card-front',
          title: _vm.$t('Invoices count'),
          value: _setup.item.salesPaymentsCount,
          class: 'col-md-3'
        },
        {
          icon: 'credit-card-front',
          title: _vm.$t('Quotations count'),
          class: 'col-md-3',
          value: _setup.item.purchasePaymentsCount
        },
        {
          icon: 'usd-square',
          title: _vm.$t('Orders count'),
          class: 'col-md-3',
          value: _setup.item.expensesCount
        }
      ]}}),_c(_setup.showCard,{staticClass:"col-12",attrs:{"title":_vm.$t('Account info'),"columns":{
        firstColumn: [
          {
            icon: 'dollar-sign',
            title: _vm.$t('Balance'),
            value: this.$moneyFormat(_setup.item.balance)
          }
        ],
        secondColumn: [
          {
            icon: 'dollar-sign',
            title: _vm.$t('Opening balance'),
            value: this.$moneyFormat(_setup.item.opening_balance)
          }
        ]
      }}}),_c('div',{staticClass:"col-12"},[(_vm.$checkRole('history_show'))?_c(_setup.HistoryCard,{attrs:{"items":_setup.item.histories}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }