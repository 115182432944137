var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Layout,[_c(_setup.ShowHeader,{attrs:{"image":_setup.item.image,"icon":'user',"title":_setup.item.name,"stat":_setup.item.stat,"statText":_setup.item.stat ? _vm.$t('Active') : _vm.$t('Inactive'),"subData":[
      {
        title: _vm.$t('tax identification number'),
        value: _setup.item.tax_number
      }
    ],"buttons":[
      {
        title: _vm.$t('Modulation'),
        icon: 'edit',
        link: '/customers/' + _vm.$route.params.id + '/edit',
        role: _vm.$checkRole('customers_edit')
      },
      {
        title: _vm.$t('Comprehensive report'),
        icon: 'file-chart-pie',
        link: '/customersDetailsAccounts?customer_id=' + _vm.$route.params.id,
        role: _vm.$checkRole('reports_show')
      },
      {
        title: _vm.$t('Add a batch'),
        icon: 'dollar-sign',
        link: '/salesPayments/create?customer_id=' + _vm.$route.params.id,
        role: _vm.$checkRole('sales_payments_add')
      },
      {
        title: _vm.$t('Add a bid'),
        icon: 'calculator',
        link: '/quotations/create?customer_id=' + _vm.$route.params.id,
        role: _vm.$checkRole('quotations_add')
      },
      {
        title: _vm.$t('Add a sales bill'),
        icon: 'file-invoice-dollar',
        link: '/invoices/create?customer_id=' + _vm.$route.params.id,
        role: _vm.$checkRole('invoices_add')
      },
      {
        title: _vm.$t('Add a request'),
        icon: 'briefcase',
        link: '/orders/create?customer_id=' + _vm.$route.params.id,
        role: _vm.$checkRole('orders_add')
      }
    ]}}),_c(_setup.ShowFile,{attrs:{"file":_setup.item.file}}),_c(_setup.ShowCard,{attrs:{"title":_vm.$t('Background'),"columns":{
      firstColumn: [
        {
          icon: 'user',
          title: _vm.$t('Customer name'),
          value: _setup.item.name
        },
        {
          icon: 'dollar-sign',
          title: _vm.$t('Balance'),
          value: _vm.$moneyFormat(_vm.$option.balance_type === 1 ? _setup.item.balance * -1 : _setup.item.balance)
        }
      ],
      secondColumn: [
        {
          icon: 'dollar-sign',
          title: _vm.$t('Opening balance'),
          value: _vm.$moneyFormat(_setup.item.opening_balance)
        },
        {
          icon: 'toggle-on',
          title: _vm.$t('Customer status'),
          value: _setup.item.stat === 1 ? _vm.$t('effective') : _vm.$t('ineffective')
        }
      ]
    }}}),_c(_setup.ShowCard,{attrs:{"title":_vm.$t('Billing address'),"columns":{
      firstColumn: [
        {
          icon: 'map-marker-alt',
          title: _vm.$t('Address'),
          value: _setup.item.address1
        },
        {
          icon: 'location-arrow',
          title: _vm.$t('Governorate'),
          value: _setup.item.entity
        },
        {
          icon: 'map-marked',
          title: _vm.$t('Zip code'),
          value: _setup.item.zip
        }
      ],
      secondColumn: [
        {
          icon: 'map-marker-alt',
          title: _vm.$t('Itinerary'),
          value: _setup.item.address2
        },
        {
          icon: 'city',
          title: _vm.$t('City'),
          value: _setup.item.city
        },
        {
          icon: 'globe-americas',
          title: _vm.$t('State'),
          value: _setup.item.country
        }
      ]
    }}}),_c(_setup.ShowCard,{attrs:{"title":_vm.$t('Communication information'),"columns":{
      firstColumn: [
        {
          icon: 'phone',
          title: _vm.$t('Mobile number'),
          value: _setup.item.phone1 ? _setup.item.phone1 + ' - ' + _setup.item.phone2 : _setup.item.phone2
        }
      ],
      secondColumn: [
        {
          icon: 'at',
          title: _vm.$t('Email'),
          value: _setup.item.email
        }
      ]
    }}}),(_vm.$checkRole('history_show'))?_c(_setup.HistoryCard,{attrs:{"items":_setup.item.histories}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }