import branches from '@/pages/branches/index.vue'
import branchesForm from '@/pages/branches/form2.vue'

const routes = [
  {
    path: 'branches',
    name: 'branches',
    component: branches
  },
  {
    path: 'branches/create',
    name: 'branchesCreate',
    component: branchesForm
  },
  {
    path: 'branches/:id/edit',
    name: 'branchesEdit',
    component: branchesForm
  }
]

export default routes
