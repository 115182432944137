<template>
  <Layout
    :title="$t('New session')"
    :loading="loading"
    @close="() => $emit('close')"
    @action="submit()"
  >
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">{{ $t('Point of sale') }}</th>
          <th scope="col">{{ $t('Session number') }}</th>
          <th scope="col">{{ $t('User') }}</th>
          <!-- <th scope="col">{{ $t('Start date') }}</th> -->
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ Item?.point_of_sale.name }}</td>
          <td>{{ Item?.session_number }}</td>
          <td>{{ $user.name }}</td>
          <!-- <td>22/4/2025</td> -->
        </tr>
      </tbody>
    </table>

    <h3 class="mt-3">{{ $t('Payments details') }}</h3>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">{{ $t('Payment method') }}</th>
          <th scope="col">{{ $t('Amount') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="payment in payments">
          <td>{{ payment.name }}</td>
          <td class="p-0">
            <input
              type="number"
              class="w-100 form-control"
              :placeholder="$t('Amount')"
              v-model="payment.entered_amount"
            />
          </td>
        </tr>
        <tr v>
          <td>
            <strong>{{ $t('Total') }}</strong>
          </td>
          <td>
            <strong>{{
              $moneyFormat(
                payments.reduce(
                  (total, method) =>
                    (isNaN(parseFloat(method.entered_amount))
                      ? 0
                      : parseFloat(method.entered_amount)) + total,
                  0
                )
              )
            }}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </Layout>
</template>
<script setup>
// layout component
import Layout from '@/layouts/model'
// Form components
import Vue from 'vue'
import axios from 'axios'
import { ref } from 'vue'
import { createToastInterface } from 'vue-toastification'
import { $t } from '@/helpers/translate'

const toast = createToastInterface()
const payments = ref([])
const loading = ref(false)

const setPayments = (values) => {
  payments.value = values
}

const Item = ref(null)
const setItem = (value) => {
  Item.value = value
}
const path = '/pointOfSaleSessions'
const getItem = () => {
  loading.value = true
  axios.get(Vue.prototype.$linkGenerator(path + '/' + props.id)).then((response) => {
    setItem(response.data)
    setPayments(response.data.payments)
    loading.value = false
  })
}
getItem()
// const pointOfSale = Vue.prototype.$database.pointOfSales.find((el) => el.id == props.id)

// payments.value.push({
//   name: $t('Cash'),
//   safe_id: pointOfSale.safe_id,
//   amount: ''
// })
// pointOfSale.payment_methods.map((el) => {
//   payments.value.push({
//     name: el.name,
//     safe_id: el.safe_id,
//     amount: ''
//   })
// })

const submit = () => {
  loading.value = true
  axios
    .put(Vue.prototype.$linkGenerator(path + '/' + props.id), {
      payments: payments.value
    })
    .then((response) => {
      Vue.prototype.$updateDatabase(['pointOfSales'])
      toast.success($t('New session has been started successfully'), {
        position: 'top-center'
      })
      emit('onFinish')
      emit('close')
      loading.value = false
    })
}

const props = defineProps(['id'])
const emit = defineEmits(['close', 'onFinish'])
</script>
