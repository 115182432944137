var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c(_setup.ShowHeader,{staticClass:"col-12 mt-3",attrs:{"icon":'archway',"title":_setup.item.name,"stat":_setup.item.stat,"statText":_setup.item.stat ? _vm.$t('Active') : _vm.$t('Inactive'),"subData":[
        {
          title: _vm.$t('Current balance'),
          value: this.$moneyFormat(_setup.item.cost)
        }
      ],"buttons":[
        {
          title: _vm.$t('Edit'),
          icon: 'edit',
          link: _setup.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$checkRole('capitals_edit')
        }
      ]}}),_c(_setup.ShowCard,{staticClass:"col-12",attrs:{"title":_vm.$t('Origin information'),"columns":{
        firstColumn: [
          {
            icon: 'signature',
            title: _vm.$t('Original name'),
            value: _setup.item.name
          },
          {
            icon: 'minus-hexagon',
            title: _vm.$t('Depreciation rate'),
            value: _setup.item.depreciation_rate
          },
          {
            icon: 'calendar',
            title: _vm.$t('Purchase date'),
            value: _setup.item.date
          }
        ],
        secondColumn: [
          {
            icon: 'dollar-sign',
            title: _vm.$t('Value'),
            value: this.$moneyFormat(_setup.item.cost)
          },
          {
            icon: 'toggle-on',
            title: _vm.$t('Status'),
            value: _setup.item.stat != 0 ? _vm.$t('effective') : _vm.$t('Ineffective')
          },
          {
            icon: 'calendar',
            title: _vm.$t('Date of sale'),
            value: _setup.item.expiry_date
          }
        ]
      },"paragraphs":[
        {
          title: _vm.$t('Description of the original'),
          value: _setup.item.descripton
        },
        { title: _vm.$t('Other detailed data'), value: _setup.item.details },
        { title: _vm.$t('Reviews'), value: _setup.item.notes }
      ]}}),_c('div',{staticClass:"col-12"},[(_vm.$checkRole('history_show'))?_c(_setup.HistoryCard,{attrs:{"items":_setup.item.histories}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }