var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('Product statement')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Product'),"plaseholder":_vm.$t('Choose product'),"show":'name',"refrance":'id',"value":_vm.params.product_id,"values":this.$database.products.concat(this.$database.materials)},model:{value:(_vm.params.product_id),callback:function ($$v) {_vm.$set(_vm.params, "product_id", $$v)},expression:"params.product_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Store'),"plaseholder":_vm.$t('Choose store'),"show":'name',"refrance":'id',"value":_vm.params.store_id,"values":_vm.$database.stores},model:{value:(_vm.params.store_id),callback:function ($$v) {_vm.$set(_vm.params, "store_id", $$v)},expression:"params.store_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('product statement'),"details":[
            { title: _vm.$t('Product name'), value: _vm.productName },
            { title: _vm.$t('Store name'), value: _vm.storeName }
          ]}}),_c('reportTable',{attrs:{"header":[
            { colspan: 6, value: 'الرصيد الأفتتاحي' },
            {
              colspan: 1,
              value: _vm.startBalance
            }
          ],"emptyTableText":_vm.$t('there are no data at the moment'),"emptyTableSubText":_vm.$t('Try adding some items and try again'),"cloumns":[
            { column: 'code', title: _vm.$t('Code'), type: 'reportCode' },
            { column: 'date', title: _vm.$t('Date'), type: 'text' },
            { column: 'type', title: _vm.$t('Type'), type: 'reportType' },
            { column: 'quantity', title: _vm.$t('Quantity'), type: 'quantity' },

            {
              column: 'debit',
              title: _vm.$t('Creditor'),
              type: 'textOrEmpty'
            },
            {
              column: 'creditor',
              title: _vm.$t('Debtor'),
              type: 'textOrEmpty'
            },
            {
              column: 'new_balance',
              title: _vm.$t('Balance'),
              type: 'text'
            }
          ],"footer":[
            { colspan: 6, value: 'الرصيد النهائي' },
            {
              colspan: 1,
              value: _vm.endBalance
            }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }