<template>
  <div v-if="!loading">
    <div class="inv-design" :class="$option.invoices_print_type == 3 ? 'inv-design-5cm' : ''">
      <img :src="logo" class="img-fluid mb-4 sm-inv-img" v-if="$option.image != null" />
      <div class="text-center">
        <p class="f-14">{{ $option.name }}</p>
        <p class="f-12" v-if="$option.address1">{{ $option.address1 }}</p>
        <p class="f-12" v-if="$option.mobile">{{ $option.mobile }}</p>
        <p class="f-12">{{ item?.code }}</p>
        <div style="display: flex">
          <div style="width: 4cm; text-align: right">
            <p class="f-12">
              التاريخ :
              <!-- <strong>{{
                item.date ? $dateFormat(item.date) : $dateFormat(Date.now() / 1000)
              }}</strong> -->
            </p>
            <p class="f-12">
              أسم المسئول :
              <strong>{{ item?.user?.name ?? $user.name }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div>
        <!-- <div style="text-align: center">
          <p class="f-12">
            اسم العميل: <strong>{{ item.customer.name }}</strong>
          </p>
          <p class="f-12">
            رقم الهاتف: <strong>{{ item.customer?.phone1 }}</strong>
          </p>
          <p class="f-12" v-if="item.customer.city || item.customer.country">
            العنوان:
            <strong>
              {{ item.customer.city }}{{ item.customer.city && item.customer.country ? ',' : ''
              }}{{ item.customer.country }}</strong
            >
          </p>
        </div>
        <div style="text-align: center" v-if="item.client_type == 1">
          <p class="f-12">
            اسم العميل: <strong>{{ item.supplier.name }}</strong>
          </p>
          <p class="f-12">
            رقم الهاتف: <strong>{{ item.supplier?.phone1 }}</strong>
          </p>
          <p class="f-12" v-if="item.supplier.city || item.supplier.country">
            العنوان:
            <strong>
              {{ item.supplier.city }}{{ item.supplier.city && item.supplier.country ? ',' : '' }}
              {{ item.supplier.country }}</strong
            >
          </p>
        </div> -->
      </div>
      <div class="table-responsive">
        <div>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td>{{ $t('Point of sale') }}</td>
                <td class="">{{ item.point_of_sale.name }}</td>
              </tr>
              <tr>
                <td>{{ $t('Session number') }}</td>
                <td class="">{{ item.session_number }}</td>
              </tr>
              <tr>
                <td>{{ $t('User') }}</td>
                <td class="">{{ item.user?.name ?? '--' }}</td>
              </tr>
              <tr>
                <td>{{ $t('Start date') }}</td>
                <td class="">
                  {{ new Date(item.start_date).toISOString().replace('T', ' ').slice(0, 19) }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('End date') }}</td>
                <td class="">
                  {{ new Date(item.end_date).toISOString().replace('T', ' ').slice(0, 19) }}
                </td>
              </tr>
            </tbody>
          </table>

          <h3 class="mt-3">{{ $t('Payments details') }}</h3>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">{{ $t('Payment method') }}</th>
                <th scope="col">{{ $t('Amount') }}</th>
                <th scope="col">{{ $t('Actual amount') }}</th>
                <th scope="col">{{ $t('Deference') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="payment in item.payments">
                <td>{{ payment.name }}</td>
                <td>{{ $moneyFormat(parseFloat(payment.entered_amount)) }}</td>
                <td>{{ $moneyFormat(parseFloat(payment.actual_amount)) }}</td>
                <td>
                  {{
                    $moneyFormat(
                      parseFloat(payment.entered_amount) - parseFloat(payment.actual_amount)
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('Total') }}</strong>
                </td>
                <td>
                  <strong>{{ $moneyFormat(parseFloat(item.entered_amount)) }}</strong>
                </td>
                <td>
                  <strong>{{ $moneyFormat(parseFloat(item.actual_amount)) }}</strong>
                </td>
                <td>
                  <strong>{{
                    $moneyFormat(parseFloat(item.entered_amount) - parseFloat(item.actual_amount))
                  }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <p
          class="text-muted mb-0"
          v-html="$option.invoices_print_footer != null ? $option.invoices_print_footer : ''"
        ></p> -->
      </div>
    </div>

    <!-- <div class="row invoice-show hide-w-8" v-if="$option.invoices_print_type == 0">
      <div class="col-12">
        <div class="table-responsive">
          <div>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">{{ $t('Point of sale') }}</th>
                  <th scope="col">{{ $t('Session number') }}</th>
                  <th scope="col">{{ $t('User') }}</th>
                  <th scope="col">{{ $t('Start date') }}</th>
                  <th scope="col">{{ $t('End date') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ item.point_of_sale.name }}</td>
                  <td>{{ item.session_number }}</td>
                  <td>{{ item.user?.name ?? '--' }}</td>
                  <td>
                    {{ new Date(item.start_date).toISOString().replace('T', ' ').slice(0, 19) }}
                  </td>
                  <td>
                    {{ new Date(item.end_date).toISOString().replace('T', ' ').slice(0, 19) }}
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 class="mt-3">{{ $t('Payments details') }}</h3>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">{{ $t('Payment method') }}</th>
                  <th scope="col">{{ $t('Amount') }}</th>
                  <th scope="col">{{ $t('Actual amount') }}</th>
                  <th scope="col">{{ $t('Deference') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="payment in item.payments">
                  <td>{{ payment.name }}</td>
                  <td>{{ $moneyFormat(parseFloat(payment.entered_amount)) }}</td>
                  <td>{{ $moneyFormat(parseFloat(payment.actual_amount)) }}</td>
                  <td>
                    {{
                      $moneyFormat(
                        parseFloat(payment.entered_amount) - parseFloat(payment.actual_amount)
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>{{ $t('Total') }}</strong>
                  </td>
                  <td>
                    <strong>{{ $moneyFormat(parseFloat(item.entered_amount)) }}</strong>
                  </td>
                  <td>
                    <strong>{{ $moneyFormat(parseFloat(item.actual_amount)) }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      $moneyFormat(parseFloat(item.entered_amount) - parseFloat(item.actual_amount))
                    }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="w-100" v-if="item.notes != null">
          <hr class="my-3" />
          <h4 class="text-uppercase">{{ $t('notes') }}</h4>
          <p class="text-muted mb-0">{{ item.notes }}</p>
        </div>
        <div class="w-100" v-if="item.terms != null">
          <hr class="my-3" />
          <h4 class="text-uppercase">
            {{ $t('Terms of offer') }}
          </h4>
          <p class="text-muted mb-0">{{ item.terms }}</p>
        </div>
        <div class="w-100 inv-footer" v-if="$option.invoices_print_footer != null">
          <hr class="my-3" />
          <p
            class="text-muted mb-0"
            v-html="$option.invoices_print_footer != null ? $option.invoices_print_footer : ''"
          ></p>
        </div>
      </div>
    </div> -->

    <!-- <div class="design-1-container" v-if="$option.invoices_print_type == 4">
      <div class="header">
        <qrcode-vue
          :value="item.qrCode"
          size="130"
          level="H"
          render-as="svg"
          v-if="$option.tax_invoice"
        />
        <div class="companydetails">
          <h1>{{ $option.name }}</h1>
          <p>الرقم الضريبي: {{ $option.tax_number }}</p>
          <p v-if="$option.commercial_register">السجل التجاري :{{ $option.commercial_register }}</p>
          <p v-if="$option.telephone">الهاتف: {{ $option.telephone }}</p>
          <p>{{ $option.country }}</p>
          <p>
            {{ $option.address1 }}
            {{ $option.address1 && $option.city ? ',' : '' }}
            {{ $option.city }}
          </p>
        </div>
      </div>
      <div class="info">
        <div class="invoive-detail">
          <div class="invoice-num">
            <strong>رقم الفاتورة<br />Invoice No</strong>
            <p>{{ item.code }}</p>
          </div>
          <div class="invoice-date">
            <strong>تاريخ الفاتورة<br />Invoice Date</strong>
            <p>
              {{ item.date ? $dateFormat(item.date) : $dateFormat(Date.now() / 1000) }}
            </p>
          </div>
        </div>
        <div class="cleint">
          <h2 class="cleint-name">العميل<br />Cleint</h2>
          <div class="cleint-info" v-if="item.client_type == 0">
            <strong>{{ item.customer.name }}</strong>
            <p v-if="item.customer.tax_number">الرقم الضريبي :{{ item.customer.tax_number }}</p>
            <p>{{ item.customer?.phone1 }}</p>
            <p>{{ item.customer.mobile2 }}</p>
            <p>{{ item.customer.country }}</p>
            <p>
              {{ item.customer.address1 }}
              {{ item.customer.address1 && item.customer.city ? ',' : '' }}
              {{ item.customer.city }}
            </p>
          </div>
          <div class="cleint-info" v-if="item.client_type == 1">
            <strong>{{ item.supplier.name }}</strong>
            <p v-if="item.supplier.tax_number">الرقم الضريبي :{{ item.supplier.tax_number }}</p>
            <p>{{ item.supplier?.phone1 }}</p>
            <p>{{ item.supplier.country }}</p>
            <p>
              {{ item.supplier.address1 }}
              {{ item.supplier.address1 && item.supplier.city ? ',' : '' }}
              {{ item.supplier.city }}
            </p>
          </div>
        </div>
      </div>
      <div class="container-of-table">
        <div>
          <div>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">{{ $t('Point of sale') }}</th>
                  <th scope="col">{{ $t('Session number') }}</th>
                  <th scope="col">{{ $t('User') }}</th>
                  <th scope="col">{{ $t('Start date') }}</th>
                  <th scope="col">{{ $t('End date') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ item.point_of_sale.name }}</td>
                  <td>{{ item.session_number }}</td>
                  <td>{{ item.user?.name ?? '--' }}</td>
                  <td>
                    {{ new Date(item.start_date).toISOString().replace('T', ' ').slice(0, 19) }}
                  </td>
                  <td>
                    {{ new Date(item.end_date).toISOString().replace('T', ' ').slice(0, 19) }}
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 class="mt-3">{{ $t('Payments details') }}</h3>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">{{ $t('Payment method') }}</th>
                  <th scope="col">{{ $t('Amount') }}</th>
                  <th scope="col">{{ $t('Actual amount') }}</th>
                  <th scope="col">{{ $t('Deference') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="payment in item.payments">
                  <td>{{ payment.name }}</td>
                  <td>{{ $moneyFormat(parseFloat(payment.entered_amount)) }}</td>
                  <td>{{ $moneyFormat(parseFloat(payment.actual_amount)) }}</td>
                  <td>
                    {{
                      $moneyFormat(
                        parseFloat(payment.entered_amount) - parseFloat(payment.actual_amount)
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>{{ $t('Total') }}</strong>
                  </td>
                  <td>
                    <strong>{{ $moneyFormat(parseFloat(item.entered_amount)) }}</strong>
                  </td>
                  <td>
                    <strong>{{ $moneyFormat(parseFloat(item.actual_amount)) }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      $moneyFormat(parseFloat(item.entered_amount) - parseFloat(item.actual_amount))
                    }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3 class="mt-3">{{ $t('Payments details') }}</h3>
          <div>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">{{ $t('Point of sale') }}</th>
                  <th scope="col">{{ $t('Session number') }}</th>
                  <th scope="col">{{ $t('User') }}</th>
                  <th scope="col">{{ $t('Start date') }}</th>
                  <th scope="col">{{ $t('End date') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ item.point_of_sale.name }}</td>
                  <td>{{ item.session_number }}</td>
                  <td>{{ item.user?.name ?? '--' }}</td>
                  <td>
                    {{ new Date(item.start_date).toISOString().replace('T', ' ').slice(0, 19) }}
                  </td>
                  <td>
                    {{ new Date(item.end_date).toISOString().replace('T', ' ').slice(0, 19) }}
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 class="mt-3">{{ $t('Payments details') }}</h3>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">{{ $t('Payment method') }}</th>
                  <th scope="col">{{ $t('Amount') }}</th>
                  <th scope="col">{{ $t('Actual amount') }}</th>
                  <th scope="col">{{ $t('Deference') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="payment in item.payments">
                  <td>{{ payment.name }}</td>
                  <td>{{ $moneyFormat(parseFloat(payment.entered_amount)) }}</td>
                  <td>{{ $moneyFormat(parseFloat(payment.actual_amount)) }}</td>
                  <td>
                    {{
                      $moneyFormat(
                        parseFloat(payment.entered_amount) - parseFloat(payment.actual_amount)
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>{{ $t('Total') }}</strong>
                  </td>
                  <td>
                    <strong>{{ $moneyFormat(parseFloat(item.entered_amount)) }}</strong>
                  </td>
                  <td>
                    <strong>{{ $moneyFormat(parseFloat(item.actual_amount)) }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      $moneyFormat(parseFloat(item.entered_amount) - parseFloat(item.actual_amount))
                    }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="money-output">
        <div class="total">
          <strong
            >المجموع<br />
            Subtotal</strong
          >
          <p>
            {{ $moneyFormat(item.subtotal + item.discount) }}
          </p>
        </div>
        <div class="total" v-if="item.edit">
          <strong>
            {{ item.edit_text ? item.edit_text : $t('Modification') }}
          </strong>
          <p>{{ $moneyFormat(item.edit) }}</p>
        </div>
        <div class="total" v-if="item.delivery">
          <strong
            >التوصيل<br />
            delivery</strong
          >
          <p>{{ $moneyFormat(item.delivery) }}</p>
        </div>
        <div class="total" v-if="item.discount">
          <strong
            >خصم<br />
            Discount</strong
          >
          <p>- {{ $moneyFormat(item.discount) }}</p>
        </div>
        <div class="total" v-if="item.tax">
          <strong
            >الضريبة<br />
            Tax</strong
          >
          <p>{{ $moneyFormat(item.tax) }}</p>
        </div>
        <div class="total" v-if="item.edit || item.delivery || item.discount || item.tax">
          <strong>الأجمالي<br />Total</strong>
          <p>
            <strong>{{ $moneyFormat(item.total) }} </strong>
          </p>
        </div>
        <template v-if="$option.show_old_balance">
          <div class="total">
            <strong
              >الرصيد السابق<br />
              Previous Balance</strong
            >
            <p>
              {{
                $moneyFormat($option.balance_type == 0 ? item.old_balance : item.old_balance * -1)
              }}
            </p>
          </div>
          <div class="total">
            <strong
              >الرصيد الكلي<br />
              Total Balance</strong
            >
            <p>
              {{
                $moneyFormat($option.balance_type == 0 ? item.new_balance : item.new_balance * -1)
              }}
            </p>
          </div>
          <div class="total" v-if="item.paid">
            <strong>المدفوع<br />Paid</strong>
            <p>{{ $moneyFormat(item.paid) }}</p>
          </div>
          <div class="total" v-if="item.paid">
            <strong>الرصيد النهائي<br />final balance</strong>
            <p>
              {{
                item.salesPayment
                  ? $moneyFormat(
                      $option.balance_type == 0
                        ? item.salesPayment.new_balance
                        : item.salesPayment.new_balance * -1
                    )
                  : $moneyFormat(
                      $option.balance_type == 0 ? item.new_balance : item.new_balance * -1
                    )
              }}
            </p>
          </div>
        </template>
      </div>
      <p class="tafqeet-style">اجمالي الفاتورة فقط {{ $tafqeet(item.total) }}</p>
      <div class="w-100" v-if="item.notes != null">
        <hr class="my-3" />
        <h4 class="text-uppercase">{{ $t('notes') }}</h4>
        <div class="mb-0" v-html="item.notes"></div>
      </div>
      <div class="w-100" v-if="item.terms != null">
        <hr class="my-3" />
        <h4 class="text-uppercase">
          {{ $t('Terms of offer') }}
        </h4>
        <div class="mb-0" v-html="item.terms"></div>
      </div>

      <div class="signeture-container">
        <div
          class="signeture-item"
          v-for="(signeture, index) in $option.invoices_signatures_data"
          :key="index"
        >
          <div class="signeture-name">{{ signeture.name }}</div>
          <div class="signeture-points">..........................</div>
        </div>
      </div>
    </div> -->

    <div class="show-iframe" v-if="$option.invoices_print_type == 5">
      <!-- <PointOfSale :item="item" style="width: 8cm; margin: 0px auto; background: white" /> -->
    </div>
  </div>
</template>
<script>
import PointOfSale from '@/print/components/pointOfSale'
export default {
  data() {
    return {
      logo: localStorage.getItem('logo')
    }
  },
  props: {
    item: {},
    loading: null
  },
  components: {
    PointOfSale
  }
}
</script>
