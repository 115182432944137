<template>
  <div class="row container-fluid">
    <div class="col-12">
      <PageHeader
        :title="$t('Manufacturing')"
        :description="$t('From here you can control your manufacturing reports')"
      />
    </div>
    <!-- Manufacturing reports -->
    <reportsCard
      class="col-md-6"
      :cardTitle="$t('Manufacturing operations')"
      :icon="'fa-hammer'"
      :reports="[
        {
          name: $t('Manufacturing deposits by items'),
          link: './manufacturingDepositsByItem',
          role: true
        },

        {
          name: $t('Manufacturing withdrawals by items'),
          link: '/manufacturingWithdrawalsByItem',
          role: true
        },
        {
          name: $t('Manufacturing models used count'),
          link: '/manufacturingModelsUsedCount',
          role: true
        }
      ]"
    />
  </div>
</template>
<script>
import reportsCard from '@/elements/reports/reportsCard.vue'
import PageHeader from '@/components/pageHeader'
export default {
  components: {
    reportsCard,
    PageHeader
  }
}
</script>
