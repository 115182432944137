<template>
  <div
    class="col-xl-3 col-lg-2-4 col-md-4 col-6"
    :class="{
      'products-without-image': !options.show_products_image
    }"
  >
    <div class="card casher-product">
      <!-- Product image -->
      <div class="casher-product-image" @click="addProduct(item.id)">
        <div class="dummy"></div>
        <div
          class="image"
          :class="{ noImage: !item.image }"
          :style="item.image != null ? image : ''"
        ></div>
      </div>
      <!-- Product name -->
      <div class="casher-product-name" @click="addProduct(item.id)">
        {{ item.name }}
      </div>
      <!-- Product details -->
      <!-- <div class="pos-btn-size-container" v-if="item.productDetails.length > 0">
        <button
          class="pos-btn-size"
          :class="{ active: detailId === index }"
          v-for="(productDetail, index) in item.productDetails"
          :key="index"
          @click="selectDetail(index)"
        >
          {{ productDetail.name }}
        </button>
      </div> -->
      <!-- Product quantity -->
      <div class="price-container">
        <div class="casher-product-price">
          {{ $moneyFormat(item.price_with_tax) }}
        </div>
        <div class="item-quantity item-out-quantity sm-quantity-btn">
          <button class="btn btn-light" @click="plusOne()">
            <i class="fas fa-plus"></i>
          </button>
          <div class="item-quantity-inner">{{ quantity }}</div>
          <button class="btn btn-light" @click="minusOne()">
            <i class="fas fa-minus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
const image =
  'background-image: url("' +
  localStorage.getItem('site') +
  '/companiesUploads/' +
  localStorage.getItem('subdomain') +
  '/products/' +
  props.item.image +
  '");'

// Quantity control
const quantity = ref(1)
const plusOne = () => (quantity.value += 1)
const minusOne = () => {
  if (quantity.value > 1) this.quantity -= 1
}

// const price = ref(props.item.price)

// Add product
const addProduct = (id) => {
  emits('addProduct', id, quantity.value)
  quantity.value = 1
}

// const detailId = ref('')

// addItmeDetail(id, detailId) {
//   var product = this.$parent.filteredProducts.find((el) => el.id == id && !el.printedCheck)
//   var detail = product.productDetails[detailId]
//   const cartProduct = this.$parent.item.invoiceItems.find(
//     (el) => el.id == id && el.detail_id == detail.id
//   )
//   var productInCart = this.$parent.item.invoiceItems.indexOf(cartProduct)
//   if (!product.allow_product_options) {
//     if (productInCart != -1 && !this.$parent.item.invoiceItems[productInCart].printedCheck) {
//       if (this.quantity > 0) {
//         this.$parent.item.invoiceItems[productInCart].quantity =
//           parseFloat(this.$parent.item.invoiceItems[productInCart].quantity) +
//           parseFloat(this.quantity)
//       } else {
//         this.$parent.item.invoiceItems[productInCart].quantity =
//           parseFloat(this.$parent.item.invoiceItems[productInCart].quantity) + 1
//       }
//     } else {
//       if (this.quantity > 0) {
//         product.quantity = this.quantity
//       } else {
//         product.quantity = 1
//       }
//       var price = product.price
//       price = detail.price - 0

//       if (this.$parent.item.priceList_id > 0) {
//         this.$database.productPrices.find(
//           (el) =>
//             el.priceList_id == this.$parent.item.priceList_id && el.product_id == product.id
//         )
//         if (priceList) {
//           price = priceList.price
//         }
//       }

//       this.$parent.item.invoiceItems.unshift({
//         id: product.id,
//         name: product.name,
//         detail_id: detail.id,
//         price: price,
//         quantity: product.quantity,
//         detailName: detail.name,
//         discount: 0,
//         productDetails: product.productDetails,
//         section_id: product.section_id
//       })
//     }
//     this.$parent.quantity = ''
//     this.search = ''
//     this.$parent.calculateTotal()
//   } else {
//     this.$parent.productOptionsFormShow = true
//     this.$parent.productOptionsId = product.id
//     this.$parent.productOptionsDetailId = detailId
//     this.$parent.productOptionsQuantity = this.quantity
//   }
//   this.quantity = 1
// },

// const selectDetail = (id) => {
//   detailId.value = id
//   price.value = props.item.productDetails[id].price
// }

// if (props.item.productDetails.length > 0) this.selectDetail(0)

const props = defineProps(['item', 'options'])
const emits = defineEmits(['addProduct'])
</script>
