<template>
  <div>
    <router-link :to="addLink" class="btn add-btn" v-if="addLink">
      <i class="fas fa-plus"></i>
    </router-link>
    <button
      class="btn d-lg-none d-block d-md-none"
      @click="$emit('filter')"
      v-if="showFilterButton"
    >
      <i class="fas fa-filter"></i>
    </button>

    <button @click="$emit('addAction')" class="btn add-btn" v-if="addActionRole">
      <i class="fas fa-plus"></i>
    </button>
  </div>
</template>
<script>
export default {
  props: ['addLink', 'addActionRole', 'showFilterButton']
}
</script>
