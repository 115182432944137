import Vue from 'vue'
import axios from 'axios'

var tables = [
  'customers',
  'suppliers',
  'accounts',
  'partners',
  'projects',
  'projectItems',
  'safes',
  'bankAccounts',
  'capitals',
  'stores',
  'products',
  'subscriptions',
  'materials',
  'pickupLocations',
  'employees',
  'manufacturingModels',
  'manufacturingModelProducts',
  'manufacturingModelMaterials',
  'pointOfSales',
  'paymentMethods',
  'paymentFees',
  'expenseSections',
  'incomeSections',
  'taxes',
  'discounts',
  'invoiceGroups',
  'sections',
  'units',
  'deliveries',
  'cities',
  'shippingAddresses',
  'marketers',
  'priceLists',
  'details',
  'productOptions',
  'productOptionItems',
  'productQuantities',
  'productPrices',
  'productUnits',
  'signatures',
  'invoiceStatus',
  'sectionsAccounts',
  'unitsCoefficients',
  'users',
  'companies',
  'roles',
  'taxPayments',
  'paymentsSections',
  'capitalsSections',
  'costCenters',
  'branches',
  'shortcuts'
]

const database = Vue.observable({ database: {} })

Object.defineProperty(Vue.prototype, '$database', {
  get() {
    return database.database
  },
  set(value) {
    database.database = value
  }
})

try {
  var databas =
    localStorage.getItem('database') !== null ? JSON.parse(localStorage.getItem('database')) : {}
  tables.forEach((table) => {
    if (databas[table] === 'undefined') {
      databas[table] = []
    }
  })
  Vue.set(Vue.prototype, '$database', databas)
} catch (e) {}

Vue.prototype.$updateDatabase = function (data) {
  if (navigator.onLine) {
    if (!data) data = tables
    data.forEach((item) => {
      axios
        .get(Vue.prototype.$linkGenerator('/allData'), {
          params: { requiredData: [item] }
        })
        .then((response) => {
          var database = Vue.prototype.$database
          var data = response.data
          tables.forEach((table) => {
            if (data[table]) {
              database[table] = data[table]
            }
          })
          Vue.set(Vue.prototype, '$database', database)
          localStorage.setItem('database', JSON.stringify(Vue.prototype.$database))
        })
    })
  }
}

// const database = Vue.observable({ database: {} })

// Object.defineProperty(Vue.prototype, '$database', {
//   get() {
//     return database.database
//   },
//   set(value) {
//     database.database = value
//   }
// })

// const prepareDB = () => {
//   return new Promise((resolve, reject) => {
//     const request = indexedDB.open("MyDatabase", 2);

//     request.onupgradeneeded = (event) => {
//       const db = event.target.result;
//       if (!db.objectStoreNames.contains("MyStore")) {
//         db.createObjectStore("MyStore", { keyPath: "id" });
//       }
//     };

//     request.onsuccess = () => {
//       resolve('success');
//     };

//     request.onerror = (event) => {
//       reject(`Database error: ${event.target.error.message}`);
//     };
//   });
// };

// const insertOrUpdateDB = (data) => {
//   return new Promise((resolve, reject) => {
//       const request = indexedDB.open("MyDatabase", 2);
//       request.onupgradeneeded = (event) => {
//           const db = event.target.result;
//           if (!db.objectStoreNames.contains("MyStore")) {
//               db.createObjectStore("MyStore", { keyPath: "id" });
//           }
//       };
//       request.onsuccess = (event) => {
//           const db = event.target.result;
//           const transaction = db.transaction("MyStore", "readwrite"); // Ensure 'readwrite' mode
//           const store = transaction.objectStore("MyStore");
//           store.put({ id: 1, data: data }); // Insert or update operation
//           transaction.oncomplete = () => resolve('success');
//           transaction.onerror = (e) => reject(`Transaction error: ${e.target.error.message}`);
//       };
//       request.onerror = (e) => reject(`Database error: ${e.target.error.message}`);
//   });
// };

// const getFromDB = () => {
//   return new Promise((resolve) => {
//       const request = indexedDB.open("MyDatabase", 2);
//       request.onsuccess = (event) => {
//           const db = event.target.result;
//           const transaction = db.transaction("MyStore", "readonly");
//           const store = transaction.objectStore("MyStore");
//           const getRequest = store.get(1);
//           getRequest.onsuccess = (event) => {
//             const data =  event.target.result ? JSON.parse(event.target.result.data) : {}
//             resolve(data);
//           }
//       };
//       request.onerror = () => reject({});
//   });
// }

// const getTables = async () => {
//   try {
//     await prepareDB();
//     const database = await getFromDB();

//     tables.forEach((table) => {
//       if (typeof database[table] === 'undefined') {
//         database[table] = [];
//       }
//     });

//     Vue.set(Vue.prototype, '$database', database);
//   } catch (e) {
//     console.error("Error initializing database:", e);
//   }
// };

// getTables();

// Vue.prototype.$updateDatabase = function (data) {
//   if (navigator.onLine) {
//     var database = Vue.prototype.$database
//     axios
//       .get(Vue.prototype.$linkGenerator('/allData'), {
//         params: { requiredData: data }
//       })
//       .then((response) => {
//         var data = response.data
//         tables.forEach((table) => {
//           if (data[table]) {
//             database[table] = data[table]
//           }
//         })
//         Vue.set(Vue.prototype, '$database', database)
//         insertOrUpdateDB(JSON.stringify(Vue.prototype.$database))
//       })
//   }
// }
