<template>
  <div class="tree position-relative h-auto">
    <div class="tree-child p-2 pointer">
      <template v-if="item.is_main == 0">
        <router-link :to="'/totalAccounts?id=' + item.id">
          <!-- File icon -->
          <i class="fa fa-file px-2"></i>
          <!-- Item name -->
          <span>{{ $t(item.name) }}</span>
        </router-link>
      </template>
      <template v-else>
        <!-- Folder close icon -->
        <i class="fa fa-folder px-2" v-if="!isOpen" @click="toggleOpen"></i>
        <!-- Folder open icon -->
        <i class="fa fa-folder-open px-2" v-else @click="toggleOpen"></i>
        <!-- Item name -->
        <span @click="$emit('selectItem', item.id)">{{ $t(item.name) }}</span>
      </template>
    </div>
    <template v-if="items.length && isOpen">
      <RecursiveComponent
        class="inner-tree"
        :item="child"
        v-for="child in items"
        :key="child.name"
        @selectItem="(item) => $emit('selectItem', item)"
      />
    </template>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import RecursiveComponent from './treeItem'
import Vue from 'vue'
import axios from 'axios'

const firstOpen = ref(true)
const isOpen = ref(false)
const toggleOpen = () => {
  if (!isOpen.value && firstOpen.value) {
    getItems()
    firstOpen.value = false
  }
  isOpen.value = !isOpen.value
}

const items = ref([])
const loading = ref(false)
const getItems = async () => {
  loading.value = true
  const response = await axios.get(Vue.prototype.$linkGenerator('/accounts'), {
    params: {
      account_id: props.item.id
    }
  })
  items.value = response.data
  loading.value = false
}

const props = defineProps(['item'])
const emits = defineEmits(['selectItem'])
</script>
