<template>
  <div class="modal fade show">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-card card">
          <div class="card-header">
            <h4 class="card-header-title">
              {{ $t('Create barcode') }}
            </h4>
            <button type="button" class="close" @click="close()">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-2">
                    {{ $t('Barcode') }}
                  </label>
                  <div class="add-input col-md-10">
                    <input
                      type="number"
                      class="form-control"
                      v-model="barcode"
                      :class="{ 'is-invalid': errors.barcode }"
                    />
                    <span>
                      <i class="fas fa-scanner-keyboard"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer card-footer-boxed">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto"></div>
              <div class="col-auto">
                <button class="btn btn-outline-danger" @click="close()">
                  <i class="far fa-times"></i>
                  {{ $t('Close') }}
                </button>
                <button @click="addBarcode()" class="btn btn-dark">
                  <i class="far fa-save"></i>
                  {{ $t('create') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      barcode: '',
      errors: {
        barcode: false
      }
    }
  },
  methods: {
    addBarcode() {
      var error = 0
      if (typeof this.barcode === 'undefined' || this.barcode == '') {
        error = 1
        this.errors.barcode = true
      }
      if (error == 0) {
        this.$parent.item.barcode.push(this.barcode)
        this.$parent.addBarcode = 0
      }
    },
    close() {
      this.$parent.addBarcode = 0
    }
  }
}
</script>
