var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('Employees Withdrawals')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Employee'),"plaseholder":_vm.$t('Choose employee'),"show":'name',"refrance":'id',"value":_vm.params.employee_id,"values":_vm.$database.employees},model:{value:(_vm.params.employee_id),callback:function ($$v) {_vm.$set(_vm.params, "employee_id", $$v)},expression:"params.employee_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('User Name'),"plaseholder":_vm.$t('Choose User Name'),"show":'name',"refrance":'id',"value":_vm.params.user_id,"values":_vm.$database.users},model:{value:(_vm.params.user_id),callback:function ($$v) {_vm.$set(_vm.params, "user_id", $$v)},expression:"params.user_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Cost center'),"plaseholder":_vm.$t('Choose a cost center'),"show":'name',"refrance":'id',"value":_vm.params.cost_center_id,"values":_vm.$database.costCenters},model:{value:(_vm.params.cost_center_id),callback:function ($$v) {_vm.$set(_vm.params, "cost_center_id", $$v)},expression:"params.cost_center_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('Employees Withdrawals'),"details":[
            { title: _vm.$t('Employee Name'), value: _vm.employeeName },
            { title: _vm.$t('User Name'), value: _vm.userName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('There are no data'),"emptyTableSubText":_vm.$t('Try adding some and Try again'),"cloumns":[
            {
              column: 'code',
              title: _vm.$t('Withdraw'),
              type: 'mainLink',
              sort: true
            },
            {
              column: 'employee_id',
              title: _vm.$t('functionary'),
              type: 'link',
              to: 'employee',
              sort: true,
              link: true
            },
            {
              column: 'user_id',
              title: _vm.$t('userName'),
              type: 'link',
              to: 'user',
              sort: true,
              link: false
            },
            {
              column: 'date',
              title: _vm.$t('Date'),
              type: 'text',
              sort: true
            },
            {
              column: 'description',
              title: _vm.$t('Thats about'),
              type: 'text',
              sort: true
            },
            {
              column: 'notes',
              title: _vm.$t('Note'),
              type: 'text',
              sort: true
            },
            {
              column: 'cost',
              title: _vm.$t('Cost'),
              type: 'text',
              sort: true
            }
          ],"footer":[
            { colspan: 6, value: _vm.$t('Total') },
            { colspan: 1, value: _vm.cost }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }