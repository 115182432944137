<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <PageHeader :title="$t('Reports')" :description="$t('Sales returns by invoice')" />

        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i> {{ $t('Reports options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <searchInput
                class="col-md-3"
                :title="$t('Customer')"
                :plaseholder="$t('Choose customer')"
                v-model="params.customer_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.customer_id"
                :values="$database.customers"
              />

              <dateFilter class="col-md-3" />

              <searchInput
                class="col-md-3"
                :title="$t('user')"
                :plaseholder="$t('Choose user')"
                v-model="params.user_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.user_id"
                :values="$database.users"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Cost center')"
                :plaseholder="$t('Choose a cost center')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.cost_center_id"
                :value="params.cost_center_id"
                :values="$database.costCenters"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Store')"
                :plaseholder="$t('Choose store')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.store_id"
                :value="params.store_id"
                :values="$database.stores"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Point of sale')"
                :plaseholder="$t('Choose point of sale')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.point_of_sale_id"
                :value="params.point_of_sale_id"
                :values="$database.pointOfSales"
              />
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('Session number') }}</label>
                  <input
                    type="text"
                    v-model="params.session_number"
                    class="form-control"
                    :placeholder="$t('Enter session number')"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('Order type') }}</label>
                  <select type="text" v-model="params.table_number" class="form-control">
                    <option value="">{{ $t('Choose Order type') }}</option>
                    <option value="0">{{ $t('Take away') }}</option>
                    <option value="1">{{ $t('Indoor') }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('Status type') }}</label>

                  <select v-model="params.status" type="text" class="form-control">
                    <option value="">{{ $t('Situation') }}</option>
                    <option value="">{{ $t('all') }}</option>
                    <option value="0">{{ $t('draft') }}</option>
                    <option value="1">{{ $t('sent') }}</option>
                    <option value="2">{{ $t('seen') }}</option>
                    <option value="3">{{ $t('driven') }}</option>
                    <option value="4">{{ $t('canceled') }}</option>
                  </select>
                </div>
              </div>
              <searchInput
                class="col-md-3"
                :title="$t('Shipping agent')"
                :plaseholder="$t('Choose shipping agent')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.delivery_id"
                :value="params.delivery_id"
                :values="$database.deliveries"
              />

              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader
            :title="$t('Sales returns by invoice')"
            :details="[
              { title: $t('Customer name'), value: customerName },
              { title: $t('Username'), value: userName },
              { title: $t('Store'), value: storeName },
              { title: $t('Point of sale'), value: pointOfSaleName },
              { title: $t('Session number'), value: sessionNumber },
              { title: $t('Order type'), value: tableNumber },
              { title: $t('Shipping agent'), value: deliveryName },
              { title: $t('Marketer'), value: marketerName }
            ]"
          />
          <reportTable
            :emptyTableText="$t('there are no data at the moment')"
            :emptyTableSubText="$t('Try adding some items and try again')"
            :cloumns="[
              {
                column: 'code',
                link: '/saleReturns',
                title: $t('Code'),
                type: 'reportMainLink',
                sort: true
              },
              {
                column: 'date',
                title: $t('Date'),
                type: 'text',
                sort: true,
                supData: true
              },
              {
                column: 'client_type',
                title: $t('Customer name'),
                type: 'clintLink',
                sort: false
              },
              {
                column: 'user',
                title: $t('User'),
                type: 'text',
                sort: true,
                supData: true
              },
              {
                column: 'subtotal',
                title: $t('Total'),
                type: 'text',
                sort: true
              },
              {
                column: 'edit',
                title: $t('Edit'),
                type: 'text',
                sort: true,
                supData: true
              },
              {
                column: 'delivery',
                title: $t('Shipping fees'),
                type: 'text',
                sort: true
              },
              {
                column: 'tax',
                title: $t('Taxes'),
                type: 'text',
                sort: true,
                supData: true
              },
              {
                column: 'discount',
                title: $t('Discount'),
                type: 'text',
                sort: true,
                supData: true
              },
              {
                column: 'total',
                title: $t('Total'),
                type: 'text',
                sort: true
              },
              {
                column: 'earning',
                title: 'الخسارة',
                type: 'text',
                sort: true,
                disable: !(this.$user.admin || this.$user.role.reports_earning_show),
                supData: true
              }
            ]"
            :footer="[
              { colspan: 1, value: $t('Total') },
              { colspan: 3, value: invoicesCount, supData: true },
              { colspan: 1, value: subtotal },
              {
                colspan: 1,
                value: edit,
                supData: true
              },
              { colspan: 1, value: delivery },
              {
                colspan: 1,
                value: tax,
                supData: true
              },
              {
                colspan: 1,
                value: discount,
                supData: true
              },
              { colspan: 1, value: total },
              {
                colspan: 1,
                value: earning,
                disable: !(this.$user.admin || this.$user.role.reports_earning_show),
                supData: true
              }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import reportHeader from '@/elements/reports/reportHeader.vue'
import reportTable from '@/elements/reports/reportTable.vue'
import PageHeader from '@/components/pageHeader'
import dateFilter from '@/elements/reports/dateFilter.vue'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'

export default {
  data() {
    return {
      path: '/reports/salesReturns/invoices',
      items: [],
      loading: 1,
      invoicesCount: 0,
      subtotal: 0,
      edit: 0,
      delivery: 0,
      tax: 0,
      discount: 0,
      total: 0,
      earning: 0,
      params: {
        customer_id: '',
        store_id: '',
        user_id: '',
        point_of_sale_id: '',
        delivery_id: '',
        session_number: '',
        table_number: '',
        dateFilter: 2,
        startDate: '',
        endDate: ''
      },

      excel: {
        fileName: 'saled return by invoice',
        reportData: '[]'
      },

      storeName: '',
      customerName: '',
      userName: '',
      pointOfSaleName: '',
      sessionNumber: '',
      tableNumber: '',
      deliveryName: '',

      searchStartDate: '',
      searchEndDate: ''
    }
  },
  methods: {
    getData() {
      this.$setParams(this.params)
      this.loading = 1
      this.subtotal = 0
      this.edit = 0
      this.delivery = 0
      this.tax = 0
      this.discount = 0
      this.total = 0
      this.earning = 0
      this.items = []
      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.items = response.data

        this.subtotal = this.items.reduce(
          (accumulator, current) => accumulator + current.subtotal,
          0
        )
        this.edit = this.items.reduce((accumulator, current) => accumulator + current.edit, 0)
        this.delivery = this.items.reduce(
          (accumulator, current) => accumulator + current.delivery,
          0
        )
        this.tax = this.items.reduce((accumulator, current) => accumulator + current.tax, 0)
        this.discount = this.items.reduce(
          (accumulator, current) => accumulator + current.discount,
          0
        )
        this.total = this.items.reduce((accumulator, current) => accumulator + current.total, 0)
        this.earning = this.items.reduce((accumulator, current) => accumulator + current.earning, 0)
        this.invoicesCount = this.items.length

        this.items.forEach((item) => {
          item.subtotal = this.$moneyFormat(item.subtotal)
          item.tax = this.$moneyFormat(item.tax)
          item.discount = this.$moneyFormat(item.discount)
          item.total = this.$moneyFormat(item.total)
          item.earning = this.$moneyFormat(item.earning)
          item.delivery = this.$moneyFormat(item.delivery)
          item.edit = this.$moneyFormat(item.edit)

          if (item.client_type == 0) {
            var customer = this.$database.customers.find((el) => el.id == item.customer_id)
            if (customer) item.customer = customer
          } else {
            var supplier = this.$database.suppliers.find((el) => el.id == item.supplier_id)
            if (supplier) item.supplier = supplier
          }

          var user = this.$database.users.find((el) => el.id == item.user_id)
          if (user) item.user = user.name

          item.date = this.$dateFormat(item.date)
        })

        this.subtotal = this.$moneyFormat(this.subtotal)
        this.tax = this.$moneyFormat(this.tax)
        this.discount = this.$moneyFormat(this.discount)
        this.total = this.$moneyFormat(this.total)
        this.earning = this.$moneyFormat(this.earning)
        this.edit = this.$moneyFormat(this.edit)
        this.delivery = this.$moneyFormat(this.delivery)

        this.loading = 0
      })

      this.searchStartDate = this.params.startDate
      this.searchEndDate = this.params.endDate

      var customer = this.$database.customers.find((el) => el.id == this.params.customer_id)
      if (customer) {
        this.customerName = customer.name
      } else {
        this.customerName = ''
      }

      var store = this.$database.stores.find((el) => el.id == this.params.store_id)
      if (store) {
        this.storeName = store.name
      } else {
        this.storeName = ''
      }

      var user = this.$database.users.find((el) => el.id == this.params.user_id)
      if (user) {
        this.userName = user.name
      } else {
        this.userName = ''
      }

      this.sessionNumber = this.params.session_number

      var delivery1 = this.$database.deliveries.find((el) => el.id == this.params.delivery_id)
      if (delivery1) {
        this.deliveryName = delivery1.name
      } else {
        this.deliveryName = ''
      }

      if (this.params.table_number == 1) {
        this.tableNumber = 'الصالة'
      } else if (this.params.table_number === '0') {
        this.tableNumber = 'تيك اواي'
      } else {
        this.tableNumber = ''
      }

      var pointOfSale = this.$database.pointOfSales.find(
        (el) => el.id == this.params.point_of_sale_id
      )
      if (pointOfSale) {
        this.pointOfSaleName = pointOfSale.name
      } else {
        this.pointOfSaleName = ''
      }
    },
    getItems() {
      this.$setParams(params)
      if (params.orderBy) {
        this.items.sort(this.$dynamicSort(params))
      }
    }
  },
  watch: {
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams()
    if (typeof this.params.startDate === 'undefined') {
      this.params.startDate = new Date()
        .toISOString('en-US', { timeZone: 'Africa/Cairo' })
        .slice(0, 10)
    }
    this.getData()
    this.$updateDatabase(['users'])
  },
  components: {
    reportHeader,
    reportTable,
    PageHeader,
    dateFilter,
    searchInput,
    actionButtons
  }
}
</script>
