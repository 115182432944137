var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Manufacturing'),"description":_vm.$t('From here you can control your manufacturing reports')}})],1),_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('Manufacturing operations'),"icon":'fa-hammer',"reports":[
      {
        name: _vm.$t('Manufacturing deposits by items'),
        link: './manufacturingDepositsByItem',
        role: true
      },

      {
        name: _vm.$t('Manufacturing withdrawals by items'),
        link: '/manufacturingWithdrawalsByItem',
        role: true
      },
      {
        name: _vm.$t('Manufacturing models used count'),
        link: '/manufacturingModelsUsedCount',
        role: true
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }