<template>
  <div class="index-table" :id="referenceId">
    <div class="d-flex" v-if="!innerTable">
      <SearchForm :searchText="searchText" class="w-100" @changeSearch="changeSearch" />
      <button v-if="showFilterButton" class="btn add-btn" @click="changeFilterActive">
        <i class="fa fa-filter"></i>
      </button>
      <TableHeader
        :addLink="addLink"
        :addActionRole="addActionRole"
        :showFilterButton="showFilterButton"
        @addAction="$emit('addAction')"
        @filter="changeFilterActive"
      />
    </div>
    <div v-if="filterActive" class="card">
      <div class="card-body">
        <div class="row">
          <slot name="filter" />

          <div class="col-md-2">
            <label class="invisible">
              {{ $t('Filter') }}
            </label>
            <button class="btn btn-dark w-100" @click="filter">
              <i class="fal fa-search"></i>
              {{ $t('Filter') }}
            </button>
          </div>
          <div class="col-md-1">
            <label class="invisible">
              {{ $t('Filter') }}
            </label>
            <button class="btn btn-warning w-100" @click="reset">
              <i class="fa fa-repeat text-white"></i>
              <!-- {{ $t('Reset') }} -->
            </button>
          </div>
        </div>
      </div>
    </div>

    <slot name="Delete-items" />

    <div :class="innerTable ? '' : 'card'">
      <TableContent>
        <ColumnsHeader :columnHeaders="columnHeaders" @sortItems="sortItems" />
        <slot />
        <Loader v-if="loading == 1" />
        <EmptyTable v-if="loading != 1 && items.length == 0 && error == 0" />
        <Error v-if="loading != 1 && items.length == 0 && error == 1" />
      </TableContent>
      <TableFooter
        v-if="loading != 1 && items.length != 0"
        :page="page"
        :totalPages="totalPages"
        :perPage="perPage"
        @changePage="changePage"
        @changePerPage="changePerPage"
      />
    </div>
    <sureDelete
      v-if="deleteId != null"
      :deleteText="deleteText"
      @closeDelete="closeDelete"
      @deleteItem="deleteItem"
    />
  </div>
</template>
<script>
import TableHeader from './header'
import ColumnsHeader from './table/ColumnsHeader'
import TableContent from './table/index'
import TableFooter from './tableFooter'
import SearchForm from './table/searchForm'
import Loader from './table/loader'
import SureDelete from './table/sureDelete'
import EmptyTable from './table/emptyTable'
import Error from './table/error'

export default {
  data: () => ({
    filterActive: false
  }),
  components: {
    TableHeader,
    ColumnsHeader,
    TableContent,
    TableFooter,
    Loader,
    SearchForm,
    SureDelete,
    EmptyTable,
    Error
  },

  methods: {
    changeFilterActive() {
      this.filterActive = !this.filterActive
    },
    closeDelete() {
      this.$emit('closeDelete')
    },
    filter() {
      this.$emit('filter')
    },
    reset() {
      this.$emit('reset')
    },
    deleteItem() {
      this.$emit('deleteItem')
    },
    sortItems(name) {
      this.$emit('sortItems', name)
    },
    changePage(page) {
      this.$emit('changePage', page)
    },
    changePerPage(item) {
      this.$emit('changePerPage', item)
    },
    changeSearch(search) {
      this.$emit('changeSearch', search)
    },
    doApproveMoveStore(id) {
      axios
        .post(this.$linkGenerator('/storesOrders/approve'), {
          order_id: this.approve_move_store_id,
          from_id: id
        })
        .then((response) => {
          this.approve_move_store_id = null
          return response
        })
    },
    closeApproveMoveToStore() {
      approve_move_store_id = null
    }
  },
  props: [
    'innerTable',
    'referenceId',
    'addLink',
    'loading',
    'error',
    'deleteId',
    'items',
    'columnHeaders',
    'deleteText',
    'searchText',
    'page',
    'totalPages',
    'perPage',
    'addActionRole',
    'showFilterButton'
  ]
}
</script>
