<template>
  <div class="modal fade show">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-card card">
          <!-- Card header -->
          <div class="card-header">
            <h4 class="card-header-title">{{ $t('Add note') }}</h4>
            <button type="button" class="close" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <!-- Card body -->
          <div class="card-body">
            <div class="form-group">
              <label>{{ $t('Product name') }}</label>
              <input
                type="text"
                class="form-control"
                :value="item.invoiceItems[selectedProduct].product_name"
                disabled
              />
            </div>
            <div class="form-group">
              <label>{{ $t('Note') }}</label>
              <textarea class="form-control" v-model="comment"></textarea>
            </div>
          </div>

          <!-- Card footer -->
          <div class="card-footer card-footer-boxed">
            <div class="d-flex justify-content-end">
              <button class="btn btn-outline-danger m-1" @click="$emit('close')">
                <i class="far fa-times"></i>
                {{ $t('Close') }}
              </button>
              <button class="btn btn-dark m-1" @click="addComment()">
                <i class="far fa-save"></i>
                {{ $t('Save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { createToastInterface } from 'vue-toastification'
import { $t } from '@/helpers/translate'

// Use toast
const toast = createToastInterface()

// Comment logic
const comment = ref('')
const addComment = () => {
  // Show popup
  toast.success($t('Comment added successfully'), { position: 'top-center' })
  // Update item
  emits('updateComment', props.selectedProduct, comment.value)
  // Close popup
  emits('close')
}

// Get comment on open
comment.value = props.item.invoiceItems[props.selectedProduct].comment ?? ''

const props = defineProps(['item', 'selectedProduct'])
const emits = defineEmits(['close', 'updateComment'])
</script>
