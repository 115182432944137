<template>
  <div class="form-group row" :class="col != null ? col : 'col-md-4'">
    <!-- Label -->
    <label :class="col != null ? 'col-md-2' : 'col-md-4'">{{ title }}</label>
    <!-- Input -->
    <div :class="col != null ? 'col-md-95' : 'col-md-7'">
      <select
        class="form-control"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :class="{ 'is-invalid': hasErorr }"
      >
        <option v-for="option in options" :value="option.id">{{ option.name }}</option>
      </select>
      <div class="invalid-feedback">
        {{ error }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'title',
    'group',
    'values',
    'value',
    'col',
    'error',
    'hasErorr',
    'hasAdd',
    'open',
    'multiple',
    'disabled',
    'except',
    'options'
  ],
  watch: {
    value: function () {
      if (this.value == 'add') {
        this.$parent.$parent.model = 1
        //this.value = 0;
        this.$parent.model = this.open
      }
    }
  }
}
</script>
