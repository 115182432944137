<template>
  <div class="print-model">
    <div id="printMe" ref="printMe">
      <SafesTransfer :item="item" />
    </div>
  </div>
</template>
<script setup>
import SafesTransfer from './components/safesTransfers.vue'
const printItem = () => {
  // Prepare content
  const text = document.getElementById('printMe').innerHTML
  document.getElementById('printMe').remove()
  const myFrame = document.getElementById('inviceIframe')
  myFrame.contentDocument.body.innerHTML = text
  setTimeout(function () {
    myFrame.focus()
    myFrame.contentWindow.print()
  }, 100)

  emits('onFinish')
}

// Print item
setTimeout(() => printItem(), 40)

const props = defineProps(['item'])
const emits = defineEmits(['onFinish'])
</script>
