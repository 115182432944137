// import Vue from 'vue'
// const { $database } = Vue.prototype
const autoSelection = (collection) => {
  if (collection.length == 1) {
    return collection[0].id
  }
  return null
}

export default autoSelection
