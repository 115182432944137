var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('account Operations'),"description":_vm.$t('From here you can control your account Operations reports')}})],1),(
      (_vm.$user.role.available_reports.find(
        (el) => el.id == 34 || el.id == 35 || el.id == 36 || el.id == 37
      ) ||
        _vm.$user.admin) &&
      _vm.$site.accounts_allow
    )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('accounts'),"icon":'fa-coins',"reports":[
      {
        name: _vm.$t('totalAccounts'),
        link: './totalAccounts',
        role: _vm.$user.role.available_reports.find((el) => el.id == 37) || _vm.$user.admin
      },
      {
        name: _vm.$t('accountDetails'),
        link: '/accountDetails',
        role: _vm.$user.role.available_reports.find((el) => el.id == 37) || _vm.$user.admin
      },
      {
        name: _vm.$t('accountBalances'),
        link: '/accountBalances',
        role: _vm.$user.role.available_reports.find((el) => el.id == 37) || _vm.$user.admin
      },
      {
        name: _vm.$t('accountsGuide'),
        link: '/accountsGuide',
        role: _vm.$user.role.available_reports.find((el) => el.id == 37) || _vm.$user.admin
      },
      {
        name: _vm.$t('movementAccounts'),
        link: '/movementAccounts',
        role: _vm.$user.role.available_reports.find((el) => el.id == 37) || _vm.$user.admin
      }
    ]}}):_vm._e(),(
      (_vm.$user.role.available_reports.find((el) => el.id == 31 || el.id == 32) || _vm.$user.admin) &&
      (_vm.$site.accountsDeposits_allow || _vm.$site.accountsWithdrawals_allow)
    )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('accountOperations'),"icon":'fa-hand-holding-usd',"reports":[
      {
        name: _vm.$t('accountTransactionPaymentsRecord'),
        link: './accountTransactionPaymentsRecord',
        role: _vm.$user.role.available_reports.find((el) => el.id == 31) || _vm.$user.admin
      },
      {
        name: _vm.$t('recordWithdrawalsAccountsOperations'),
        link: '/recordWithdrawalsAccountsOperations',
        role: _vm.$user.role.available_reports.find((el) => el.id == 32) || _vm.$user.admin
      }
    ]}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }