<template>
  <Layout
    :title="editId ? $t('Update account') : $t('Create account')"
    :loading="loading"
    @close="() => $emit('close')"
    @action="onSubmit"
  >
    <div class="row">
      <div class="col-md-6">
        <Select
          type="text"
          :title="$t('Account type')"
          v-model:value="item.is_main"
          :disabled="editId"
          :errors="getInputError('is_main')"
          :values="[
            { value: 1, name: $t('Main account') },
            { value: 0, name: $t('Sup account') }
          ]"
        />
      </div>
      <div class="col-md-6">
        <Input
          type="number"
          :title="$t('Code')"
          v-model:value="item.code"
          :errors="getInputError('code')"
        />
      </div>
      <div class="col-md-6">
        <Input
          type="text"
          :title="$t('Name')"
          v-model:value="item.name"
          :errors="getInputError('name')"
        />
      </div>
      <div class="col-md-6">
        <SearchInput
          type="text"
          :title="$t('Main account')"
          :options="$database.accounts.filter((el) => el.is_main == 1)"
          v-model:value="item.account_id"
          :errors="getInputError('account_id')"
        />
      </div>
      <template v-if="item.is_main == 0">
        <div class="col-md-6">
          <Select
            :title="$t('Balance type')"
            v-model:value="item.balance_type"
            :disabled="$route.name == 'customersEdit'"
            :values="[
              { value: 0, name: $t('Cumulative') },
              { value: 1, name: $t('Creditor and Debitor') }
            ]"
            :errors="getInputError('balance_type')"
          />
        </div>

        <div class="col-md-6">
          <Input
            type="number"
            :title="$t('Initial balance')"
            v-model:value="item.opening_balance"
            :disabled="$route.name == 'customersEdit' || item.balance_type == 1"
            :errors="getInputError('opening_balance')"
            :after="$option.currency"
          />
        </div>

        <div class="col-md-6">
          <Input
            type="number"
            v-if="item.balance_type == 1"
            :title="$t('Debit')"
            v-model:value="item.opening_debit"
            :disabled="$route.name == 'customersEdit'"
            :errors="getInputError('opening_debit')"
            :after="$option.currency"
          />
        </div>

        <div class="col-md-6">
          <Input
            type="number"
            v-if="item.balance_type == 1"
            :title="$t('Creditor')"
            v-model="item.opening_credit"
            :disabled="$route.name == 'customersEdit'"
            :errors="getInputError('opening_credit')"
            :after="$option.currency"
          />
        </div>
      </template>
    </div>
  </Layout>
</template>
<script setup>
import { watch } from 'vue'
// layout component
import Layout from '@/layouts/model'
// form components
import Input from '@/components/form/inputs/input'
import Select from '@/components/form/inputs/select.vue'
import SearchInput from '@/components/form/inputs/searchInput'
// helpers functions
import formHock from '@/helpers/formHock'

// form validations
import { required, minLength, maxLength, helpers } from '@vuelidate/validators'

const formSetup = {
  name: '',
  code: props.nextCode,
  is_main: 1,
  account_id: props.accountId,
  balance_type: 0,
  balance: 0,
  opening_debit: null,
  opening_credit: null
}

// Validations
const rules = {
  name: {
    required: helpers.withMessage('Name is required', required),
    minLength: helpers.withMessage('Name must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('Name must be smaller than or equal 121', maxLength(121))
  },
  code: {
    required: helpers.withMessage('Code is required', required),
    minLength: helpers.withMessage('Code must be bigger than or equal 1', minLength(1)),
    maxLength: helpers.withMessage('Code must be smaller than or equal 121', maxLength(125))
  }
}

// Import from form hock
const { item, saveItem, loading, getInputError } = formHock({
  path: 'accounts',
  localData: true,
  formSetup: formSetup,
  rules: rules,
  route: props.editId ? 'edit' : 'create',
  id: props.editId
})

const onSubmit = async () => {
  const response = await saveItem()
  if (response.status) emit('close')
}

// Watch balance
watch(
  () => item,
  () => {
    if (item.value.balance_type == 1) {
      item.value.opening_balance =
        (item.value.opening_debit ?? 0) - (item.value.opening_credit ?? 0)
    }
  },
  { deep: true }
)

const emit = defineEmits('close')
const props = defineProps(['accountId', 'nextCode', 'editId'])
</script>
