import Vue from 'vue'

const initItems = (items) => {
  items.forEach((item) => {
    if (item.date) {
      item.date = Vue.prototype.$dateFormat(item.date, '')
    } else {
      item.date = '--'
    }
    if (item.created_date) {
      item.created_date = Vue.prototype.$dateFormat(item.created_date, '')
    } else {
      item.created_date = '--'
    }
    if (item.start_date) {
      item.start_date = Vue.prototype.$dateFormat(item.start_date, '')
    } else {
      item.start_date = '--'
    }
    if (item.end_date) {
      item.end_date = Vue.prototype.$dateFormat(item.end_date, '')
    } else {
      item.end_date = '--'
    }
    if (item.out_date) {
      item.out_date = Vue.prototype.$dateFormat(item.out_date, '')
    } else {
      item.out_date = '--'
    }
    if (item.total || item.total == 0) {
      item.total = Vue.prototype.$moneyFormat(item.total)
    }
    if (item.price || item.price == 0) {
      item.price = Vue.prototype.$moneyFormat(item.price)
    }
    if (item.cost || item.cost == 0) {
      item.cost = Vue.prototype.$moneyFormat(item.cost)
    }
    if (item.paid || item.paid == 0) {
      item.paid = Vue.prototype.$moneyFormat(item.paid)
    }
    if (item.rest || item.rest == 0) {
      item.rest = Vue.prototype.$moneyFormat(item.rest)
    }
    if (item.balance || item.balance == 0) {
      item.balance = Vue.prototype.$moneyFormat(item.balance)
    }
    if (item.salary || item.salary == 0) {
      item.salary = Vue.prototype.$moneyFormat(item.salary)
    }
    if (item.cod || item.cod == 0) {
      item.cod = Vue.prototype.$moneyFormat(item.cod)
    }
    if (item.customer_id) {
      let customer = Vue.prototype.$database.customers.find((el) => el.id == item.customer_id)
      item.customer = customer
    }
    if (item.supplier_id) {
      let supplier = Vue.prototype.$database.suppliers.find((el) => el.id == item.supplier_id)
      item.supplier = supplier
    }
    if (item.partner_id) {
      let partner = Vue.prototype.$database.partners.find((el) => el.id == item.partner_id)
      item.partner = partner
    }
    if (item.account_id) {
      let account = Vue.prototype.$database.accounts.find((el) => el.id == item.account_id)
      item.account = account
    }
    if (item.eployee_id) {
      let partner = Vue.prototype.$database.partners.find((el) => el.id == item.partner_id)
      item.partner = partner
    }
    if (item.employee_id) {
      let employee = Vue.prototype.$database.employees.find((el) => el.id == item.employee_id)
      item.employee = employee
    }
    if (item.project_id) {
      let project = Vue.prototype.$database.projects.find((el) => el.id == item.project_id)
      item.project = project
    }
    if (item.store_id) {
      let store = Vue.prototype.$database.stores.find((el) => el.id == item.store_id)
      item.store = store
    }
    if (item.safe_id) {
      let safe = Vue.prototype.$database.safes.find((el) => el.id == item.safe_id)
      if (!safe) safe = Vue.prototype.$database.bankAccounts.find((el) => el.id == item.safe_id)
      item.safe = safe
    }

    if (item.from_store_id) {
      let fromStore = Vue.prototype.$database.stores.find((el) => el.id == item.from_store_id)
      item.fromStore = fromStore
    }
    if (item.to_store_id) {
      let toStore = Vue.prototype.$database.stores.find((el) => el.id == item.to_store_id)
      item.toStore = toStore
    }

    if (item.from_safe_id) {
      let fromSafe = Vue.prototype.$database.safes.find((el) => el.id == item.from_safe_id)
      if (!fromSafe)
        fromSafe = Vue.prototype.$database.bankAccounts.find((el) => el.id == item.from_safe_id)
      item.fromSafe = fromSafe
    }
    if (item.to_safe_id) {
      let toSafe = Vue.prototype.$database.safes.find((el) => el.id == item.to_safe_id)
      if (!toSafe)
        toSafe = Vue.prototype.$database.bankAccounts.find((el) => el.id == item.to_safe_id)

      item.toSafe = toSafe
    }
    if (item.section_id) {
      let section = Vue.prototype.$database.sections.find((el) => el.id == item.section_id)
      item.section = section
    }
    if (item.expense_section_id) {
      let expenseSection = Vue.prototype.$database.expenseSections.find(
        (el) => el.id == item.expense_section_id
      )
      item.expenseSection = expenseSection
    }
    if (item.income_section_id) {
      let incomeSection = Vue.prototype.$database.incomeSections.find(
        (el) => el.id == item.income_section_id
      )
      item.incomeSection = incomeSection
    }

    if (item.from_project_id) {
      let fromProject = Vue.prototype.$database.projects.find((el) => el.id == item.from_project_id)
      item.fromProject = fromProject
    }
    if (item.to_project_id) {
      let toProject = Vue.prototype.$database.projects.find((el) => el.id == item.to_project_id)
      item.toProject = toProject
    }
    if (item.delivery_id) {
      let delivery = Vue.prototype.$database.deliveries.find((el) => el.id == item.delivery_id)
      item.delivery = delivery
    }
    if (item.city_id) {
      let city = Vue.prototype.$database.cities.find((el) => el.id == item.city_id)
      item.city = city
    }

    if (item.from_customer_id) {
      let fromCustomer = Vue.prototype.$database.customers.find(
        (el) => el.id == item.from_customer_id
      )
      item.fromCustomer = fromCustomer
    }

    if (item.from_supplier_id) {
      let fromSupplier = Vue.prototype.$database.suppliers.find(
        (el) => el.id == item.from_supplier_id
      )
      item.fromSupplier = fromSupplier
    }

    if (item.from_partner_id) {
      let fromPartner = Vue.prototype.$database.partners.find((el) => el.id == item.from_partner_id)
      item.fromPartner = fromPartner
    }

    if (item.from_account_id) {
      let fromAccount = Vue.prototype.$database.accounts.find((el) => el.id == item.from_account_id)
      item.fromAccount = fromAccount
    }

    if (item.to_customer_id) {
      let toCustomer = Vue.prototype.$database.customers.find((el) => el.id == item.to_customer_id)
      item.toCustomer = toCustomer
    }

    if (item.to_supplier_id) {
      let toSupplier = Vue.prototype.$database.suppliers.find((el) => el.id == item.to_supplier_id)
      item.toSupplier = toSupplier
    }

    if (item.to_partner_id) {
      let toPartner = Vue.prototype.$database.partners.find((el) => el.id == item.to_partner_id)
      item.toPartner = toPartner
    }

    if (item.to_account_id) {
      let toAccount = Vue.prototype.$database.accounts.find((el) => el.id == item.to_account_id)
      item.toAccount = toAccount
    }
    if (item.parent_id) {
      let section = Vue.prototype.$database.sections.find((el) => el.id == item.parent_id)
      item.section = section
    }
    if (item.actual_amount) {
      item.actual_amount = Vue.prototype.$moneyFormat(item.actual_amount)
    }
    if (item.entered_amount) {
      item.entered_amount = Vue.prototype.$moneyFormat(item.entered_amount)
    }
    if (item.difference) {
      item.difference = Vue.prototype.$moneyFormat(item.difference)
    }
    if (item.amount) {
      item.amount = Vue.prototype.$moneyFormat(item.amount)
    }
  })

  return items
}

export default initItems
