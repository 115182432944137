<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <PageHeader :title="$t('Reports')" :description="$t('Manufacturing models used count')" />

        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{ $t('Reports options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <searchInput
                class="col-md-3"
                :title="$t('Customer')"
                :plaseholder="$t('Choose customer')"
                v-model="params.customer_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.customer_id"
                :values="$database.customers"
              />

              <dateFilter class="col-md-3" />

              <searchInput
                class="col-md-3"
                :title="$t('User')"
                :plaseholder="$t('Choose user')"
                v-model="params.user_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.user_id"
                :values="$database.users"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Point of sale')"
                :plaseholder="$t('Choose point of sale')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.point_of_sale_id"
                :value="params.point_of_sale_id"
                :values="$database.pointOfSales"
              />

              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('Session number') }}</label>
                  <input
                    type="text"
                    v-model="params.session_number"
                    class="form-control"
                    :placeholder="$t('Enter session number')"
                  />
                </div>
              </div>

              <searchInput
                class="col-md-3"
                :title="$t('Cost center')"
                :plaseholder="$t('Choose a cost center')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.cost_center_id"
                :value="params.cost_center_id"
                :values="$database.costCenters"
              />

              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader
            :title="$t('Manufacturing models used count')"
            :details="[
              { title: 'اسم العميل', value: customerName },
              { title: 'اسم المستخدم', value: userName },
              { title: 'نقطة البيع', value: pointOfSaleName },
              { title: 'رقم الجلسة', value: sessionNumber }
            ]"
          />
          <reportTable
            :emptyTableText="$t('There are no data at the moment')"
            :emptyTableSubText="$t('Try adding some items and try again')"
            :cloumns="[
              {
                column: 'name',
                title: $t('Model name'),
                type: 'text',
                sort: true
              },
              {
                column: 'quantity',
                title: $t('Quantity'),
                type: 'quantity',
                sort: true
              }
            ]"
            :footer="[
              { colspan: 1, value: 'المجموع' },
              { colspan: 1, value: quantity }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import reportTable from '@/elements/reports/reportTable.vue'
import PageHeader from '@/components/pageHeader'
import dateFilter from '@/elements/reports/dateFilter.vue'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'
import reportHeader from '@/elements/reports/reportHeader.vue'

export default {
  data() {
    return {
      path: '/reports/manufacturing/models',
      items: [],

      loading: 0,

      quantity: 0,

      params: {
        customer_id: '',
        user_id: '',
        point_of_sale_id: '',
        session_number: '',
        startDate: '',
        endDate: ''
      },

      excel: {
        fileName: 'Manufacturing models used count',
        reportData: '[]'
      },

      customerName: '',
      userName: '',
      pointOfSaleName: '',
      sessionNumber: '',

      searchStartDate: '',
      searchEndDate: '',

      selectedSections: []
    }
  },
  methods: {
    getData() {
      this.$setParams(this.params)
      this.loading = 1
      this.items = []
      this.quantity = 0
      this.total = 0
      this.earning = 0
      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.items = response.data

        this.quantity = this.items.reduce(
          (accumulator, current) => accumulator + current.quantity,
          0
        )

        this.items.map((el) => (el.quantity = el.quantity.toFixed(2)))

        this.quantity = this.quantity.toFixed(2)
        this.loading = 0
      })

      this.searchStartDate = this.params.startDate
      this.searchEndDate = this.params.endDate

      var customer = this.$database.customers.find((el) => el.id == this.params.customer_id)
      if (customer) {
        this.customerName = customer.name
      } else {
        this.customerName = ''
      }

      var user = this.$database.users.find((el) => el.id == this.params.user_id)
      if (user) {
        this.userName = user.name
      } else {
        this.userName = ''
      }

      var pointOfSale = this.$database.pointOfSales.find(
        (el) => el.id == this.params.point_of_sale_id
      )
      if (pointOfSale) {
        this.pointOfSaleName = pointOfSale.name
      } else {
        this.pointOfSaleName = ''
      }
    },
    getItems() {
      this.$setParams(this.params)
      if (this.params.orderBy) this.items.sort(this.$dynamicSort(this.params))
    }
  },
  mounted() {
    this.params = this.$getParams()

    if (typeof this.params.startDate === 'undefined') {
      this.params.startDate = new Date()
        .toISOString('en-US', { timeZone: 'Africa/Cairo' })
        .slice(0, 10)
    }
    this.getData()
    this.$updateDatabase(['users'])
  },
  watch: {
    selectedSections: function (val) {
      this.params.section_id = val.map((el) => el.id)
    },
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
    }
  },
  components: {
    reportTable,
    PageHeader,
    dateFilter,
    searchInput,
    actionButtons,
    reportHeader
  }
}
</script>
