<template>
  <nav aria-label="Page navigation  pt-3" v-if="totalPages > 1">
    <ul class="pagination">
      <li class="page-item" :class="page == 1 ? 'disabled' : ''" @click="previousPage()">
        <a class="page-link">{{ $t('Prev') }}</a>
      </li>
      <li
        class="page-item"
        :class="props.page == page ? 'active' : ''"
        v-for="page in pages"
        :key="page"
      >
        <a class="page-link" @click="getPage(page)">{{ page }}</a>
      </li>

      <li class="page-item" :class="page == totalPages ? 'disabled' : ''" @click="nextPage()">
        <a class="page-link">{{ $t('Next') }}</a>
      </li>
    </ul>
    <!-- <div class="item-counts-label">
        <label>{{ $t('Count') }} : </label>
        <input type="number" v-model="count" @change="changePerPage()" />
      </div> -->
  </nav>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps(['page', 'totalPages', 'perPage'])
const emits = defineEmits(['changePerPage', 'changePage'])

// Variables
const pages = ref([])

// Functions
const getPage = (page) => {
  if (page != '...' && page != props.page) {
    emits('changePage', page)
  }
}

// const changePerPage = (item) => {
//     emits('changePerPage', item)
// }

const previousPage = () => {
  if (props.page > 1) {
    emits('changePage', props.page - 1)
  }
}
const nextPage = () => {
  if (props.page < props.totalPages) {
    emits('changePage', props.page + 1)
  }
}

const initPages = () => {
  pages.value = []
  var current = props.page
  var total = props.totalPages
  if (total > 9) {
    if (current > 4) {
      pages.value.push(1, 2, '...')
    }

    if (current <= 4) {
      pages.value.push(1, 2, 3, 4, 5)
    } else if (current > 4 && current < total - 3) {
      pages.value.push(current - 2, current - 1, current, current + 1, current + 2)
    } else if (current >= total - 3) {
      pages.value.push(total - 4, total - 3, total - 2, total - 1, total)
    }

    if (current < total - 3) {
      pages.value.push('... ', total - 1, total)
    }
  } else {
    pages.value = total
  }
}

initPages()

watch(
  () => props.page,
  () => {
    initPages()
  },
  { immediate: true }
)

watch(
  () => props.totalPages,
  () => {
    initPages()
  },
  { immediate: true }
)
</script>
