<template>
  <div class="card" v-if="file">
    <div class="card-header">
      <h4 class="card-header-title">
        {{ $t('Operation File') }}
      </h4>
    </div>
    <div class="card-body">
      <img
        :src="$linkGenerator('/companiesUploads/' + subdomain + '/files/' + file, true)"
        style="width: 100%"
      />
      <a :href="$linkGenerator('/companiesUploads/' + subdomain + '/files/' + file, true)" download
        ><i class="fas fa-file"></i> {{ file }}
      </a>
    </div>
  </div>
</template>
<script setup>
const subdomain = localStorage.getItem('subdomain')

defineProps(['file'])
</script>
