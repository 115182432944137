<template>
  <div class="col-12">
    <div class="d-xl-block d-none">
      <div class="row invoice-table-header">
        <div class="col-xl-4">
          {{ $t('ProductItem') }}
        </div>
        <div class="col-xl-4">
          {{ $t('Unit') }}
        </div>
        <div class="col-xl-4">
          {{ $t('Quantity') }}
        </div>
      </div>
    </div>
    <quotationItem
      v-for="(rowItem, index) in $parent.item.storesSettlementsItems"
      :key="index"
      :index="index"
      :item="rowItem"
    />
    <!-- <button class="btn btn-success w-100 mb-4" @click="$parent.addQuotationItem()">
      <i class="fas fa-plus"></i> {{ $t('Addition') }}
    </button> -->
  </div>
</template>
<script>
import quotationItem from './quotationItem.vue'
export default {
  data() {
    return {
      items: [],
      filteredItems: []
    }
  },
  methods: {
    updateQuantities() {
      this.filteredItems = []
      this.items.forEach((item) => {
        if (item.track_quantity == 1) this.filteredItems.push(item)
      })
    }
  },
  mounted() {
    this.items = this.$database.products.concat(this.$database.materials)
    this.updateQuantities()
  },
  components: {
    quotationItem
  }
}
</script>
