<template>
  <div>
    <div class="row">
      <label class="col-sm-3">{{ $t('Phone number') }}</label>
      <div class="col-md-7">
        <div class="input-group" ref="elementRef">
          <input
            type="search"
            autocomplete="off"
            v-model="customerMobile"
            class="form-control"
            @keydown="handelKey"
            @keyup="searchCustomer"
            @focus="searchCustomer"
            :disabled="item.customer_id != 0"
          />

          <ul
            class="list-unstyled search-selects"
            v-if="searchCustomers.length > 0 && item.customer_id == 0 && showCustomersList"
          >
            <li
              v-for="customer in searchCustomers"
              :key="customer.id"
              @click="addCustomerFromSearch(customer.id)"
              :class="{ focused: customer.focused }"
            >
              {{ customer.mobile }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Vue from 'vue'
import { ref, computed, watch } from 'vue'
import { useClickOutside } from '@/helpers/useClickOutside'

// Click outside logic
const { elementRef, isOutside } = useClickOutside()
watch(isOutside, (value, oldValue) => {
  if (value && oldValue != value) removeCustomerList()
})

const searchCustomers = ref([])
const showCustomersList = ref(false)

// Computed inputs
const customerMobile = computed({
  get: () => props.item.customer.mobile,
  set: (value) =>
    emits('updateItem', { ...props.item, customer: { ...props.item.customer, mobile: value } })
})

const searchCustomer = () => {
  // Show dropdown
  showCustomersList.value = true
  // Search in database
  const search = props.item.customer.mobile
  const searchRGX = new RegExp(search, 'i')
  const customers = Vue.prototype.$database.customers
    .sort(Vue.prototype.$dynamicSort({ orderBy: 'mobile', orderType: 'desc' }))
    .filter((el) => searchRGX.test(el.mobile) && el.mobile != null && el.mobile != '')
    .slice(0, 10)
  if (typeof customers != 'undefined') {
    searchCustomers.value = customers
  } else {
    searchCustomers.value = []
  }
}

const handelKey = (e) => {
  if (
    (e.key == 'ArrowDown' && searchCustomers.value.length != 0) ||
    (e.key == 'ArrowUp' && searchCustomers.value.length != 0)
  ) {
    let customer = searchCustomers.value.find((el) => el.focused == true)
    let selected = searchCustomers.value.indexOf(customer)
    let length = searchCustomers.value.length
    if (selected > -1) {
      Vue.prototype.$set(searchCustomers.value[selected], 'focused', false)
    }
    if (e.key == 'ArrowDown') {
      if (selected < length - 1) {
        Vue.prototype.$set(searchCustomers.value[selected + 1], 'focused', true)
      } else {
        Vue.prototype.$set(searchCustomers.value[0], 'focused', true)
      }
    } else if (e.key == 'ArrowUp') {
      if (selected > 0) {
        Vue.prototype.$set(searchCustomers.value[selected - 1], 'focused', true)
      } else {
        Vue.prototype.$set(searchCustomers.value[length - 1], 'focused', true)
      }
    }
  } else if (e.key == 'Enter') {
    let eCustomer = searchCustomers.value.find((el) => el.focused == true)
    let eSelected = searchCustomers.value.indexOf(eCustomer)
    if (eSelected != -1) {
      addCustomerFromSearch(eCustomer.id)
      removeCustomerList()
    }
  }
}

const addCustomerFromSearch = (id) => {
  let customer = Vue.prototype.$database.customers.find((el) => el.id == id)
  emits('updateItem', { ...props.item, customer_id: id, customer: customer })
}
const removeCustomerList = () => (showCustomersList.value = false)

const props = defineProps(['options', 'item'])
const emits = defineEmits(['updateItem'])
</script>
