var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('addHeader',{attrs:{"title":_vm.$t('Settings'),"description":_vm.$t('From here you can control all settings')}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('reportsCard',{attrs:{"cardTitle":_vm.$t('User settings'),"icon":'fal fa-user-cog',"reports":[
              {
                name: _vm.$t('profile'),
                link: './profile',
                role: _vm.$checkRole('profile_edit')
              },
              {
                name: _vm.$t('Companies'),
                link: './companies',
                role: _vm.$checkRole('companies_show')
              },
              {
                name: _vm.$t('User Roles'),
                link: './roles',
                role: _vm.$checkRole('roles_show')
              },
              {
                name: _vm.$t('User Accounts'),
                link: './users',
                role: _vm.$checkRole('users_show')
              }
            ]}}),_c('reportsCard',{attrs:{"cardTitle":_vm.$t('Operations settings'),"icon":'fal fa-cogs',"reports":[
              {
                name: _vm.$t('Numbering Groups'),
                link: './invoiceGroups',
                role:
                  _vm.$user.admin ||
                  _vm.$checkRole('quotations_show') ||
                  _vm.$checkRole('invoices_show') ||
                  _vm.$checkRole('bills_show') ||
                  _vm.$checkRole('orders_show') ||
                  _vm.$checkRole('purchase_orders_show')
              },
              {
                name: _vm.$t('Invoice Status'),
                link: './invoiceStatus',
                role:
                  _vm.$user.admin ||
                  _vm.$checkRole('quotations_show') ||
                  _vm.$checkRole('invoices_show') ||
                  _vm.$checkRole('bills_show') ||
                  _vm.$checkRole('orders_show') ||
                  _vm.$checkRole('purchase_orders_show')
              },
              {
                name: _vm.$t('Assets Sections'),
                link: './capitalsSections',
                role:
                  _vm.$user.admin ||
                  _vm.$checkRole('quotations_show') ||
                  _vm.$checkRole('invoices_show') ||
                  _vm.$checkRole('bills_show') ||
                  _vm.$checkRole('orders_show') ||
                  _vm.$checkRole('purchase_orders_show')
              },
              {
                name: _vm.$t('Signatures'),
                link: './Signatures',
                role: _vm.$user.admin || _vm.$user.signatures_show
              }
            ]}}),_c('reportsCard',{attrs:{"cardTitle":_vm.$t('product settings'),"icon":'fal fa-boxes-alt',"reports":[
              {
                name: _vm.$t('Units Product'),
                link: './units',
                role: _vm.$checkRole('products_show')
              },
              {
                name: _vm.$t('Product Details'),
                link: './details',
                role: _vm.$checkRole('products_show')
              },
              {
                name: _vm.$t('Products Options'),
                link: './productOptions',
                role: _vm.$checkRole('products_show')
              },
              {
                name: _vm.$t('Product Categories'),
                link: './sections',
                role: _vm.$checkRole('products_show')
              },
              {
                name: _vm.$t('Units Coefficient'),
                link: '/unitsCoefficients',
                role: _vm.$checkRole('products_show')
              }
            ]}})],1),_c('div',{staticClass:"col-md-6"},[_c('reportsCard',{attrs:{"cardTitle":_vm.$t('Payment settings'),"icon":'fal fa-file-invoice-dollar',"reports":[
              {
                name: _vm.$t('payment Methods'),
                link: './paymentMethods',
                role:
                  _vm.$user.admin ||
                  _vm.$checkRole('sales_payments_show') ||
                  _vm.$checkRole('purchase_payments_show') ||
                  _vm.$checkRole('expenses_show')
              },
              {
                name: _vm.$t('payment Fees'),
                link: './paymentFees',
                role:
                  _vm.$user.admin ||
                  _vm.$checkRole('sales_payments_show') ||
                  _vm.$checkRole('purchase_payments_show') ||
                  _vm.$checkRole('expenses_show')
              },
              {
                name: _vm.$t('Tax Rates'),
                link: './taxes',
                role:
                  _vm.$user.admin ||
                  _vm.$checkRole('quotations_show') ||
                  _vm.$checkRole('invoices_show') ||
                  _vm.$checkRole('bills_show') ||
                  _vm.$checkRole('orders_show') ||
                  _vm.$checkRole('purchase_orders_show') ||
                  _vm.$checkRole('products_show')
              },
              {
                name: _vm.$t('discount rates'),
                link: './discounts',
                role:
                  _vm.$user.admin ||
                  _vm.$checkRole('quotations_show') ||
                  _vm.$checkRole('invoices_show') ||
                  _vm.$checkRole('bills_show') ||
                  _vm.$checkRole('orders_show') ||
                  _vm.$checkRole('purchase_orders_show') ||
                  _vm.$checkRole('products_show')
              },
              {
                name: _vm.$t('Shipping companies'),
                link: './deliveries',
                role: true
              },
              {
                name: _vm.$t('marketers'),
                link: './marketers',
                role: true
              },
              {
                name: _vm.$t('Price Lists'),
                link: './PriceLists',
                role: _vm.$checkRole('products_show')
              },
              // {
              //   name: $t('Sections bond'),
              //   link: './PaymentsSections',
              //   role: true
              // },
              {
                name: _vm.$t('expenditure Categories'),
                link: './expenseSections',
                role: _vm.$checkRole('expenses_show')
              },
              {
                name: _vm.$t('Incomes Categories'),
                link: './incomeSections',
                role: _vm.$checkRole('expenses_show')
              },
              {
                name: _vm.$t('section Accounts'),
                link: './sectionsAccounts',
                role: _vm.$checkRole('products_show')
              }
            ]}}),_c('reportsCard',{attrs:{"cardTitle":_vm.$t('Backup'),"icon":'fal fa-copy',"reports":[
              {
                name: _vm.$t('Backup'),
                link: './Backup',
                role: _vm.$checkRole('buckup_allow')
              }
            ]}})],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }