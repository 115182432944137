export const print = (content, copies = 1, printer = 'default', url = 'localhost') => {
  if (window.location.hostname == 'localhost') return offlinePrint(content, copies)
  const ws = new WebSocket(`ws://${url}:8080`)
  const printDetails =
    printer == 'default'
      ? {
          options: {
            preview: false,
            copies: 1,
            printBackground: true,
            silent: true
          },
          data: content
        }
      : {
          options: {
            preview: false,
            copies: 1,
            deviceName: printer,
            printBackground: true,
            silent: true
          },
          data: content
        }

  ws.onopen = () => {
    for (let i = 0; i < copies; i++) ws.send(JSON.stringify(printDetails))
  }

  ws.onerror = (error) => {
    offlinePrint(content, copies)
  }
}

const offlinePrint = (content, copies = 1) => {
  const iframe = document.createElement('iframe')
  document.body.appendChild(iframe)
  iframe.contentWindow.document.open()
  iframe.classList.add('printIframe')
  iframe.contentWindow.document.write(content)
  iframe.contentWindow.document.close()
  iframe.onload = function () {
    iframe.focus()
    for (let i = 0; i < copies; i++) iframe.contentWindow.print()
    iframe.remove()
  }
}
