<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('Create product')"
          :description="$t('From here you can create a new product')"
          v-if="$route.name == 'productsCreate'"
        />
        <addHeader
          :title="$t('Edit product')"
          :description="$t('From here you can modify the product data')"
          v-if="$route.name == 'productsEdit'"
        />
        <div class="mb-4 row">
          <dvider
            :title="$t('Basic information')"
            :description="$t('Basic product information')"
            noLine="true"
          />
          <fileInput
            :title="$t('Product image')"
            v-model="item.image"
            :image="item.image"
            :path="'products'"
            :hasErorr="errors.image"
            :error="$t('Choose a suitable file')"
            :col="'col-12'"
          />
          <formInput
            :title="$t('Product name')"
            v-model="item.name"
            :hasErorr="errors.name"
            :error="$t('This field is required')"
            maxlength="255"
          />

          <connectionInput
            :title="$t('Product classification')"
            :group="$t('classifications')"
            v-model="item.section_id"
            :hasAdd="true"
            :open="'addSection'"
            :values="$database.sections"
          />

          <div class="form-group row col-md-6">
            <label class="col-md-4">{{ $t('Code') }}</label>
            <!-- Input -->
            <div class="col-md-7">
              <div class="d-flex">
                <input
                  type="text"
                  class="form-control"
                  v-model="item.code"
                  :class="errors.code ? 'is-invalid' : ''"
                />
                <button class="btn btn-primary barcode-generator" @click="generatCode">
                  <i class="fas fa-scanner-keyboard"></i>
                </button>
              </div>
              <div class="invalid-feedback d-block" v-if="errors.code">كود المنتج مكرر</div>
            </div>
          </div>

          <!-- Input -->
          <!-- <div class="form-group row col-md-6">
            <label class="col-md-4">{{ $t('Barcode code') }}</label>
            <div class="col-md-7">
              <div class="d-flex">
                <input
                  type="text"
                  class="form-control"
                  v-model="item.barcode"
                  :class="errors.barcode ? 'is-invalid' : ''"
                />
                <button class="btn btn-primary barcode-generator" @click="generatBarcode">
                  <i class="fas fa-scanner-keyboard"></i>
                </button>
              </div>
              <div class="invalid-feedback d-block" v-if="errors.barcode">باركود المنتج مكرر</div>
            </div>
          </div> -->
          <connectionInput
            :title="$t('Supplier name')"
            :group="$t('rates')"
            v-model="item.supplier_id"
            :hasAdd="true"
            :values="$database.suppliers"
            :open="'addSupplier'"
          />
          <!-- <formInput
            :title="$t('Barcode description')"
            v-model="item.barcode_description"
            :hasErorr="errors.barcode_description"
            :error="$t('This field is required')"
            maxlength="255"
          /> -->

          <div class="form-group row col-md-12">
            <label class="col-md-2">{{ $t('Product description') }}</label>
            <div class="col-md-95">
              <ckeditor
                :editor="editor"
                v-model="item.description"
                :config="editorConfig"
              ></ckeditor>
            </div>
          </div>

          <div class="col-xl-12 row" v-if="item.allow_product_options == 1">
            <label class="col-2">{{ $t('Product Options') }}</label>
            <div class="col-4">
              <multiselect
                v-model="selctedProductOptions"
                :options="$database.productOptions"
                input-class="form-control w-100"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('Choose from options')"
                label="name"
                track-by="name"
                :hide-selected="true"
              />
            </div>
          </div>

          <dvider
            :title="$t('Accounts information')"
            :description="$t('Product account information')"
          />
          <connectionInput
            :title="$t('Tax rate')"
            :group="$t('Rates')"
            v-model="item.tax_id"
            :hasAdd="true"
            :values="$database.taxes"
            :open="'addTaxe'"
          />
          <connectionInput
            :title="$t('Discount rate')"
            :group="$t('Discount rates')"
            v-model="item.discount_id"
            :hasAdd="true"
            :values="$database.discounts"
            :open="'addDiscount'"
          />
          <div class="form-group row col-md-6">
            <div class="col-4">
              <label>{{ $t('Selling price') }}</label>
            </div>
            <div class="col-7">
              <select class="form-control" v-model="item.price_type">
                <option value="0">{{ $t('selling Price Without Tax') }}</option>
                <option value="1">{{ $t('selling Price Wit Tax') }}</option>
              </select>
              <input
                type="number"
                :placeholder="$t('selling Price Without Tax')"
                class="form-control"
                v-model="item.price"
                :disabled="item.price_type == 1"
              />
              <input
                type="number"
                :placeholder="$t('selling Price With Tax')"
                class="form-control"
                v-model="item.price_with_tax"
                :disabled="item.price_type == 0"
              />
            </div>
          </div>
          <div class="form-group row col-md-6">
            <div class="col-4">
              <label>{{ $t('purchasing price') }}</label>
            </div>
            <div class="col-7">
              <select class="form-control" v-model="item.cost_type">
                <option value="0">{{ $t('purchasing Price Without Tax') }}</option>
                <option value="1">{{ $t('purchasing Price With Tax') }}</option>
              </select>

              <input
                type="number"
                :placeholder="$t('purchasing Price With Tax')"
                class="form-control"
                v-model="item.cost"
                :disabled="item.cost_type == 1"
              />
              <input
                type="number"
                :placeholder="$t('purchasing Price Without Tax')"
                class="form-control"
                v-model="item.cost_with_tax"
                :disabled="item.cost_type == 0"
              />
            </div>
          </div>
          <dvider :title="$t('Barcode list')" :description="$t('Product barcodes list')" />
          <table class="table">
            <thead class="invoice-table-header">
              <tr>
                <th scope="col">{{ $t('Barcode') }}</th>

                <th scope="col">{{ $t('Settings') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(element, index) in item.barcode">
                <td>
                  <input
                    type="number"
                    :placeholder="$t('Barcode')"
                    class="form-control"
                    v-model="item.barcode[index]"
                  />
                </td>

                <td>
                  <button
                    class="btn btn-danger"
                    @click="deleteBarcode(index)"
                    :disabled="item.barcode.length <= 1"
                  >
                    <i class="fas fa-trash"></i>
                    {{ $t('Delete') }}
                  </button>
                </td>
              </tr>
              <tr>
                <th colspan="20">
                  <button type="button" class="btn btn-success w-100 mb-4" @click="addBarcode()">
                    <i class="fas fa-plus"></i>
                    {{ $t('Add') }}
                  </button>
                </th>
              </tr>
            </tbody>
          </table>
          <pricesTable class="col-12" />

          <dvider
            :title="$t('Store settings')"
            :description="$t('Settings for stores')"
            v-if="item.type == 0"
          />
          <connectionInput
            :title="$t('Basic unit')"
            :group="$t('units')"
            v-model="item.unit_id"
            :open="'addUnit'"
            :hasAdd="true"
            :values="$database.units"
            v-if="item.type == 0"
          />

          <unitsTable class="col-12" />

          <dvider
            :title="$t('Warehouse quantities')"
            :description="$t('Quantities Of Products available in Stores')"
            v-if="item.type == 0"
          />

          <div class="form-group col-12 row" v-if="item.type == 0">
            <label class="col-2">{{ $t('Track inventory') }}</label>
            <div class="col-md-7">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  id="cardToggle"
                  :checked="item.track_quantity == 1"
                  class="custom-control-input"
                  value="1"
                  @change="changevalue()"
                />
                <label for="cardToggle" class="custom-control-label"></label>
              </div>
            </div>
          </div>

          <storesTable v-if="item.type == 0 && item.track_quantity == 1" class="co-12" />

          <dvider
            :title="$t('Manufacturing settings')"
            :description="$t('Manufacturing numbers')"
            v-if="item.type == 0"
          />
          <checkInput
            :title="$t('Factory product')"
            :value="item.factory_product"
            v-model="item.factory_product"
          />
          <connectionInput
            :title="$t('Manufacturing model')"
            :group="$t('Manufacturing models')"
            v-model="item.manufacturing_model_id"
            :values="$database.manufacturingModels"
            v-if="item.factory_product"
          />

          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'productsCreate' && !$parent.stopEdit"
          >
            {{ $t('Create product') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'productsEdit' && !$parent.stopEdit"
          >
            {{ $t('Edit product') }}
          </a>
          <loading v-if="$parent.stopEdit" />
        </div>
      </div>
    </div>
    <addSection v-if="model == 'addSection'"></addSection>
    <addUnit v-if="model == 'addUnit'"></addUnit>
    <addTaxe v-if="model == 'addTaxe'"></addTaxe>
    <addDiscount v-if="model == 'addDiscount'"></addDiscount>
  </div>
</template>
<script>
import axios from 'axios'

import formInput from '@/elements/add/form/formInput.vue'
import fileInput from '@/elements/add/form/fileInput.vue'
import formTextarea from '@/elements/add/form/formTextarea.vue'
import connectionInput from '@/elements/add/form/connectionInput.vue'
import connectionInput2 from '@/elements/add/form/connectionInput2.vue'

import monyInput from '@/elements/add/form/monyInput.vue'
import selectInput from '@/elements/add/form/selectInput.vue'
import checkInput from '@/elements/add/form/checkInput.vue'

import dvider from '@/elements/add/dvider.vue'
import addHeader from '@/elements/add/header.vue'

import addSection from './components/addSection.vue'
import addUnit from './components/addUnit.vue'
import addTaxe from './components/addTaxe.vue'
import addDiscount from './components/addDiscount.vue'
import loading from '@/elements/add/loading.vue'

import pricesTable from '@/elements/add/form/product/pricesTable.vue'
import unitsTable from '@/elements/add/form/product/unitsTable.vue'
import storesTable from '@/elements/add/form/product/storesTable.vue'
import PriceInput from '@/elements/add/form/product/priceInput.vue'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import UploadAdapter from '@/helpers/UploadAdapter.js'

import { VueEditor } from 'vue2-editor'
export default {
  data() {
    return {
      path: '/products',
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'insertTable',
          '|',
          'imageUpload',
          'mediaEmbed',
          '|',
          'undo',
          'redo'
        ],
        table: {
          toolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
        },
        extraPlugins: [
          function uploader(editor) {
            editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
              return new UploadAdapter(loader)
            }
          }
        ],
        language: 'ar'
      },

      item: {
        description: '',
        barcode_description: '',
        type: 0,
        track_quantity: 1,
        code: '',
        barcode: [null],
        tax_id: 0,
        discount_id: 0,
        unit_id: 0,
        section_id: 0,
        supplier_id: '',
        allow_product_options: 0,
        product_option_id: '',
        productPrices: [{ priceList_id: 0, detail_id: 0, unit_id: 0, price: '', cost: '' }],
        productUnits: [],
        productQuantities: [],
        unit_name: 'وحدة',
        detail_id: 0,
        factory_product: 0,
        manufacturing_model_id: 0,
        price_type: 0,
        cost_type: 0,
        price: '',
        price_with_tax: '',
        cost: '',
        cost_with_tax: ''
      },
      selctedProductOptions: [],
      errors: {
        name: false,
        price: false,
        barcode: false,
        code: false
      },
      model: false,
      disabled: true,
      runStores: 0
    }
  },
  methods: {
    addUnit() {
      this.item.productUnits.push({
        unit_id: '',
        quantity: '',
        barcode: []
      })
    },
    addProductPrices() {
      this.item.productPrices.push({
        priceList_id: 0,
        detail_id: 0,
        unit_id: 0,
        price: '',
        cost: ''
      })
    },
    addBarcode() {
      this.item.barcode.push(null)
    },
    deleteBarcode(index) {
      this.item.barcode.splice(index, 1)
    },
    getItem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.$parent.stopEdit = false
        this.item = response.data
        this.item.detail_id = 0
        this.runStores += 1
        if (this.item.productUnits.length == 0) {
          this.addUnit()
        }
        if (Array.isArray(JSON.parse(this.item.product_option_id))) {
          JSON.parse(this.item.product_option_id).forEach((id) => {
            var productOption = this.$database.productOptions.find((el) => el.id == id)
            if (productOption) {
              this.selctedProductOptions.push(productOption)
            }
          })
        }
      })
    },
    saveItem() {
      var error = 0
      if (typeof this.item.name === 'undefined' || this.item.name == '') {
        error = 1
        this.errors.name = true
      }
      if (
        typeof this.item.barcode !== 'undefined' &&
        this.item.barcode != '' &&
        this.item.barcode != null
      ) {
        var barcode = this.$database.products.find(
          (el) => el.barcode == this.item.barcode && el.id != this.item.id
        )
        if (barcode) {
          error = 1
          this.errors.barcode = true
        }
      }

      if (typeof this.item.code !== 'undefined' && this.item.code != '' && this.item.code != null) {
        var barcode = this.$database.products.find(
          (el) => el.code == this.item.code && el.id != this.item.id
        )
        if (barcode) {
          error = 1
          this.errors.code = true
        }
      }

      if (typeof this.item.name !== 'undefined' && this.item.name != '' && this.item.name != null) {
        var barcode = this.$database.products.find(
          (el) => el.name == this.item.name && el.id != this.item.id
        )
        if (barcode) {
          error = 1
          this.errors.name = true
        }
      }
      if (error == 0) {
        if (this.$route.name == 'productsCreate') {
          this.$toast.success(this.$t('Product successfully added'))
          this.$addToLocalDatabase(this.item, this.path.substring(1))
          this.$router.go(-1)
        } else if (this.$route.name == 'productsEdit') {
          this.$parent.stopEdit = true
          this.item._method = 'PUT'
          axios
            .post(this.$linkGenerator(this.path + '/' + this.item.id), this.item)
            .then((response) => {
              this.$toast.success(this.$t('Product has been modified successfully'))
              this.$router.go(-1)
              return response
            })
        }
      } else {
        this.$toast.error(this.$t('Please make sure of the input'))
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    changevalue() {
      if (this.item.track_quantity == 1) {
        this.item.track_quantity = 0
      } else if (this.item.track_quantity == 0) {
        this.item.track_quantity = 1
      }
    },
    generatBarcode() {
      this.item.barcode = Math.floor(Math.random() * 10000000)
    },
    generatCode() {
      this.item.code = Math.floor(Math.random() * 10000000)
    }
  },
  mounted() {
    this.$updateDatabase([
      'sections',
      'priceLists',
      'details',
      'units',
      'stores',
      'suppliers',
      'materials',
      'taxes',
      'manufacturingModels'
    ])

    if (this.$route.name == 'productsEdit') {
      this.$parent.stopEdit = true
      this.getItem()
    } else {
      this.runStores += 1
      this.addUnit()
    }
  },
  watch: {
    'item.tax_id': function (val) {
      const tax = this.$database.taxes.find((el) => el.id == this.item.tax_id)
      let taxRate = 0
      if (tax) taxRate = tax.rate

      if (this.item.price_type == 0)
        this.item.price_with_tax = parseFloat(this.item.price * (1 + taxRate / 100), 6)
      if (this.item.price_type == 1)
        this.item.price = parseFloat(this.item.price_with_tax / (1 + taxRate / 100), 6)
      if (this.item.cost_type == 0)
        this.item.cost_with_tax = parseFloat(this.item.cost / (1 + taxRate / 100), 6)
      if (this.item.cost_type == 1)
        this.item.cost = parseFloat(this.item.cost_with_tax / (1 + taxRate / 100), 6)
    },
    'item.price_with_tax': function (val) {
      if (this.item.price_type == 1) {
        const tax = this.$database.taxes.find((el) => el.id == this.item.tax_id)
        let taxRate = 0
        if (tax) taxRate = tax.rate
        this.item.price = parseFloat(val / (1 + taxRate / 100), 6)
      }
    },
    'item.price': function (val) {
      if (this.item.price_type == 0) {
        const tax = this.$database.taxes.find((el) => el.id == this.item.tax_id)
        let taxRate = 0
        if (tax) taxRate = tax.rate
        this.item.price_with_tax = parseFloat(val * (1 + taxRate / 100), 6)
      }
    },
    'item.cost_with_tax': function (val) {
      if (this.item.cost_type == 1) {
        const tax = this.$database.taxes.find((el) => el.id == this.item.tax_id)
        let taxRate = 0
        if (tax) taxRate = tax.rate
        this.item.cost = parseFloat(val / (1 + taxRate / 100), 6)
      }
    },
    'item.cost': function (val) {
      if (this.item.cost_type == 0) {
        const tax = this.$database.taxes.find((el) => el.id == this.item.tax_id)
        let taxRate = 0
        if (tax) taxRate = tax.rate
        this.item.cost_with_tax = parseFloat(val * (1 + taxRate / 100), 6)
      }
    },
    runStores: function (val) {
      if (val == 1) {
        this.$database.stores.forEach((store) => {
          var productQuantity = this.item.productQuantities.find((el) => el.store_id == store.id)
          if (productQuantity) {
            productQuantity.name = store.name
          } else {
            this.item.productQuantities.push({
              store_id: store.id,
              name: store.name,
              quantity: '',
              min_quantity: ''
            })
          }
        })
      }
    },
    'item.unit_id': function (val) {
      var unit = this.$database.units.find((el) => el.id == val)
      if (unit) {
        this.item.unit_name = unit.name
      } else {
        this.item.unit_name = 'وحدة'
      }
    },
    selctedProductOptions: function (val) {
      this.item.product_option_id = val.map((el) => el.id)
    }
  },
  components: {
    formInput,
    fileInput,
    dvider,
    addHeader,
    formTextarea,
    connectionInput,
    monyInput,
    selectInput,
    addSection,
    addUnit,
    addTaxe,
    addDiscount,
    loading,
    pricesTable,
    unitsTable,
    storesTable,
    checkInput,
    PriceInput,
    VueEditor,
    connectionInput2
  }
}
</script>
