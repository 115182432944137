<template>
  <div class="print-model">
    <div id="printMe" ref="printMe">
      <PurchaseReturnsComponent :item="item" :options="$option" :type="$options.print_type" />
      <div class="signeture-container">
        <div
          class="signeture-item"
          v-for="(signeture, index) in $options.purchase_returns_signatures_data"
          :key="index"
        >
          <div class="signeture-name">{{ signeture.name }}</div>
          <div class="signeture-points">..........................</div>
        </div>
      </div>
      <!-- <p class="pioneer-data">تم الإنشاء بواسطة ثاني أثنين</p> -->
    </div>
  </div>
</template>
<script setup>
import PurchaseReturnsComponent from './components/purchaseReturns.vue'

import { print } from '@/helpers/print'
import Vue from 'vue'
const printItem = () => {
  // Prepare content
  const html = document.getElementById('printMe').innerHTML
  const css =
    '<meta charset="utf-8" /><style>' +
    Vue.prototype.$print +
    Vue.prototype.$option.invoice_css +
    '</style>'
  const content = '<head>' + css + '</head><body>' + html + '</body>'
  print(content)
  emits('onFinish')
}

// Print item
setTimeout(() => printItem(), 40)

const props = defineProps(['item'])
const emits = defineEmits(['onFinish'])
</script>
