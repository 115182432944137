import axios from 'axios'
import linkGenerator from '@/helpers/linkGenerator'
import initItem from '@/helpers/initItem'
const getItem = async ({ id, path }) => {
  try {
    const response = await axios.get(linkGenerator('/' + path + '/' + id))
    return initItem(response.data)
  } catch (error) {
    console.log(error)
  }
}

export default getItem
