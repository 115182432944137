var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c(_setup.ShowHeader,{staticClass:"col-12 mt-3",attrs:{"icon":'user-cog',"title":_setup.item.name,"stat":_setup.item.stat,"statText":_setup.item.stat ? _vm.$t('Active') : _vm.$t('Inactive'),"subData":[
        { title: _vm.$t('Title'), value: _setup.item.job_title },
        {
          title: _vm.$t('Salary'),
          value: _vm.$moneyFormat(_setup.item.salary + ' ' + _vm.$option.currency)
        }
      ],"buttons":[
        {
          title: _vm.$t('Modulation'),
          icon: 'edit',
          link: _setup.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$checkRole('empolyees_edit')
        }
      ]}}),_c('div',{staticClass:"col-12"},[_c(_setup.ShowFile,{attrs:{"file":_setup.item.file}})],1),_c('div',{staticClass:"col-12"},[(_vm.$checkRole('history_show'))?_c(_setup.HistoryCard,{attrs:{"items":_setup.item.histories}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }