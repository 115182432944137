//accounting reports
import profitsByInvoices from '@/pages/accounting/profitsByInvoices.vue'
import profitsAndLosses from '@/pages/accounting/profitsAndLosses.vue'
import balanceSheet from '@/pages/accounting/balanceSheet.vue'
import dailyRestrictions from '@/pages/accounting/dailyRestrictions.vue'
import AccountingTree from '@/pages/accounting/accountingTree/index.vue'
import AccountingRestrictions from '@/pages/accounting/accountingRestrictions/index.vue'
import AccountingRestrictionsForm from '@/pages/accounting/accountingRestrictions/form.vue'
import AccountingRestrictionsShow from '@/pages/accounting/accountingRestrictions/show.vue'
import AutoRestrictions from '@/pages/accounting/autoRestrictions/index.vue'
import AutoRestrictionsForm from '@/pages/accounting/autoRestrictions/form.vue'
import AutoRestrictionsShow from '@/pages/accounting/autoRestrictions/show.vue'
import CostCenters from '@/pages/accounting/costCenter/index.vue'
import CostCentersForm from '@/pages/accounting/costCenter/form.vue'
import totalAccounts from '@/pages/accounting/totalAccounts.vue'
const routes = [
  {
    path: 'totalAccounts',
    name: 'totalAccounts',
    component: totalAccounts
  },
  {
    path: 'accountingTree',
    name: 'accountingTree',
    component: AccountingTree
  },
  {
    path: 'accountingRestrictions',
    name: 'accountingRestrictions',
    component: AccountingRestrictions
  },
  {
    path: 'accountingRestrictions/create',
    name: 'accountingRestrictionsCreate',
    component: AccountingRestrictionsForm
  },
  {
    path: 'accountingRestrictions/:id',
    name: 'accountingRestrictionsShow',
    component: AccountingRestrictionsShow
  },
  {
    path: 'accountingRestrictions/:id/edit',
    name: 'accountingRestrictionsEdit',
    component: AccountingRestrictionsForm
  },
  {
    path: 'autoRestrictions',
    name: 'autoRestrictions',
    component: AutoRestrictions
  },
  {
    path: 'autoRestrictions/create',
    name: 'autoRestrictionsCreate',
    component: AutoRestrictionsForm
  },
  {
    path: 'autoRestrictions/:id',
    name: 'autoRestrictionsShow',
    component: AutoRestrictionsShow
  },
  {
    path: 'autoRestrictions/:id/edit',
    name: 'autoRestrictionsEdit',
    component: AutoRestrictionsForm
  },

  {
    path: 'costCenters',
    name: 'costCenters',
    component: CostCenters
  },
  {
    path: 'costCenters/create',
    name: 'CostCentersCreate',
    component: CostCentersForm
  },
  {
    path: 'costCenters/:id/edit',
    name: 'CostCentersEdit',
    component: CostCentersForm
  },
  {
    path: 'profitsByInvoices',
    name: 'profitsByInvoices',
    component: profitsByInvoices
  },
  {
    path: 'profitsAndLosses',
    name: 'profitsAndLosses',
    component: profitsAndLosses
  },
  { path: 'balanceSheet', name: 'balanceSheet', component: balanceSheet },
  {
    path: 'dailyRestrictions',
    name: 'dailyRestrictions',
    component: dailyRestrictions
  }
]

export default routes
