<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <PageHeader :title="$t('Reports')" :description="$t('Point of sales sessions')" />

        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title"><i class="fal fa-edit"></i> {{ $t('Report') }}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <dateFilter class="col-md-3" />

              <searchInput
                class="col-md-3"
                :title="$t('User')"
                :plaseholder="$t('Choose user')"
                v-model="params.user_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.user_id"
                :values="$database.users"
              />

              <searchInput
                class="col-md-3"
                :title="$t('Point of sale')"
                :plaseholder="$t('Choose point of sale')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.point_of_sale_id"
                :value="params.point_of_sale_id"
                :values="$database.pointOfSales"
              />

              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('Status') }}</label>
                  <select type="text" v-model="params.status" class="form-control">
                    <option value="">{{ $t('Choose status') }}</option>
                    <option value="open">{{ $t('Open') }}</option>
                    <option value="close">{{ $t('Closed') }}</option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('Session number') }}</label>
                  <input
                    type="text"
                    v-model="params.session_number"
                    class="form-control"
                    :placeholder="$t('Enter session number')"
                  />
                </div>
              </div>

              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader
            :title="$t('Point of sales sessions')"
            :details="[
              { title: 'اسم المستخدم', value: userName },
              { title: 'نقطة البيع', value: pointOfSaleName },
              { title: 'رقم الجلسة', value: sessionNumber }
            ]"
          />
          <reportTable
            :emptyTableText="$t('there are no data at the moment')"
            :emptyTableSubText="$t('Try adding some items and try again')"
            :cloumns="[
              {
                column: 'id',
                title: $t('Code'),
                type: 'text',
                sort: true
              },
              {
                column: 'point_of_sale_name',
                title: $t('Point of sale'),
                type: 'text',
                sort: true
              },
              {
                column: 'user',
                title: $t('User'),
                type: 'text',
                sort: true,
                supData: true
              },
              {
                column: 'status',
                title: $t('Status'),
                type: 'text',
                sort: true
              },
              {
                column: 'start_date',
                title: $t('Start date'),
                type: 'text',
                sort: true
              },
              {
                column: 'end_date',
                title: $t('End date'),
                type: 'text',
                sort: true
              },

              {
                column: 'entered_amount',
                title: $t('Amount'),
                type: 'text',
                sort: true,
                supData: true
              },
              {
                column: 'actual_amount',
                title: $t('Actual amount'),
                type: 'text',
                sort: true,
                supData: true
              },
              {
                column: 'deference',
                title: $t('Deference'),
                type: 'text',
                sort: true,
                supData: true
              }
            ]"
            :footer="[
              { colspan: 6, value: $t('Total') },
              { colspan: 1, value: enteredAmount, supData: true },
              { colspan: 1, value: actualAmount, supData: true },
              { colspan: 1, value: deference, supData: true }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import reportHeader from '@/elements/reports/reportHeader.vue'
import reportTable from '@/elements/reports/reportTable.vue'
import PageHeader from '@/components/pageHeader'
import dateFilter from '@/elements/reports/dateFilter.vue'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'

export default {
  data() {
    return {
      path: '/reports/pointOfSales/sessions',
      items: [],
      loading: 1,
      enteredAmount: 0,
      actualAmount: 0,
      deference: 0,
      params: {
        point_of_sale_id: '',
        user_id: '',
        session_number: '',
        startDate: '',
        endDate: '',
        Status: ''
      },

      excel: {
        fileName: 'point of sales sessions',
        reportData: '[]'
      },

      userName: '',
      pointOfSaleName: '',
      sessionNumber: '',

      searchStartDate: '',
      searchEndDate: ''
    }
  },
  methods: {
    getData() {
      this.$setParams(this.params)
      this.loading = 1
      this.enteredAmount = 0
      this.actualAmount = 0
      this.deference = 0
      this.items = []

      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.items = response.data

        this.enteredAmount = this.items.reduce(
          (accumulator, current) => accumulator + parseFloat(current.entered_amount),
          0
        )
        this.actualAmount = this.items.reduce(
          (accumulator, current) => accumulator + parseFloat(current.actual_amount),
          0
        )

        this.deference = this.enteredAmount - this.actualAmount

        this.items.forEach((item) => {
          item.deference = this.$moneyFormat(
            parseFloat(item.entered_amount) - parseFloat(item.actual_amount)
          )
          item.entered_amount = this.$moneyFormat(parseFloat(item.entered_amount))
          item.actual_amount = this.$moneyFormat(parseFloat(item.actual_amount))

          const user = this.$database.users.find((el) => el.id == item.user_id)
          if (user) item.user = user.name
          item.start_date = this.$dateFormat(item.start_date)
          item.end_date = item.end_date ? this.$dateFormat(item.end_date) : '--'
          item.status = item.status == 'open' ? this.$t('Open') : this.$t('Closed')
        })

        this.enteredAmount = this.$moneyFormat(this.enteredAmount)
        this.actualAmount = this.$moneyFormat(this.actualAmount)
        this.deference = this.$moneyFormat(this.deference)

        this.loading = 0
      })

      this.searchStartDate = this.params.startDate
      this.searchEndDate = this.params.endDate

      var user = this.$database.users.find((el) => el.id == this.params.user_id)
      if (user) {
        this.userName = user.name
      } else {
        this.userName = ''
      }

      this.sessionNumber = this.params.session_number
      var pointOfSale = this.$database.pointOfSales.find(
        (el) => el.id == this.params.point_of_sale_id
      )
      this.pointOfSaleName = pointOfSale ? pointOfSale.name : ''
    },
    getItems() {
      this.$setParams(this.params)
      if (this.params.orderBy) {
        this.items.sort(this.$dynamicSort(this.params))
      }
    }
  },
  watch: {
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams()
    if (typeof this.params.startDate === 'undefined') {
      this.params.startDate = new Date()
        .toISOString('en-US', { timeZone: 'Africa/Cairo' })
        .slice(0, 10)
    }
    if (!this.params.order_type) this.params.order_type = ''
    this.getData()
    this.$updateDatabase(['users'])
  },
  components: {
    reportTable,
    PageHeader,
    dateFilter,
    searchInput,
    actionButtons,
    reportHeader
  }
}
</script>
