<template>
  <div class="invoice-container">
    <img :src="logo" class="logo" v-if="$option.image != null" />
    <div class="title">{{ $t('Simple tax invoice') }}</div>
    <div class="invoice-number">{{ $t('Invoice number') }}: {{ item.code }}</div>
    <div class="invoice-number" v-if="options.order_number">
      {{ $t('Operation number') }}: {{ item.order_number }}
    </div>
    <!-- <div class="invoice-number" v-if="options.order_number">
      {{ $t('User Name') }}: {{ marketer ?? '----' }}
    </div> -->
    <div class="store-name">{{ $option.name }} - {{ options.name }}</div>
    <!-- <div class="store-address">{{ $option.zatca.registered_address ?? $option.address1 }}</div> -->

    <div class="date-info">{{ $t('Date') }}: {{ $dateFormat(item.date) }}</div>
    <div class="register-number-info">
      <!-- {{ $t('Tax number') }}: {{ $option.zatca.vat_number ?? $option.tax_number }} -->
    </div>
    <div class="date-info" v-if="options.show_products">
      {{ $t('Order type') }}:
      {{ item.order_type == 0 ? $t('Take away') : '' }}
      {{ item.order_type == 1 ? $t('Indoor') : '' }}
      {{ item.order_type == 2 ? $t('Delivery app') : '' }}
      {{ item.order_type == 3 ? $t('Hospitality') : '' }}
      {{ item.order_type == 4 ? $t('Subsistence') : '' }}
      {{ item.order_type == 5 ? $t('Depreciation') : '' }}
      {{ item.order_type == 6 ? $t('Kitchen') : '' }}
    </div>
    <div class="date-info" v-if="item.order_type == 4">
      {{ $t('Employee name') }}:
      {{ this.$database.customers.find((el) => el.id == item.customer_id)?.name ?? '' }}
    </div>
    <div class="date-info" v-if="item.order_type == 6">
      {{ $t('Customer name') }}:
      {{ this.$database.customers.find((el) => el.id == item.customer_id)?.name ?? '' }}
    </div>
    <div class="date-info" v-if="item.order_type == 2">
      {{ $t('Delivery app') }}:
      {{ options.delivery_apps.find((el) => el.customer_id == item.customer_id)?.name ?? '' }}
    </div>
    <div class="date-info" v-if="item.order_type == 2">
      {{ $t('Delivery app reference') }}: {{ item.refrance }}
    </div>
    <div class="date-info" v-if="item.order_type < 3 || order_type == 6">
      {{ $t('Payment type') }}:
      {{ item.payment_type == 'cash' ? $t('Cash') : '' }}
      {{
        item.payment_type == 'method'
          ? options.payment_methods.find((el) => el.safe_id == item.payment_method)
            ? options.payment_methods.find((el) => el.safe_id == item.payment_method)?.name
            : $t('Payment method')
          : ''
      }}
      {{ item.payment_type == 'multi' ? $t('Multi payments') : '' }}
      {{ item.payment_type == 'deferred' ? $t('Deferred') : '' }}
    </div>
    <div class="date-info">
      {{ $t('User Name') }} :
      <strong>{{ item?.user?.name ?? $user.name }}</strong>
    </div>

    <table class="details-table">
      <thead>
        <tr>
          <th>{{ $t('Product') }}</th>
          <th>{{ $t('Quantity') }}</th>
          <!-- <th>{{ $t('Price before tax') }}</th>
          <th>{{ $t('Tax') }}</th> -->
          <th>{{ $t('Total') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(qItem, index) in item.quotationItems" :key="index">
          <td>{{ qItem.product_name }}</td>
          <td>{{ parseFloat(qItem.quantity).toFixed(2) }}</td>
          <!-- <td>{{ $moneyFormat(qItem.price) }}</td>
          <td>{{ $moneyFormat(qItem.average) }}</td> -->
          <td>{{ $moneyFormat(qItem.total) }}</td>
        </tr>
      </tbody>
    </table>

    <div class="summary">
      <div>
        {{ $t('Total amount before Taxes') }}: <span>{{ $moneyFormat(item.subtotal) }}</span>
      </div>
      <div>
        {{ $t('Taxes') }} <template v-if="item.tax_rate">({{ item.tax_rate ?? 0 }}%)</template>:
        <span>{{ $moneyFormat(item.tax) }}</span>
      </div>
      <div class="total">
        {{ $t('Total after Taxes') }}
        <template v-if="item.tax_rate">({{ item.tax_rate ?? 0 }}%)</template>:
        <span>{{ $moneyFormat(item.total) }}</span>
      </div>
    </div>

    <!-- <div class="footer">>>>>>>>>>> {{ $t('Close invoice') }} 0100 <<<<<<<<<<</div> -->

    <div class="qr-code" v-if="$option.tax_invoice">
      <QrCodeVue
        :value="
          qrCodeGenerator(
            $fullDateFormat(item.date),
            parseFloat(item.total).toFixed(2),
            parseFloat(item.tax).toFixed(2)
          )
        "
        size="130"
        level="H"
        render-as="svg"
      />
    </div>
  </div>
</template>
<script setup>
import Vue from 'vue'
import { ref } from 'vue'
import qrCodeGenerator from '@/helpers/qrCodeGenerator'
import QrCodeVue from 'qrcode.vue'

const logo = localStorage.getItem('logo')

// Get options
const options = ref({})
const pointOfSale = Vue.prototype.$database.pointOfSales.find(
  (el) => el.id == props.item.point_of_sale_id
)
if (pointOfSale) options.value = pointOfSale
const marketer = Vue.prototype.$database.marketers.find((el) => el.id == props.item.marketer_id)
const props = defineProps(['item'])
</script>
<style scoped>
body {
  -webkit-print-color-adjust: exact !important;
}
@page {
  size: 2.8in auto;
  margin: 0;
}
body {
  font-family: 'Cerebri Sans', Tajawal, sans-serif;
  background-color: white;
  direction: rtl;
  text-align: right;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  width: 2.8in;
}
.invoice-container {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  text-align: center;
}
.logo {
  width: 4cm;
  margin: 4px auto;
}
.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #000;
}
.invoice-number {
  font-weight: bold;
  margin-bottom: 6px;
  font-size: 12px;
}
.store-name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 6px;
  color: #000;
}
.store-address {
  font-size: 14px;
  font-weight: 500;
  font-weight: bold;
  margin-bottom: 6px;
  color: #000;
}
.date-info {
  text-align: start;
  font-size: 11px;
  font-weight: 500;
  font-weight: bold;
  color: #000;
}
.register-number-info {
  text-align: start;
  font-size: 11px;
  font-weight: 500;
  font-weight: bold;
  color: #000;
}
.details-table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
  font-weight: bold;
  border-bottom: 1px dashed #ddd;
}
.details-table th,
.details-table td {
  padding: 8px 3px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}
.details-table th {
  border-top: 1px dashed #ddd;
  border-bottom: 1px dashed #ddd;
  font-weight: bold;
}
.summary {
  text-align: right;
  margin-top: 10px;
  font-size: 12px;
  font-weight: bold;
}
.summary div {
  display: flex;
  justify-content: space-between;
  padding: 3px 0;
}
.summary .total {
  font-weight: bold;
  color: #000;
}
.footer {
  margin-top: 30px;
  font-size: 10px;
  color: #666;
}
.qr-code {
  margin-top: 10px;
}
</style>
