<template>
  <div class="row container-fluid">
    <div class="col-12">
      <PageHeader
        :title="$t('point Of Sale')"
        :description="$t('From here you can control your point Of Sale reports')"
      />
    </div>
    <!-- تقارير  نقطة البيع -->
    <reportsCard
      class="col-md-6"
      :cardTitle="$t('point Of Sale')"
      :icon="'far fa-cash-register'"
      v-if="
        ($user.role.available_reports.find((el) => el.id == 0) || $user.admin) &&
        $site.pointOfSales_allow
      "
      :reports="[
        {
          name: $t('point Of Sale'),
          link: './pointOfSalesOverview',
          role: $user.role.available_reports.find((el) => el.id == 0) || $user.admin
        },
        {
          name: $t('point Of Sale Sessions'),
          link: './pointOfSalesSessionsReport',
          role: $user.role.available_reports.find((el) => el.id == 0) || $user.admin
        }
      ]"
    />
  </div>
</template>
<script>
import reportsCard from '@/elements/reports/reportsCard.vue'
import PageHeader from '@/components/pageHeader'
export default {
  components: {
    reportsCard,
    PageHeader
  }
}
</script>
