// Main plugins pages
import pluginsIndex from '@/pages/plugins/index'

// Plugins pages
import zatcaIndex from '@/pages/plugins/zatca/index'
import shortcutsIndex from '@/pages/plugins/shortcuts/index.vue'
const routes = [
  // Main plugins pages
  {
    path: 'plugins',
    name: 'plugins',
    component: pluginsIndex
  },

  // Plugins pages
  {
    path: 'plugins/zatca',
    name: 'zatca',
    component: zatcaIndex
  },
  {
    path: 'plugins/shortcuts',
    name: 'shortcuts',
    component: shortcutsIndex
  }
]

export default routes
