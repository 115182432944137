import { ref, onMounted } from 'vue'
import axios from 'axios'
import Vue from 'vue'

export const useGetItem = (data) => {
  const { path, id, localData = data.localData ?? false } = data

  const item = ref({})
  const loading = ref(true)

  const getItem = async () => {
    loading.value = true
    
    // Try to get from local data
    if (localData) {
      const itemFromDatabase = Vue.prototype.$database[path].find((el) => el.id == id)
      if (itemFromDatabase) item.value = itemFromDatabase
      loading.value = false
    }

    // Get from database
    const response = await axios.get(Vue.prototype.$linkGenerator('/' + path + '/' + id));
    item.value = response.data
    loading.value = false
  }


  onMounted(getItem)

  return {
    item,
    loading,
    getItem
  }
}

export default useGetItem