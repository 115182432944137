<template>
  <Layout
    :title="$t('New session')"
    :loading="loading"
    @close="() => $emit('close')"
    @action="submit()"
  >
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">{{ $t('Point of sale') }}</th>
          <th scope="col">{{ $t('Session number') }}</th>
          <th scope="col">{{ $t('User') }}</th>
          <th scope="col">{{ $t('Start date') }}</th>
          <th scope="col">{{ $t('End date') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ session?.point_of_sale?.name ?? '--' }}</td>
          <td>{{ session?.id ?? '--' }}</td>
          <td>{{ $user.name }}</td>
          <td>{{ session?.start_date ?? '--' }}</td>
          <td>{{ this.$dateFormat() }}</td>
        </tr>
      </tbody>
    </table>

    <h3 class="mt-3">{{ $t('Payments details') }}</h3>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">{{ $t('Payment method') }}</th>
          <th scope="col">{{ $t('Amount') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="payment in payments">
          <td>{{ payment.name }}</td>
          <td class="p-0">
            <input
              type="number"
              class="w-100 form-control"
              :placeholder="$t('Amount')"
              v-model="payment.amount"
            />
          </td>
        </tr>
        <tr v>
          <td>
            <strong>{{ $t('Total') }}</strong>
          </td>
          <td>
            <strong>{{
              $moneyFormat(
                payments.reduce(
                  (total, method) =>
                    (isNaN(parseFloat(method.amount)) ? 0 : parseFloat(method.amount)) + total,
                  0
                )
              )
            }}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </Layout>
</template>
<script setup>
// layout component
import Layout from '@/layouts/model'
// Form components
import Vue from 'vue'
import axios from 'axios'
import { ref } from 'vue'
import { createToastInterface } from 'vue-toastification'
import { $t } from '@/helpers/translate'

const toast = createToastInterface()

const pointOfSale = Vue.prototype.$database.pointOfSales.find((el) => el.id == props.id)

const payments = ref([])
payments.value.push({
  name: $t('Cash'),
  safe_id: pointOfSale.safe_id,
  amount: ''
})
pointOfSale.payment_methods.map((el) => {
  payments.value.push({
    name: el.name,
    safe_id: el.safe_id,
    amount: ''
  })
})

const loading = ref(false)
const session = ref(null)
const getItem = async () => {
  loading.value = true
  const response = await axios.get(
    Vue.prototype.$linkGenerator('/pointOfSaleSessions/' + pointOfSale.open_session_id)
  )
  session.value = response.data
  loading.value = false
}
getItem()

const submit = () => {
  loading.value = true
  axios
    .post(Vue.prototype.$linkGenerator('/pointOfSaleSessions/closeSession'), {
      point_of_sale_id: props.id,
      payments: payments.value
    })
    .then((response) => {
      Vue.prototype.$updateDatabase(['pointOfSales'])
      toast.success($t('New session has been started successfully'), {
        position: 'top-center'
      })
      emit('onFinish', response.data.data)
      emit('close')
      loading.value = false
    })
}

const props = defineProps(['id'])
const emit = defineEmits(['close', 'onFinish'])
</script>
