<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <PageHeader :title="$t('Reports')" :description="$t('Sales returns by product')" />

        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{ $t('Reports options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <searchInput
                class="col-md-3"
                :title="$t('Customer')"
                :plaseholder="$t('Choose customer')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.customer_id"
                :value="params.customer_id"
                :values="$database.customers"
              />

              <dateFilter class="col-md-3" />

              <searchInput
                class="col-md-3"
                :title="$t('user')"
                :plaseholder="$t('Choose user')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.user_id"
                :value="params.user_id"
                :values="$database.users"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Store')"
                :plaseholder="$t('Choose store')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.store_id"
                :value="params.store_id"
                :values="$database.stores"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Cost center')"
                :plaseholder="$t('Choose a cost center')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.cost_center_id"
                :value="params.cost_center_id"
                :values="$database.costCenters"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Point of sale')"
                :plaseholder="$t('Choose point of sale')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.point_of_sale_id"
                :value="params.point_of_sale_id"
                :values="$database.pointOfSales"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Products category')"
                :plaseholder="$t('Choose category')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.section_id"
                :value="params.section_id"
                :values="$database.sections"
              />
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('Session number') }}</label>
                  <input
                    type="text"
                    v-model="params.session_number"
                    class="form-control"
                    :placeholder="$t('Enter session number')"
                  />
                </div>
              </div>
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader
            :title="$t('Sales returns by invoice')"
            :details="[
              { title: $t('Customer name'), value: customerName },
              { title: $t('Username'), value: userName },
              { title: $t('Store'), value: storeName },
              { title: $t('Point of sale'), value: pointOfSaleName },
              { title: $t('Session number'), value: sessionNumber },
              { title: $t('Order type'), value: tableNumber },
              { title: $t('Shipping agent'), value: deliveryName }
            ]"
          />
          <reportTable
            :emptyTableText="$t('there are no data at the moment')"
            :emptyTableSubText="$t('Try adding some items and try again')"
            :cloumns="[
              {
                column: 'product_name',
                link: '/products',
                title: $t('Product name'),
                type: 'reportMainLink',
                sort: true
              },
              { column: 'quantity', title: $t('Quantity'), type: 'text', sort: true },
              {
                column: 'average',
                title: $t('Average'),
                type: 'text',
                sort: true,
                supData: true
              },
              {
                column: 'total',
                title: $t('Total'),
                type: 'text',
                sort: true
              },
              {
                column: 'earning',
                title: 'الخسارة',
                type: 'text',
                sort: true,
                disable: !(this.$user.admin || this.$user.role.reports_earning_show),
                supData: true
              }
            ]"
            :footer="[
              { colspan: 1, value: 'المجموع' },
              { colspan: 1, value: quantity },
              { colspan: 1, value: '', supData: true },
              { colspan: 1, value: total },
              {
                colspan: 1,
                value: earning,
                disable: !(this.$user.admin || this.$user.role.reports_earning_show)
              }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import reportHeader from '@/elements/reports/reportHeader.vue'
import reportTable from '@/elements/reports/reportTable.vue'
import PageHeader from '@/components/pageHeader'
import dateFilter from '@/elements/reports/dateFilter.vue'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'

export default {
  data() {
    return {
      path: '/reports/salesReturns/products',
      items: [],

      loading: 0,

      quantity: 0,
      total: 0,
      earning: 0,

      params: {
        customer_id: '',
        store_id: '',
        user_id: '',
        point_of_sale_id: '',
        session_number: '',
        delivery_id: '',
        table_number: '',
        dateFilter: 2,
        startDate: '',
        endDate: ''
      },

      excel: {
        fileName: 'saled return by item',
        reportData: '[]'
      },

      storeName: '',
      customerName: '',
      userName: '',
      pointOfSaleName: '',
      sessionNumber: '',
      tableNumber: null,
      deliveryName: '',

      searchStartDate: '',
      searchEndDate: ''
    }
  },
  methods: {
    getData() {
      this.$setParams(this.params)
      this.loading = 1
      this.items = []
      this.quantity = 0
      this.total = 0
      this.earning = 0
      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.items = response.data
        this.total = this.items.reduce((accumulator, current) => accumulator + current.total, 0)
        this.quantity = this.items.reduce(
          (accumulator, current) => accumulator + current.quantity,
          0
        )
        this.earning = this.items.reduce((accumulator, current) => accumulator + current.earning, 0)

        this.items.map((el) => {
          el.total = this.$moneyFormat(el.total)
          el.average = this.$moneyFormat(el.average)
          el.earning = this.$moneyFormat(el.earning)
          el.quantity = el.quantity.toFixed(2)
        })
        this.total = this.$moneyFormat(this.total)
        this.earning = this.$moneyFormat(this.earning)
        this.quantity = this.quantity.toFixed(2)
        this.loading = 0
      })
      this.searchStartDate = this.params.startDate
      this.searchEndDate = this.params.endDate

      if (customer) {
        this.customerName = customer.name
      } else {
        this.customerName = ''
      }

      var store = this.$database.stores.find((el) => el.id == this.params.store_id)
      if (store) {
        this.storeName = store.name
      } else {
        this.storeName = ''
      }

      var user = this.$database.users.find((el) => el.id == this.params.user_id)
      if (user) {
        this.userName = user.name
      } else {
        this.userName = ''
      }

      this.sessionNumber = this.params.session_number

      var pointOfSale = this.$database.pointOfSales.find(
        (el) => el.id == this.params.point_of_sale_id
      )
      if (pointOfSale) {
        this.pointOfSaleName = pointOfSale.name
      } else {
        this.pointOfSaleName = ''
      }
    },
    getItems() {
      this.$setParams(params)
      this.searchStartDate = this.params.startDate
      this.searchEndDate = this.params.endDate
      if (params.orderBy) {
        this.items.sort(this.$dynamicSort(params))
      }
    }
  },
  watch: {
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams()
    if (typeof this.params.startDate === 'undefined') {
      this.params.startDate = new Date()
        .toISOString('en-US', { timeZone: 'Africa/Cairo' })
        .slice(0, 10)
    }
    this.getData()
    this.$updateDatabase(['users'])
  },
  components: {
    reportHeader,
    reportTable,
    PageHeader,
    dateFilter,
    searchInput,
    actionButtons
  }
}
</script>
