<template>
  <Layout
    :title="$t('New session')"
    :loading="loading"
    @close="() => $emit('close')"
    @action="submit()"
  >
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">{{ $t('Point of sale') }}</th>
          <th scope="col">{{ $t('User') }}</th>
          <th scope="col">{{ $t('Start date') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ pointOfSale.name }}</td>
          <td>{{ $user.name }}</td>
          <td>{{ this.$dateFormat() }}</td>
        </tr>
      </tbody>
    </table>
  </Layout>
</template>
<script setup>
// layout component
import Layout from '@/layouts/model'
// Form components
import Vue from 'vue'
import axios from 'axios'
import { ref } from 'vue'
import { createToastInterface } from 'vue-toastification'
import { $t } from '@/helpers/translate'

const toast = createToastInterface()

const pointOfSale = Vue.prototype.$database.pointOfSales.find((el) => el.id == props.id)

const loading = ref(false)
const submit = () => {
  loading.value = true
  axios
    .post(Vue.prototype.$linkGenerator('/pointOfSaleSessions/openSession'), {
      point_of_sale_id: pointOfSale.id
    })
    .then((response) => {
      Vue.prototype.$updateDatabase(['pointOfSales'])
      toast.success($t('New session has been started successfully'), {
        position: 'top-center'
      })
      emit('onFinish', response.data.data)
      emit('close')
      loading.value = false
    })
}

const props = defineProps(['id'])
const emit = defineEmits(['close', 'onFinish'])
</script>
