<template>
  <div>
    <div class="row">
      <label class="col-sm-3">
        {{ $t('Customer name') }}
      </label>
      <div class="col-md-7">
        <div class="input-group" ref="elementRef">
          <div :class="item.customer_id != 0 ? 'add-input  w-100' : ' w-100'">
            <input
              type="search"
              autocomplete="off"
              maxlength="200"
              v-model="customerName"
              class="form-control list-search"
              @keydown="handelKey"
              @keyup="searchCustomer"
              @focus="searchCustomer"
              :disabled="item.customer_id != 0"
            />
            <span class="btn btn-danger" v-if="item.customer_id != 0" @click="removeCustomer"
              ><i class="fas fa-times"></i
            ></span>
          </div>
          <ul
            class="list-unstyled search-selects"
            v-if="searchCustomers.length > 0 && item.customer_id == 0 && showCustomersList"
          >
            <li
              v-for="customer in searchCustomers"
              :key="customer.id"
              @click="addCustomerFromSearch(customer.id)"
              :class="{ focused: customer.focused }"
            >
              {{ customer.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Vue from 'vue'
import { ref, computed, watch } from 'vue'
import { useClickOutside } from '@/helpers/useClickOutside'

// Click outside logic
const { elementRef, isOutside } = useClickOutside()
watch(isOutside, (value, oldValue) => {
  if (value && oldValue != value) removeCustomerList()
})

const searchCustomers = ref([])
const showCustomersList = ref(false)

// Computed inputs
const customerName = computed({
  get: () => props.item.customer.name,
  set: (value) =>
    emits('updateItem', { ...props.item, customer: { ...props.item.customer, name: value } })
})

const searchCustomer = () => {
  // Show dropdown
  showCustomersList.value = true
  // Search in database
  const search = props.item.customer.name
  const searchRGX = new RegExp(search, 'i')
  const customers = Vue.prototype.$database.customers
    .sort(Vue.prototype.$dynamicSort({ orderBy: 'name', orderType: 'desc' }))
    .filter((el) => searchRGX.test(el.name))
    .slice(0, 10)
  if (typeof customers != 'undefined') {
    searchCustomers.value = customers
  } else {
    searchCustomers.value = []
  }
}

const handelKey = (e) => {
  if (
    (e.key == 'ArrowDown' && searchCustomers.value.length != 0) ||
    (e.key == 'ArrowUp' && searchCustomers.value.length != 0)
  ) {
    let customer = searchCustomers.value.find((el) => el.focused == true)
    let selected = searchCustomers.value.indexOf(customer)
    let length = searchCustomers.value.length
    if (selected > -1) {
      Vue.prototype.$set(searchCustomers.value[selected], 'focused', false)
    }
    if (e.key == 'ArrowDown') {
      if (selected < length - 1) {
        Vue.prototype.$set(searchCustomers.value[selected + 1], 'focused', true)
      } else {
        Vue.prototype.$set(searchCustomers.value[0], 'focused', true)
      }
    } else if (e.key == 'ArrowUp') {
      if (selected > 0) {
        Vue.prototype.$set(searchCustomers.value[selected - 1], 'focused', true)
      } else {
        Vue.prototype.$set(searchCustomers.value[length - 1], 'focused', true)
      }
    }
  } else if (e.key == 'Enter') {
    let eCustomer = searchCustomers.value.find((el) => el.focused == true)
    let eSelected = searchCustomers.value.indexOf(eCustomer)
    if (eSelected != -1) {
      addCustomerFromSearch(eCustomer.id)
      removeCustomerList()
    }
  }
}

const addCustomerFromSearch = (id) => {
  let customer = Vue.prototype.$database.customers.find((el) => el.id == id)
  emits('updateItem', { ...props.item, customer_id: id, customer: customer })
}
const removeCustomerList = () => (showCustomersList.value = false)

const removeCustomer = () => {
  emits('updateItem', { ...props.item, customer_id: 0, customer: {} })
  searchCustomers.value = []
}

const props = defineProps(['options', 'item'])
const emits = defineEmits(['updateItem'])
</script>
