<template>
  <div class="container-fluid" v-if="!loading">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col ml-n2">
                <h4 class="mb-1">
                  <a>
                    <strong>#{{ $t('Point Of sales Session Show') }} {{ item.id }}</strong></a
                  >
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="card card-body p-5 invoice-show">
          <div class="row">
            <div class="col">
              <div class="badge badge-dark" v-if="item.status == 0">
                {{ $t('Open') }}
              </div>
              <div class="badge badge-success" v-if="item.status == 1">
                {{ item.rest == 0 ? $t('Driven') : $t('Partially driven') }}
              </div>
              <div class="badge badge-danger" v-if="item.status == 2">
                {{ $t('Canceled') }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <TableComponent :data="item" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">#{{ item?.id }}</h4>
          </div>
          <div class="card-body">
            <ul class="list-unstyled">
              <li>
                <strong>{{ $t('Created in') }}</strong> :
                {{ item?.created }}
              </li>
              <li>
                <strong>{{ $t('Updated in') }}</strong> :
                {{ item?.updated }}
              </li>
              <li v-if="item.client_type == 0 && item.customer">
                <strong>{{ $t('Client') }}</strong> :
                <router-link :to="'/customers/' + item.customer.id">
                  {{ item.customer.name }}</router-link
                >
              </li>
              <li v-if="item.client_type == 1 && item.supplier">
                <strong>{{ $t('Client') }}</strong> :
                <router-link :to="'/suppliers/' + item.supplier.id">
                  {{ item.supplier.name }}</router-link
                >
              </li>
              <li>
                <strong>{{ $t('Administrator') }}</strong> :
                {{ item.user.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <printBill v-if="printingSession" :item="printingSession" @onFinish="onFinishPrinting" />
  </div>
</template>
<script>
import axios from 'axios'
import TableComponent from '../pointOfSales/components/TableComponent.vue'
import printBill from '@/print/bill.vue'
export default {
  data() {
    return {
      path: '/pointOfSaleSessions',
      printingSession: null,
      logo: localStorage.getItem('logo'),
      item: {},
      purchasePayments: {},
      purchasePaymentForm: false,
      addId: this.$route.params.id,
      amount: 0,
      type: 'show',
      loading: true,
      itemsCount: 0,
      printInvoice: false,
      site: localStorage.getItem('site')
    }
  },
  mounted() {
    this.getitem()
  },
  methods: {
    getitem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data

        this.loading = false
      })
    },
    onFinishPrinting() {
      this.printingSession = null
    },
    print() {
      this.printingSession = this.item
    }
  },
  components: {
    TableComponent,
    printBill
  }
}
</script>
