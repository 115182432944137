<template>
  <div :class="!showSidebar ? 'remove-sidebar' : ''">
    <sidebar :showMobileMnue="showMobileMnue" :showMobileNave="showMobileNave" :model="model" />
    <div class="main-content">
      <navbar :padding="showSidebar" :showSidebar="showSidebar" />
      <transition name="slide" mode="out-in" v-if="!loading">
        <router-view @closeSidebar="closeSidebar"></router-view>
      </transition>
    </div>
    <div class="modal-backdrop fade show" v-if="model == 1"></div>
  </div>
</template>
<script setup>
import navbar from '@/includes/navbar.vue'
import sidebar from '@/includes/sidebar.vue'
import { i18n } from '@/helpers/i18n'
import Vue, { ref, onMounted, watch, getCurrentInstance } from 'vue'

const { proxy } = getCurrentInstance()
const router = proxy.$router

const model = ref(0)
const loading = ref(true)
const showSidebar = ref(true)
const showMobileNave = ref(false)
const showMobileMnue = ref(false)
onMounted(() => {
  var token = localStorage.getItem('token')
  if (!token) {
    router.push('/login')
  } else {
    loading.value = false
    Vue.prototype.$intUser()
    Vue.prototype.$initCompany()
    Vue.prototype.$getUser()
    Vue.prototype.$getCompanies()
    Vue.prototype.$localStore()
    Vue.prototype.$updateDatabase()
    if (Vue.prototype.$user.language == 'en') {
      document.getElementById('stylesheetRtl').disabled = true
      i18n.locale = 'en'
    } else {
      document.getElementById('stylesheetRtl').disabled = false
      i18n.locale = 'ar'
    }
  }
})
watch(router, (newRoute) => {
  document.body.classList.remove('menu-open')
  showMobileMnue.value = false
  model.value = 0
  window.scrollTo({ top: 0 })
  document.documentElement.classList.remove('overflow-hidden')

  if (newRoute.name !== 'pointOfSalesShow') {
    showSidebar.value = true
  }
})
const closeSidebar = () => {
  showSidebar.value = false
}

// export default {
//   data() {
//     return {
//       model: 0,
//       loading: true,
//       showSidebar: true,
//       showMobileNave: false,
//       showMobileMnue: false
//     }
//   },
//   methods: {
//     closeSidebar() {
//       this.showSidebar = false
//     }
//   },
//   components: {
//     navbar,
//     sidebar
//   },
//   mounted() {
//     var token = localStorage.getItem('token')
//     if (!token) {
//       Vue.prototype.$router.push('/login')
//     } else {
//       this.loading = false
//       Vue.prototype.$intUser()
//       Vue.prototype.$initCompany()
//       Vue.prototype.$getUser()
//       Vue.prototype.$getCompanies()
//       Vue.prototype.$localStore()
//       Vue.prototype.$updateDatabase()
//       if (this.$user.language == 'en') {
//         document.getElementById('stylesheetRtl').disabled = true
//         Vue.prototype.$i18n.locale = 'en'
//       } else {
//         document.getElementById('stylesheetRtl').disabled = false
//         Vue.prototype.$i18n.locale = 'ar'
//       }
//     }
//   },
//   watch: {
//     $route: function () {
//       document.body.classList.remove('menu-open')
//       this.showMobileMnue = false
//       this.model = 0
//       window.scrollTo({ top: 0 })
//       document.documentElement.classList.remove('overflow-hidden')
//       if (this.$route.name != 'pointOfSalesShow') {
//         this.showSidebar = true
//       }
//     }
//   }
// }
</script>
<style>
.slide-enter-active,
.slide-leave-active {
  transition:
    opacity 0.4s,
    transform 0.4s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: scale(0.97);
}
</style>
