<template>
  <div class="form-group row">
    <label class="col-md-4" :for="inputId" v-if="!withoutLabel">{{ title }}</label>
    <label :for="inputId" v-if="withoutLabel">{{ title }}</label>

    <div :class="!withoutLabel ? 'col-md-7' : 'col-md-12 icon-input w-100'">
      <span>
        <i class="far fa-calendar"></i>
      </span>
      <VueCtkDateTimePicker
        :label="$t('Choose time and date')"
        :button-now-translation="$t('Now')"
        v-model="date"
        :value="value"
        format="YYYY-MM-DD hh:mm a"
        color="#03355e"
        button-color="#03355e"
        :class="{ 'is-invalid': errors.length }"
        :disabled="disabled"
        :id="inputId"
      />
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-7">
      <div class="invalid-feedback d-block">
        <ul class="list-unstyled">
          <li v-for="(error, index) in errors" :key="index">{{ $t(error) }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
export default {
  data() {
    return {
      date: '',
      inputId: Math.random().toString(36).substring(7)
    }
  },
  props: ['title', 'disabled', 'errors', 'value', 'withoutLabel'],
  watch: {
    date(value) {
      this.$emit('input', this.$fixDate(value))
    },
    value(value) {
      if (value != this.date) this.date = this.value
    }
  },
  mounted() {
    this.date = this.$dateFormat(this.value, true)
  },
  components: { VueCtkDateTimePicker }
}
</script>
