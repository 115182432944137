<template>
  <Layout>
    <!-- page header component -->
    <ShowHeader
      :image="item.image"
      :icon="'user'"
      :title="item.name"
      :stat="item.stat"
      :statText="item.stat ? $t('Active') : $t('Inactive')"
      :subData="[
        {
          title: $t('tax identification number'),
          value: item.tax_number
        }
      ]"
      :buttons="[
        {
          title: $t('Modulation'),
          icon: 'edit',
          link: '/customers/' + $route.params.id + '/edit',
          role: $checkRole('customers_edit')
        },
        {
          title: $t('Comprehensive report'),
          icon: 'file-chart-pie',
          link: '/customersDetailsAccounts?customer_id=' + $route.params.id,
          role: $checkRole('reports_show')
        },
        {
          title: $t('Add a batch'),
          icon: 'dollar-sign',
          link: '/salesPayments/create?customer_id=' + $route.params.id,
          role: $checkRole('sales_payments_add')
        },
        {
          title: $t('Add a bid'),
          icon: 'calculator',
          link: '/quotations/create?customer_id=' + $route.params.id,
          role: $checkRole('quotations_add')
        },
        {
          title: $t('Add a sales bill'),
          icon: 'file-invoice-dollar',
          link: '/invoices/create?customer_id=' + $route.params.id,
          role: $checkRole('invoices_add')
        },
        {
          title: $t('Add a request'),
          icon: 'briefcase',
          link: '/orders/create?customer_id=' + $route.params.id,
          role: $checkRole('orders_add')
        }
      ]"
    />
    <!-- Page content-->
    <ShowFile :file="item.file" />
    <ShowCard
      :title="$t('Background')"
      :columns="{
        firstColumn: [
          {
            icon: 'user',
            title: $t('Customer name'),
            value: item.name
          },
          {
            icon: 'dollar-sign',
            title: $t('Balance'),
            value: $moneyFormat($option.balance_type === 1 ? item.balance * -1 : item.balance)
          }
        ],
        secondColumn: [
          {
            icon: 'dollar-sign',
            title: $t('Opening balance'),
            value: $moneyFormat(item.opening_balance)
          },
          {
            icon: 'toggle-on',
            title: $t('Customer status'),
            value: item.stat === 1 ? $t('effective') : $t('ineffective')
          }
        ]
      }"
    />
    <ShowCard
      :title="$t('Billing address')"
      :columns="{
        firstColumn: [
          {
            icon: 'map-marker-alt',
            title: $t('Address'),
            value: item.address1
          },
          {
            icon: 'location-arrow',
            title: $t('Governorate'),
            value: item.entity
          },
          {
            icon: 'map-marked',
            title: $t('Zip code'),
            value: item.zip
          }
        ],
        secondColumn: [
          {
            icon: 'map-marker-alt',
            title: $t('Itinerary'),
            value: item.address2
          },
          {
            icon: 'city',
            title: $t('City'),
            value: item.city
          },
          {
            icon: 'globe-americas',
            title: $t('State'),
            value: item.country
          }
        ]
      }"
    />
    <ShowCard
      :title="$t('Communication information')"
      :columns="{
        firstColumn: [
          {
            icon: 'phone',
            title: $t('Mobile number'),
            value: item.phone1 ? item.phone1 + ' - ' + item.phone2 : item.phone2
          }
        ],
        secondColumn: [
          {
            icon: 'at',
            title: $t('Email'),
            value: item.email
          }
        ]
      }"
    />
    <HistoryCard :items="item.histories" v-if="$checkRole('history_show')" />
  </Layout>
</template>
<script setup>
import { getCurrentInstance } from 'vue'
// layout component
import Layout from '@/layouts/main.vue'
// Page components
import ShowHeader from '@/components/show/showHeader.vue'
import ShowFile from '@/components/show/showFile.vue'
import ShowCard from '@/components/show/showCard.vue'
import HistoryCard from '@/components/historyCard.vue'

import showHock from '@/helpers/showHock.js'

const { proxy } = getCurrentInstance()
const route = proxy.$route
const { item } = showHock({path: 'customers', id: route.params.id})

</script>
