var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Layout,{attrs:{"title":_vm.editId ? _vm.$t('Update account') : _vm.$t('Create account'),"loading":_setup.loading},on:{"close":() => _vm.$emit('close'),"action":_setup.onSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c(_setup.Select,{attrs:{"type":"text","title":_vm.$t('Account type'),"disabled":_vm.editId,"errors":_setup.getInputError('is_main'),"values":[
          { value: 1, name: _vm.$t('Main account') },
          { value: 0, name: _vm.$t('Sup account') }
        ]},model:{value:(_setup.item.is_main),callback:function ($$v) {_vm.$set(_setup.item, "is_main", $$v)},expression:"item.is_main"}})],1),_c('div',{staticClass:"col-md-6"},[_c(_setup.Input,{attrs:{"type":"number","title":_vm.$t('Code'),"errors":_setup.getInputError('code')},model:{value:(_setup.item.code),callback:function ($$v) {_vm.$set(_setup.item, "code", $$v)},expression:"item.code"}})],1),_c('div',{staticClass:"col-md-6"},[_c(_setup.Input,{attrs:{"type":"text","title":_vm.$t('Name'),"errors":_setup.getInputError('name')},model:{value:(_setup.item.name),callback:function ($$v) {_vm.$set(_setup.item, "name", $$v)},expression:"item.name"}})],1),_c('div',{staticClass:"col-md-6"},[_c(_setup.SearchInput,{attrs:{"type":"text","title":_vm.$t('Main account'),"options":_vm.$database.accounts.filter((el) => el.is_main == 1),"errors":_setup.getInputError('account_id')},model:{value:(_setup.item.account_id),callback:function ($$v) {_vm.$set(_setup.item, "account_id", $$v)},expression:"item.account_id"}})],1),(_setup.item.is_main == 0)?[_c('div',{staticClass:"col-md-6"},[_c(_setup.Select,{attrs:{"title":_vm.$t('Balance type'),"disabled":_vm.$route.name == 'customersEdit',"values":[
            { value: 0, name: _vm.$t('Cumulative') },
            { value: 1, name: _vm.$t('Creditor and Debitor') }
          ],"errors":_setup.getInputError('balance_type')},model:{value:(_setup.item.balance_type),callback:function ($$v) {_vm.$set(_setup.item, "balance_type", $$v)},expression:"item.balance_type"}})],1),_c('div',{staticClass:"col-md-6"},[_c(_setup.Input,{attrs:{"type":"number","title":_vm.$t('Initial balance'),"disabled":_vm.$route.name == 'customersEdit' || _setup.item.balance_type == 1,"errors":_setup.getInputError('opening_balance'),"after":_vm.$option.currency},model:{value:(_setup.item.opening_balance),callback:function ($$v) {_vm.$set(_setup.item, "opening_balance", $$v)},expression:"item.opening_balance"}})],1),_c('div',{staticClass:"col-md-6"},[(_setup.item.balance_type == 1)?_c(_setup.Input,{attrs:{"type":"number","title":_vm.$t('Debit'),"disabled":_vm.$route.name == 'customersEdit',"errors":_setup.getInputError('opening_debit'),"after":_vm.$option.currency},model:{value:(_setup.item.opening_debit),callback:function ($$v) {_vm.$set(_setup.item, "opening_debit", $$v)},expression:"item.opening_debit"}}):_vm._e()],1),_c('div',{staticClass:"col-md-6"},[(_setup.item.balance_type == 1)?_c(_setup.Input,{attrs:{"type":"number","title":_vm.$t('Creditor'),"disabled":_vm.$route.name == 'customersEdit',"errors":_setup.getInputError('opening_credit'),"after":_vm.$option.currency},model:{value:(_setup.item.opening_credit),callback:function ($$v) {_vm.$set(_setup.item, "opening_credit", $$v)},expression:"item.opening_credit"}}):_vm._e()],1)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }