<template>
  <div class="col-12">
    <div class="d-xl-block d-none">
      <div class="row invoice-table-header">
        <div class="col-xl-4">
          {{ $t('Unit') }}
        </div>

        <div class="col-xl-3">
          {{ $t('Quantity') }}
        </div>
        <div class="col-xl-4">
          {{ $t('Barcode') }}
        </div>
      </div>
    </div>
    <unitItem
      v-for="(rowItem, index) in $parent.item.productUnits"
      :key="index"
      :index="index"
      :item="rowItem"
    ></unitItem>
    <button class="btn btn-success w-100 mb-4" @click="$parent.addUnit()">
      <i class="fas fa-plus"></i> {{ $t('Addition') }}
    </button>
  </div>
</template>
<script>
import unitItem from './unitItem.vue'
export default {
  components: {
    unitItem
  }
}
</script>
