<template>
  <div v-if="!loading">
    <div class="row invoice-show hide-w-8">
      <div class="col-12 text-center">
        <img
          :src="logo"
          class="img-fluid mb-4"
          style="max-width: 4.5rem"
          v-if="$option.image != null"
        />
        <h2 class="mb-2">
          {{ $option.name }}
        </h2>
        <p class="text-muted">
          {{ item.code }}
        </p>

        <p class="text-muted mb-2">
          <strong>{{ $t('Date') }}:</strong>
          {{ $dateFormat(item.date) }}
        </p>
        <p class="text-muted mb-2">
          <strong>{{ $t('User') }}:</strong>
          {{ item.user_id ? $database.users.find((user) => user.id == item.user_id)?.name : '--' }}
        </p>
      </div>
    </div>
    <div class="row invoice-show hide-w-8">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table my-w">
            <thead>
              <tr>
                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('Account') }}</span>
                </th>
                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('Debit') }}</span>
                </th>
                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('Credit') }}</span>
                </th>
                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('Thats about') }}</span>
                </th>

                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('Cost center') }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="qItem in item.transaction_items" :key="qItem.id">
                <td class="px-0">
                  {{ $database.accounts.find((acc) => acc.id == qItem.account_id).name }}
                </td>
                <td class="px-0">{{ qItem.type == 'debit' ? $moneyFormat(qItem.amount) : 0 }}</td>
                <td class="px-0">{{ qItem.type == 'credit' ? $moneyFormat(qItem.amount) : 0 }}</td>
                <td class="px-0">
                  {{ qItem.description ?? '--' }}
                </td>
                <td class="px-0">
                  {{
                    $database.costCenters.find((el) => el.id == qItem.cost_center_id)?.name ?? '--'
                  }}
                </td>
              </tr>
              <tr>
                <td colspan="" class="px-0 border-top border-top-2">
                  <strong>الإجمالي </strong>
                </td>

                <td class="px-0 border-top border-top-2">
                  <span class="h3">
                    {{
                      parseFloat(
                        item.transaction_items.reduce((sum, currentItem) => {
                          if (currentItem.type == 'credit') {
                            return parseFloat(parseFloat(sum) + parseFloat(currentItem.amount))
                          } else return parseFloat(parseFloat(sum) + parseFloat(0))
                        }, 0)
                      ).toFixed(2)
                    }}
                  </span>
                </td>
                <td class="px-0 border-top border-top-2">
                  <span class="h3">
                    {{
                      parseFloat(
                        item.transaction_items.reduce((sum, currentItem) => {
                          if (currentItem.type == 'debit') {
                            return parseFloat(parseFloat(sum) + parseFloat(currentItem.amount))
                          } else return parseFloat(parseFloat(sum) + 0)
                        }, 0)
                      ).toFixed(2)
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="w-100" v-if="item.description != null">
               <hr class="my-3" />
               <h4 class="text-uppercase">{{ $t('notes') }}</h4>
               <p class="text-muted mb-0">{{ item.description }}</p>
          </div> -->
      </div>
      <!-- <div class="w-100 inv-footer" v-if="$option.stores_deposits_print_footer != null">
        <hr class="my-3" />
        <p
          class="text-muted mb-0"
          v-html="
            $option.stores_deposits_print_footer != null ? $option.stores_deposits_print_footer : ''
          "
        ></p>
      </div> -->
      <!-- <div class="signeture-container">
        <div class="signeture-item">
          <div class="signeture-name">{{ signeture.name }}</div>
          <div class="signeture-points">..........................</div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logo: localStorage.getItem('logo')
    }
  },

  props: {
    item: {},
    loading: null
  }
}
</script>
